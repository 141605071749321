import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import InputBase from './InputBase';

type InputBaseProps = React.ComponentPropsWithoutRef<typeof InputBase>;

type Props = InputBaseProps & NumberFormatProps;

const CurrencyInput = (props: Props) => {
  return <NumberFormat customInput={InputBase} {...props} />;
};

CurrencyInput.defaultProps = {
  thousandSeparator: ',',
  decimalSeparator: '.',
} as Partial<NumberFormatProps>;

export default CurrencyInput;
