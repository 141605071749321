import React, { FC } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import CellRenderer, { CellRendererProps } from './CellRenderer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 0,
      padding: theme.spacing(0.5),
      marginTop: -theme.spacing(0.5),
    },
  }),
);

const IconCellRenderer: FC<
  CellRendererProps & {
    /**
     * Display icon on row and/or group. Default 'row';
     */
    displayMode: 'both' | 'group' | 'row';
  }
> = ({ displayMode, ...otherProps }) => {
  const classes = useStyles();

  const { column, context, node } = otherProps;
  const colDef = column.getColDef();
  const componentProps = (colDef as any).componentProps || {};
  const onClickCallback = (otherProps as any).onClick || (context && context.onIconCellClick);

  const display =
    displayMode === 'both' || (displayMode === 'group' && node.group) || (displayMode === 'row' && !node.group);

  const component = (props: any) => {
    const { clickable, icon, ...otherProps } = props;
    return (
      <div className={classes.root} {...otherProps}>
        {icon === undefined ? <MoreHorizIcon /> : icon}
      </div>
    );
  };

  const clickableComponent = (props: any) => {
    const { icon, ...otherProps } = props;
    return (
      <IconButton
        classes={{
          root: classes.root,
        }}
        color="inherit"
        aria-label="more"
        {...otherProps}
      >
        {icon === undefined ? <MoreHorizIcon /> : icon}
      </IconButton>
    );
  };

  return display ? (
    <CellRenderer
      {...otherProps}
      component={componentProps.clickable === false ? component : clickableComponent}
      onClick={onClickCallback}
    />
  ) : (
    <></>
  );
};

IconCellRenderer.defaultProps = {
  displayMode: 'row',
};

export default IconCellRenderer;
