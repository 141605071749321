import React, { FC } from 'react';

import isNil from 'lodash/isNil';
import NumberFormat from 'react-number-format';

import CellRenderer, { CellRendererProps } from './CellRenderer';

const NumberCellRenderer: FC<CellRendererProps> = props => {
  const { context, value, valueFormatted } = props;
  const onClickCallback = props.onClick || (context && context.onNumberCellClick);

  const displayValue = valueFormatted === null ? value : valueFormatted;

  const component = (props: any) => (
    <NumberFormat
      value={displayValue}
      displayType={'text'}
      thousandSeparator={true}
      renderText={value => <span className="ag-cell-value">{value}</span>}
      {...props}
    />
  );

  return isNil(displayValue) ? <></> : <CellRenderer {...props} component={component} onClick={onClickCallback} />;
};

export default NumberCellRenderer;
