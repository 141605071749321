import React, { useContext } from 'react'
// eslint-disable-next-line import/extensions
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js'
import { ThemeProvider } from '@material-ui/styles'
import { createPriveTheme } from '@prive/prive-core-ui'
import Landing from './Landing'
import AuthContextProvider, { AuthContext } from './auth/AuthContextProvider'
import { logout } from './auth/authActions'
import { getItem, setItem } from './store/localStorage'

const isAuthenticated = authState => {
    return authState && authState.isAuthenticated && authState.authInfo && authState.authInfo.token
}

const LogoutHandler = () => {
    const { authState, dispatch } = useContext(AuthContext)

    const MINUTE = 1000 * 60
    const timeout = 2 * 60 * MINUTE
    const LAST_ACTION = 'lastAction'

    const logOut = () => {
        if (isAuthenticated(authState)) {
            logout()(dispatch)
        }
    }

    const checkTimeout = () => {
        const lastAction = getItem(LAST_ACTION)
        const duration = Date.now() - lastAction
        if (duration > timeout) {
            setItem(LAST_ACTION, '')
            logOut()
        }
    }

    const onAction = () => {
        checkTimeout()
        setItem(LAST_ACTION, Date.now())
    }

    const onIdle = () => {
        checkTimeout()
    }

    useIdleTimer({
        onIdle,
        onAction,
        timeout: timeout + 1000,
        eventsThrottle: 1000 * 10,
        startOnMount: true,
        crossTab: true,
        name: 'idle-timer',
        syncTimers: 5,
        throttle: 1000 * 10
    })

    return null
}

const App = () => {
    return (
        <ThemeProvider theme={createPriveTheme()}>
            <AuthContextProvider>
                <LogoutHandler />
                <Landing />
            </AuthContextProvider>
        </ThemeProvider>
    )
}

export default App
