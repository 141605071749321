import React from "react";
import {Route, Redirect} from "react-router-dom";
import  {AuthContext}  from "./AuthContextProvider";
import RoutePaths from "../configs/RoutePaths";


const AuthedRoute = ({component: Component, ...rest}) => {
    const { authState } = React.useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={
                props => isAuthenticated(authState) ?
                    <Component {...props}/>
                    :
                    <Redirect to={{pathname: RoutePaths.LOGIN_PATH, state:{referrer:props.location}}}/>
            }
        />
    )
};

const isAuthenticated = authState => {
    return authState && authState.isAuthenticated && authState.authInfo && authState.authInfo.token;
};


export default AuthedRoute;
