import jwtDecode from 'jwt-decode'
import axios from 'axios'
import endpoints from '../configs/endpoints'
import localize from '../utils/localize'
import Role from '../model/enum/Role'

const userHeaders = token =>
    token
        ? {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
              Accept: 'application/json'
          }
        : {
              'Content-Type': 'application/json',
              Accept: 'application/json'
          }
class UserService {
    /** get user from monolith user endpoint */
    getUser({ accessToken: token }) {
        const monolithId = token && jwtDecode(token).monolith_id

        const url = window._env_.isWebpack
            ? `/loans/v1/user?monolithId=${monolithId}`
            : endpoints.baseTTSServiceUrl + endpoints.userGetUrl.replace('{monolithId}', monolithId)

        const options = {
            method: 'GET',
            credentials: 'include',
            headers: userHeaders(token),
            url
        }
        return axios(options)
            .then(response => response.data)
            .catch(e => {
                console.error('### Failed to user list', e)
                return e.response.data
            })
    }

    updateUser({ accessToken: token, userId, data }) {
        const url = window._env_.isWebpack
            ? '/loans/v1/user/userId/{userId}'.replace('{userId}', userId)
            : endpoints.baseTTSServiceUrl + endpoints.userUpdateUrl.replace('{userId}', userId)

        const options = {
            method: 'PUT',
            credentials: 'include',
            headers: userHeaders(token),
            url,
            data
        }
        return axios(options)
            .then(response => response.data)
            .catch(e => {
                console.error('### Failed to user list', e)
                return e.response.data
            })
    }

    getUserPermission({ accessToken: token }) {
        const monolithId = token && jwtDecode(token).monolith_id

        const url = window._env_.isWebpack
            ? `/loans/v1/userDetail?monolithId=${monolithId}`
            : endpoints.baseTTSServiceUrl + endpoints.userDetailUrl.replace('{monolithId}', monolithId)

        const options = {
            method: 'GET',
            headers: userHeaders(token),
            url
        }
        return axios(options)
            .then(response => {
                const userData = response.data
                const userResponse = {
                    userDetails: userData,
                    role: userData.roleDTO,
                    token,
                    monolithId: userData.key,
                    username: userData.username,
                    team: userData.teamDTO,
                    status: userData.active
                }
                return userResponse
            })
            .catch(e => {
                console.error('### Failed to get user', e)
                return e.response.data
            })
    }

    createUser({ accessToken: token, data }) {
        const url = window._env_.isWebpack ? '/loans/v1/user' : endpoints.baseTTSServiceUrl + endpoints.userCreateUrl

        const options = {
            method: 'POST',
            credentials: 'include',
            headers: userHeaders(token),
            url,
            data
        }

        return axios(options)
            .then(response => response.data)
            .catch(e => {
                console.error('### Failed to user list', e)
                return e.response.data
            })
    }

    getTeamList({ accessToken: token, groupId }) {
        const url = window._env_.isWebpack
            ? '/loans/v1/teams?teamId={teamId}'.replace('{teamId}', groupId)
            : endpoints.baseTTSServiceUrl + endpoints.userTeamsUrl.replace('{teamId}', groupId)

        const options = {
            method: 'GET',
            credentials: 'include',
            headers: userHeaders(token),
            url
        }

        return axios(options)
            .then(response => response.data)
            .catch(e => {
                console.error('### Failed to user list', e)
                return e.response.data
            })
    }

    getGroupList({ accessToken: token }) {
        const url = window._env_.isWebpack ? '/loans/v1/teams' : endpoints.baseTTSServiceUrl + endpoints.userGroupsUrl

        const options = {
            method: 'GET',
            credentials: 'include',
            headers: userHeaders(token),
            url
        }

        return axios(options)
            .then(response => {
                return response.data
            })
            .catch(e => {
                console.error('### Failed to user list', e)
                return e.response.data
            })
    }

    getRoleList({ accessToken: token }) {
        const url = window._env_.isWebpack ? '/loans/v1/roles' : endpoints.baseTTSServiceUrl + endpoints.userRoleUrl

        const options = {
            method: 'GET',
            credentials: 'include',
            headers: userHeaders(token),
            url
        }

        return axios(options)
            .then(response => response.data)
            .catch(e => {
                console.error('### Failed to user list', e)
                return e.response.data
            })
    }

    getUserList({ accessToken: token }) {
        const url = window._env_.isWebpack ? '/loans/v1/users' : endpoints.baseTTSServiceUrl + endpoints.userListGetUrl

        const options = {
            method: 'GET',
            credentials: 'include',
            headers: userHeaders(token),
            url
        }

        return axios(options)
            .then(response =>
                response.data.map(i => ({
                    firstName: i.firstName || '',
                    lastName: i.lastName || '',
                    username: i.username || '',
                    email: i.email || '',
                    role: Role[i.roleDTO.code](),
                    roleCode: i.roleDTO.code,
                    team: i.teamDTO.name,
                    group: i.teamDTO.teamDTOList.find(team => team.level === 0).name,
                    status: i.active ? localize.strings.StatusActive : localize.strings.StatusInactive,
                    statusCode: i.active,
                    userId: i.userId,
                    key: i.key
                }))
            )
            .catch(e => {
                console.error('### Failed to user list', e)
                return e.response.data
            })
    }

    getUserDetail({ accessToken: token, monolithId }) {
        const url = window._env_.isWebpack
            ? `/loans/v1/userDetail?monolithId=${monolithId}`
            : endpoints.baseTTSServiceUrl + endpoints.userDetailUrl.replace('{monolithId}', monolithId)

        const options = {
            method: 'GET',
            headers: userHeaders(token),
            url
        }
        return axios(options)
            .then(response => {
                const userData = response.data
                const userResponse = {
                    userDetails: userData,
                    role: userData.roleDTO,
                    token,
                    monolithId: userData.key,
                    username: userData.username,
                    team: userData.teamDTO,
                    status: userData.active
                }
                return userResponse
            })
            .catch(e => {
                console.error('### Failed to get user', e)
                return e.response.data
            })
    }
}

export default new UserService()
