import React, { useEffect, useRef, useState, ReactNode } from 'react';

import { GridApi } from 'ag-grid-community';

import { getGridCore } from '../utils';
import Portal, { PortalProps } from '@material-ui/core/Portal';

const topPanelCssClass = 'ag-top-panel';

export interface DataGridTopPanelProps {
  /**
   * Turns on or off the top panel. Default `false`.
   */
  enabled: boolean;
  /**
   * Render the data into the top panel
   */
  children?: ReactNode;
}

export const DefaultTopPanelProps: DataGridTopPanelProps = Object.freeze({
  enabled: false,
});

export function useTopPanel(
  apis?: {
    api: GridApi;
  },
  enabled: boolean = false,
) {
  const [initialized, setInitialized] = useState(false);
  const topPanelRef = useRef<HTMLElement>();

  useEffect(() => {
    if (apis) {
      const { api } = apis;

      const topPanel = getGridCore(api).getRootGui().firstElementChild as HTMLElement;
      topPanelRef.current = topPanel;
      setInitialized(true);
    }
  }, [apis]);

  useEffect(() => {
    if (!initialized) return;
    if (!topPanelRef.current) throw new Error('Top panel is mising');

    const topPanel = topPanelRef.current;

    if (enabled) {
      topPanel.classList.add(topPanelCssClass);
    }

    return () => {
      topPanel.classList.remove(topPanelCssClass);
    };
  }, [enabled, initialized]);

  return (props: PortalProps) => (enabled ? <Portal {...props} container={topPanelRef.current} /> : null);
}
