import { DialogActions } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(4),
    },
    spacing: {
      // Used to override external CSS
      '&&&': {
        '& > * + *': {
          marginLeft: theme.spacing(1),
        },
      },
    },
  }),
)(DialogActions);
