import React from 'react';
import ReactDOM from 'react-dom';
import * as WebComponent from 'types/WebComponent';

/**
 * Wrapper class to render root component.
 *
 * ```typescript
 * const provider = () => import('your/component/path')
 * const $container = document.createElement('div')
 * const loadingComponent = <LoadingComponent />
 *
 * const renderer = new LazyRenderer(
 *   provider,
 *   $container,
 *   loadingComponent,
 * )
 * ```
 */
class LazyRenderer<P extends Props, C extends React.ComponentType<P>> {
  private props: Partial<Props> = {};
  private component: React.LazyExoticComponent<C>;

  constructor(
    provider: WebComponent.Provider<C>,
    private container: HTMLElement,
    private loading?: React.SuspenseProps['fallback'],
  ) {
    /**
     * Store this as a class member, otherwise when attributes change in custom element
     * the corresponding react component will unmount -> mount instead of the desired
     * behavior: rerender.
     */
    this.component = React.lazy(provider);
  }

  /**
   * Render this component.
   * This can be used as `setState` in react but for props,
   * it will perform a `Object.assign`-like behavior to partially update props.
   *
   * @param partialProps Props passed in.
   * @param callback Do something after it rendered.
   */
  public renderWithProps(partialProps: Partial<P>, callback?: () => void, children?: React.ReactNode[]): void {
    this.props = {
      ...this.props,
      ...partialProps,
    };

    ReactDOM.render(
      <React.Suspense fallback={this.loading || 'Loading...'}>
        {children
          ? React.createElement(this.component as React.ComponentType<any>, this.props, ...children)
          : React.createElement(this.component as React.ComponentType<any>, this.props)}
      </React.Suspense>,
      this.container,
      callback,
    );
  }

  /**
   * Similar to `renderWithProps` but it replace all props.
   *
   * @param props Props to pass.
   * @param callback Do something after it rendered.
   */
  public replaceProps(props: P, callback?: () => void): void {
    this.props = {};
    this.renderWithProps(props, callback);
  }

  /**
   * Unmount the component.
   */
  public unmount() {
    ReactDOM.unmountComponentAtNode(this.container);
  }
}

export default LazyRenderer;
