/**
 * Modify some implementation from [`@prezly/react-promise-modal`](https://github.com/prezly/react-promise-modal)
 * It can be easily integrated with any Dialog I think.
 *
 * @remarks
 * Remember to pass required context if you want your dialog behave correctly.
 * e.g.
 * 1. style
 * 2. theme
 * 3. i18n
 * Why? Since this component will be rendered in a new react root.
 *
 * e.g.
 * ```typescript
 * import promisify, { CANCEL } from 'path/to/this/file'
 *
 * // in some `async` function context
 * try {
 *   const options = {
 *     destructionDelay: 300,
 *   }
 *
 *   const confirmed = await promisify<boolean>(({ open, onSubmit, onDismiss }) => (
 *     <Dialog open={open} onClose={onDismiss}>
 *       <DialogTitle>Are you sure?</DialogTitle>
 *       <DialogContent>Really?</DialogContent>
 *       <DialogActions>
 *         <Button onClick={() => onSubmit(true)}>Y</Button>
 *         <Button onClick={onDismiss}>N</Button>
 *       </DialogActions>
 *     </Dialog>
 *   ), options)
 *
 *   // if Y is clicked
 *   confirmed // true
 * } catch (e) {
 *   if (e === CANCEL) { // Symbol(CANCEL)
 *     // N is clicked or this dialog is closed before Y is clicked
 *   } else {
 *     // some exception are thrown
 *   }
 * }
 * ```
 */

import { ReactElement } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

const noop = () => {};

type ModalRendererOption<T> = {
  onSubmit: (result?: T) => void;
  onDismiss: () => void;
  open: boolean;
};

type ModalRenderer<T> = (options: ModalRendererOption<T>) => ReactElement;

type Options = {
  destructionDelay: number;
};

const CANCEL = Symbol('CANCEL');
const DEFAULT_OPTIONS: Options = {
  destructionDelay: 300,
};

const promisify = <T>(renderModal: ModalRenderer<T>, options?: Partial<Options>) => {
  type PartialOptions = Pick<ModalRendererOption<T>, 'onSubmit' | 'onDismiss'>;

  const { destructionDelay } = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  const $container = document.createElement('div');
  document.body.appendChild($container);

  const display = (options: PartialOptions) => {
    const modal = renderModal({ ...options, open: true });

    render(modal, $container);
  };

  const hide = (options: PartialOptions, callback: () => void) => {
    const modal = renderModal({ ...options, open: false });

    render(modal, $container, callback);
  };

  const destroy = () => {
    unmountComponentAtNode($container);
    document.body.removeChild($container);
  };

  return new Promise<T>((resolve, reject) => {
    const onSubmit = resolve;
    const onDismiss = () => reject(CANCEL);
    display({ onSubmit, onDismiss });
  }).finally(() => {
    const onSubmit = noop;
    const onDismiss = noop;
    hide({ onSubmit, onDismiss }, () => {
      setTimeout(destroy, destructionDelay);
    });
  });
};

export { CANCEL };
export default promisify;
