import React from 'react';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import Select, { SelectProps } from '@material-ui/core/Select';
import { ListItemProps } from '@material-ui/core/ListItem';
import { InputBase } from 'Input';

const useListItemClasses = makeStyles(theme =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(0.5),
    },
  }),
);

const useMenuClasses = makeStyles(theme =>
  createStyles({
    paper: {
      borderRadius: theme.spacing(1),
    },

    list: {
      backgroundColor: theme.colors.gray[100],
      color: theme.colors.greenGray[700],
      padding: 0,
    },
  }),
);

export default withStyles(theme =>
  createStyles({
    select: {
      paddingRight: `${theme.spacing(3)}px !important`,

      '&:focus': {
        backgroundColor: 'transparent',
      },
    },

    icon: {
      color: theme.colors.greenGray[700],
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },

    disabled: {
      '& ~ $icon': {
        color: theme.colors.gray[400],
      },
    },
  }),
)((props: SelectProps) => {
  const { multiple } = props;
  const classes = useMenuClasses();
  const listItemClasses = useListItemClasses();

  return (
    <Select
      {...props}
      input={<InputBase />}
      IconComponent={KeyboardArrowDown}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        classes,
      }}
    >
      {React.Children.map(props.children, child =>
        multiple
          ? React.cloneElement(child as React.ReactElement<ListItemProps>, {
              classes: listItemClasses,
            })
          : child,
      )}
    </Select>
  );
});
