import { useState } from 'react';
import { GridApi, ColumnApi } from 'ag-grid-community';

const useExpand = (gridApi: GridApi, columnApi: ColumnApi) => {
  const [expanded, _setExpanded] = useState(false);

  const setExpanded = (newState: boolean) => {
    _setExpanded(newState);
    newState ? gridApi.expandAll() : gridApi.collapseAll();
  };

  return {
    expanded,
    setExpanded,
  };
};

export default useExpand;
