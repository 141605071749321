import { useContext } from 'react';
import debounce from 'lodash/debounce';
import { GridContext } from '../../DataGrid';

const useQuickSearch = () => {
  const debounceTime = 300;
  const { gridApi, serverSideDataSource } = useContext(GridContext)!;
  const debouncedSetQuickFilter = debounce(keyword => {
    const model = gridApi.getModel();
    if ('serverSide' === model.getType() && serverSideDataSource != null && serverSideDataSource.onKeyword) {
      serverSideDataSource.onKeyword(keyword);
      gridApi.purgeServerSideCache();
    } else {
      gridApi.setQuickFilter(keyword);
    }
  }, debounceTime);
  const setKeyword = (keyword: string) => debouncedSetQuickFilter(keyword);

  return { setKeyword };
};

export default useQuickSearch;
