import React, { forwardRef } from 'react';

import mergeWith from 'lodash/mergeWith';

import ChartBase, { ChartCommonProps, ChartBaseRef } from './ChartBase';
import { replaceIfArray } from './utils';

export const BarChart = forwardRef<ChartBaseRef, ChartCommonProps>(({ defaultOptions, ...otherProps }, ref) => {
  const myDefaultOptions: Highcharts.Options = mergeWith(
    {
      chart: {
        type: 'bar',
      },
    },
    defaultOptions,
    replaceIfArray,
  );

  return <ChartBase ref={ref} defaultOptions={myDefaultOptions} {...otherProps} />;
});

BarChart.defaultProps = {};

export default BarChart;
