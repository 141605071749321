import { AccordionDetails } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0),
    },
  }),
)(AccordionDetails);
