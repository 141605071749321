import React from 'react'

const Header = ({ title, rightpanel }) => (
    <section className="action-header">
        <h3>{title}</h3>
        <div className="action-btn btn-right">{rightpanel()}</div>
    </section>
)

export default Header
