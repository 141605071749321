import { ColumnApi, GridApi } from 'ag-grid-community';

import { createContext } from 'V2/utils/ReactUtils';

export interface DataGridContext {
  gridApi: GridApi;
  columnApi: ColumnApi;
}

const [useDataGridContext, DataGridContextProvider] = createContext<DataGridContext>();

export { useDataGridContext, DataGridContextProvider };
