import React, { FC } from 'react';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import highchartsDumbbell from 'highcharts/modules/dumbbell';
import HighchartsReact from 'highcharts-react-official';
import { useTheme, Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

import ColorScheme from '../ColorScheme';
import ChartProps from '../ChartProps';
import { SeriesData } from './DumbbellType';

highchartsMore(Highcharts);
highchartsDumbbell(Highcharts);

export const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

export interface DumbbellChartProps extends ChartProps<SeriesData> {}

export const DumbbellChart: FC<DumbbellChartProps & WithStyles<typeof styles>> = ({ classes, chartOptions, data }) => {
  const theme = useTheme();

  const { series, ...extOptions } = chartOptions || {};
  const dumbbellOptions: Highcharts.SeriesDumbbellOptions = {
    type: 'dumbbell',
    connectorColor: 'rgb(255, 255, 255, 0)',
    marker: {
      radius: 6,
    },
    data,
  };
  const backgroundColors = [theme.colors.white, theme.colors.gray[200]];
  const options: Highcharts.Options = {
    title: undefined,
    series: [dumbbellOptions],
    chart: {
      type: 'dumbbell',
      inverted: true,
    },
    colors: ColorScheme(theme),
    xAxis: {
      type: 'category',
      plotBands: [
        ...data.map((d, i) => ({
          from: i - 0.5,
          to: i + 0.5,
          color: backgroundColors[i % 2],
        })),
        {
          from: data.length - 0.5,
          to: data.length + 1 + 0.5,
          color: backgroundColors[data.length % 2],
        },
        {
          from: data.length + 1 - 0.5,
          to: data.length + 2 + 0.5,
          color: backgroundColors[(data.length + 1) % 2],
        },
      ],
    },
    yAxis: {
      title: undefined,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    ...extOptions,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default withStyles(styles, { name: 'DumbbellChart' })(DumbbellChart);
