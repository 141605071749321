import React, { useCallback } from 'react';
import { InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import useQuickSearch from './hooks/useQuickSearch';
import InputBase from 'Input/InputBase';

const SearchInput = () => {
  const { setKeyword } = useQuickSearch();

  const onSearchChange = useCallback<NonNullable<React.ComponentProps<typeof InputBase>['onChange']>>(
    e => {
      const value = e.target.value;
      setKeyword(value);
    },
    [setKeyword],
  );

  return (
    <InputBase
      onChange={onSearchChange}
      placeholder="Search"
      startAdornment={
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      }
    />
  );
};

export default SearchInput;
