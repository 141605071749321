import React, { MutableRefObject, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '../Button';
import { ButtonsState, ButtonsLabel } from './WizardTypes/ButtonsLabel';
import { useTranslation } from 'react-i18next';
import { i18n } from '../i18n';
import { makeStyles } from '@material-ui/styles';
import { useTheme, Theme, createStyles, ThemeProvider } from '@material-ui/core';

export type Props = {
  /**
   * Page title
   */
  title: string;
  /**
   * disallow to click on go back button
   */
  cannotGoBack: boolean;
  /**
   * true for first step in widget
   */
  isFirstStep: boolean;
  /**
   * true for final step in widget
   */
  isFinalStep: boolean;
  /**
   * disable all buttons
   */
  disabled: boolean;
  /**
   * default label when no override
   */
  buttonsLabel?: ButtonsLabel;
  /**
   * overrding from current widget
   */
  buttonsState: ButtonsState;
  /**
   * Ref to placeholder of extra actions (to be used with <Portal>).
   *
   * Note: it could be null due to lifecycle / hidden buttons.
   */
  extraActionsRef: MutableRefObject<HTMLDivElement>;
  onCancel: () => void;
  onBack: () => void;
  onNext: () => void;
};
function label(
  key: keyof ButtonsLabel,
  fallback: string,
  buttonsState: ButtonsState,
  buttonsLabel?: ButtonsLabel,
): string {
  let v = buttonsState[key];
  if (v !== null) {
    return v;
  }
  return (buttonsLabel && buttonsLabel[key]) || fallback;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: '0px',
    },
    title: {
      fontSize: '2em',
      fontWeight: 'bold',
      display: 'inline-block',
    },
    wizardButtonRow: theme.stepWizard.hideTitle
      ? {
          maxWidth: '100%',
          flexBasis: '100%',
          marginTop: '30px',
          justifyContent: 'space-between',
        }
      : {},
    wizardButtonRowFirst: {
      justifyContent: 'flex-end',
    },
    wizardButtonRowFinal: {
      justifyContent: 'flex-end',
    },
    wizardButton: {
      height: theme.stepWizard.buttonHeight,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: theme.spacing(4),
      },
    },
    wizardButtonCancel: {},
    wizardButtonBack: {},
    wizardButtonNext: {},
    wizardButtonFinish: {
      fontWeight: 'bold',
    },
  }),
);

const ButtonRow = ({
  title,
  isFirstStep,
  isFinalStep,
  disabled,
  cannotGoBack,
  onCancel,
  onBack,
  onNext,
  buttonsState,
  buttonsLabel,
  extraActionsRef,
}: Props) => {
  const theme = useTheme();
  const styles = useStyles();
  const { t } = useTranslation('StepWizard', { i18n });
  const cancelLabel = label('cancelLabel', t('Cancel'), buttonsState, buttonsLabel);
  const backLabel = label('backLabel', t('Back'), buttonsState, buttonsLabel);
  const nextLabel = label('nextLabel', t('Next'), buttonsState, buttonsLabel);
  const finishLabel = label('finishLabel', t('Finish'), buttonsState, buttonsLabel);
  const emptyPlaceholder = useMemo(() => document.createElement('div'), []);
  const { hideAllButtons } = buttonsState;
  if (hideAllButtons) {
    extraActionsRef.current = emptyPlaceholder;
  }
  return (
    <Grid container spacing={4} justify="space-between" className={`${styles.root} custom-row-button-step`}>
      {theme.stepWizard.hideTitle ? null : (
        <Grid item xs={6} className={styles.title}>
          {title}
        </Grid>
      )}
      <Grid
        container
        item
        //TODO: need more flexiable when both buttons are title size are variable
        xs={theme.stepWizard.hideTitle ? 12 : 6}
        justify="flex-end"
        className={`${styles.wizardButtonRow} ${isFinalStep ? styles.wizardButtonRowFinal : ''} custom-wrap-button ${
          isFirstStep ? 'jus-flex-end' + styles.wizardButtonRowFirst : ''
        }`}
        hidden={hideAllButtons}
      >
        {hideAllButtons || theme.stepWizard.hideCancel ? null : (
          <Button
            startIcon={
              theme.stepWizard.showIconOnCancelButton
                ? React.createElement(theme.stepWizard.showIconOnCancelButton)
                : undefined
            }
            variant="text"
            onClick={onCancel}
            disabled={disabled || buttonsState.cancelDisabled}
            className={`${styles.wizardButton} ${styles.wizardButtonCancel} btn-custom-cancel`}
          >
            {cancelLabel}
          </Button>
        )}
        {hideAllButtons || isFirstStep ? null : (
          <Button
            startIcon={
              theme.stepWizard.showIconOnBackButton
                ? React.createElement(theme.stepWizard.showIconOnBackButton)
                : undefined
            }
            variant="text"
            className={`coloredBtn ${styles.wizardButton} ${styles.wizardButtonBack} btn-custom-back`}
            onClick={onBack}
            hidden={isFirstStep}
            disabled={disabled || cannotGoBack || buttonsState.backDisabled}
          >
            {backLabel}
          </Button>
        )}
        {hideAllButtons ? null : <div ref={extraActionsRef} />}
        {hideAllButtons ? null : isFinalStep ? (
          <Button
            endIcon={
              theme.stepWizard.showIconOnFinishButton
                ? React.createElement(theme.stepWizard.showIconOnFinishButton)
                : undefined
            }
            variant="contained"
            color="primary"
            onClick={onNext}
            disabled={disabled || buttonsState.finishDisabled}
            className={`${styles.wizardButton} ${styles.wizardButtonFinish} btn-custom-finish`}
          >
            {finishLabel}
          </Button>
        ) : (
          <Button
            endIcon={
              theme.stepWizard.showIconOnNextButton
                ? React.createElement(theme.stepWizard.showIconOnNextButton)
                : undefined
            }
            className={`coloredBtn ${styles.wizardButton} ${styles.wizardButtonNext} btn-custom-next`}
            color="primary"
            onClick={onNext}
            disabled={disabled || buttonsState.nextDisabled}
          >
            {nextLabel}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const StyledButtonRow = (props: Props) => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme.stepWizard.buttonRowTheme ? theme.stepWizard.buttonRowTheme(theme) : theme}>
      <ButtonRow {...props} />
    </ThemeProvider>
  );
};

export default StyledButtonRow;
