/* eslint-disable no-shadow */
import React, { useContext, useState } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { AuthContext } from '../../auth/AuthContextProvider'
import LoginForm from './LoginForm'
import ForgetPasswordForm from './ForgetPasswordForm'
import ResetPasswordForm from './ResetPasswordForm'
import RoutePaths from '../../configs/RoutePaths'
import Nav, { NavButtons } from '../common/Nav'
import logo from '../../assets/img/citibank/logo.png'

const StyledLogo = styled.div`
    margin: 0px auto;
    display: flex;
    justify-content: center;
    background-color: #fcfcfc;
    padding-top: 50px;
`
const renderForm = (type, setStatus) => {
    if (type === 'login') return <LoginForm setStatus={setStatus} />
    if (type === 'forgetPassword') return <ForgetPasswordForm setStatus={setStatus} />
    if (type === 'resetPassword') return <ResetPasswordForm setStatus={setStatus} />
    return null
}
const Login = () => {
    const { authState } = useContext(AuthContext)
    const [status, setStatus] = useState('login')
    const isAuthenticated = authState => authState?.isAuthenticated && authState?.authInfo?.token

    if (isAuthenticated(authState)) return <Redirect to={`${RoutePaths.HOME_PATH}`} />

    return (
        <div className="wrapper tts">
            <div className="nav-sidebar" />
            <div className="content-wrapper">
                <Nav enabledButtons={[NavButtons.CHANGE_LOCALE]} />
                <StyledLogo>
                    <img src={logo} alt="logo" />
                </StyledLogo>
                {renderForm(status, setStatus)}
            </div>
        </div>
    )
}

export default Login
