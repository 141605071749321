import { Tab } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      textTransform: 'none',

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },

    selected: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },

    disabled: {
      color: theme.colors.gray[400],
    },
  }),
)(Tab);
