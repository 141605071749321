/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { ErrorMessage } from '@hookform/error-message'
import classnames from 'classnames'
import OptionsHOC from '../common/OptionsHOC'
import localize from '../../utils/localize'
import ROLE, { values as userRole } from '../../model/enum/Role'

const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)

const FIELDS = [
    [
        {
            label: () => localize.strings.FirstName,
            defaultValue: '',
            key: 'firstName',
            rules: () => ({
                required: localize.strings.Required
            }),
            render: () => ({ onChange, value }) => <input type="text" onChange={onChange} value={value} />
        },
        {
            label: () => localize.strings.LastName,
            defaultValue: '',
            key: 'lastName',
            rules: () => ({
                required: localize.strings.Required
            }),
            render: () => ({ onChange, value }) => <input type="text" onChange={onChange} value={value} />
        }
    ],
    [
        {
            label: () => localize.strings.Username,
            defaultValue: '',
            key: 'username',
            rules: () => ({
                required: localize.strings.Required
            }),
            render: (errors, optionData) => ({ onChange, value }) => (
                <input type="text" onChange={onChange} value={value} disabled={optionData.isUpdated} />
            )
        },
        {
            type: 'selection',
            label: () => localize.strings.UserType,
            defaultValue: '',
            key: 'role',
            rules: () => ({
                required: localize.strings.Required
            }),
            render: (errors, optionData, getValues) => ({ onChange, value }) => {
                if (getValues('roleCode') === userRole.ADMIN) {
                    return (
                        <div className="default-input" style={{ width: 368 }}>
                            <div className={classnames('input-box')}>
                                {' '}
                                <input type="text" onChange={onChange} value={value} disabled />
                            </div>
                        </div>
                    )
                }
                return (
                    <FormControl className="default-input" style={{ width: 368 }}>
                        <Select
                            displayEmpty
                            value={value}
                            className={classnames('input-box', { error: errors.role })}
                            labelId="customized-select-label"
                            id="customized-select"
                            disableUnderline
                            style={{
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                fontFamily:
                                    '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif',
                                color: '#3D505D',
                                fontSize: '16px'
                            }}
                            onChange={onChange}
                        >
                            {optionData.roles
                                .filter(i => i.code !== userRole.ADMIN)
                                .map((i, index) => (
                                    <MenuItem key={`role-${index}`} value={i.id}>
                                        {ROLE[i.code]()}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                )
            }
        }
    ],
    [
        {
            label: () => localize.strings.Password,
            defaultValue: '',
            key: 'password',
            rules: ({ isUpdated }) => ({
                required: isUpdated ? false : localize.strings.Required,
                validate: value =>
                    value?.length === 0 || value === '********'
                        ? true
                        : value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{16,}$/) || localize.strings.InvalidPassword
            }),
            render: (errors, optionData) => ({ onChange, value }) => (
                <input type="password" onChange={onChange} value={value} disabled={optionData.isUpdated} />
            )
        },
        {
            label: () => localize.strings.ConfirmPassword,
            defaultValue: '',
            key: 'confirmPassword',
            rules: ({ isUpdated, getValues }) => ({
                required: isUpdated ? false : localize.strings.Required,
                validate: value => value === getValues('password') || localize.strings.PasswordNotMatch
            }),
            render: (errors, optionData) => ({ onChange, value }) => (
                <input type="password" onChange={onChange} value={value} disabled={optionData.isUpdated} />
            )
        }
    ],
    [
        {
            label: () => localize.strings.Email,
            defaultValue: '',
            key: 'email',
            rules: () => ({
                required: localize.strings.Required,
                validate: value => emailRegex.test(value) || localize.strings.InvalidEmail
            }),
            render: () => ({ onChange, value }) => <input type="text" onChange={onChange} value={value} />
        }
    ],
    [
        {
            label: () => localize.strings.Group,
            defaultValue: '',
            key: 'group',
            rules: () => ({
                required: localize.strings.Required
            }),
            render: () => ({ onChange, value }) => {
                return <input type="text" onChange={onChange} value={value} disabled />
            }
        },
        {
            type: 'selection',
            label: () => localize.strings.Team,
            defaultValue: '',
            key: 'team',
            rules: () => ({
                required: localize.strings.Required
            }),
            render: (errors, optionData, getValues) => ({ onChange, value }) => {
                if ([userRole.ADMIN, userRole.SUPERVISOR].includes(getValues('roleCode'))) {
                    return (
                        <div className="default-input" style={{ width: 368 }}>
                            <div className={classnames('input-box')}>
                                {' '}
                                <input type="text" onChange={onChange} value={value} disabled />
                            </div>
                        </div>
                    )
                }
                return (
                    <FormControl className="default-input">
                        <Select
                            displayEmpty
                            value={value}
                            className={classnames('input-box', { error: errors.team })}
                            labelId="customized-select-label"
                            id="customized-select"
                            disableUnderline
                            style={{
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                fontFamily:
                                    '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif',
                                color: '#3D505D',
                                fontSize: '16px'
                            }}
                            onChange={onChange}
                        >
                            {optionData.teams.map((i, index) => (
                                <MenuItem key={`team-${index}`} value={i.id}>
                                    {i.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )
            }
        }
    ],
    [
        {
            label: () => 'UserId',
            defaultValue: '',
            key: 'userId',
            rules: () => ({
                required: false
            }),
            render: () => ({ value }) => <input type="hidden" value={value} />,
            hide: true
        },
        {
            label: () => 'key',
            defaultValue: '',
            key: 'key',
            rules: () => ({
                required: false
            }),
            render: () => ({ value }) => <input type="hidden" value={value} />,
            hide: true
        },
        {
            label: () => 'roleCode',
            defaultValue: '',
            key: 'roleCode',
            rules: () => ({
                required: false
            }),
            render: () => ({ value }) => <input type="hidden" value={value} />,
            hide: true
        },
        {
            label: () => 'defaultTeam',
            defaultValue: '',
            key: 'defaultTeam',
            rules: () => ({
                required: false
            }),
            render: () => ({ value }) => <input type="hidden" value={value} />,
            hide: true
        }
    ]
]

const CreateUserForm = ({ roles, teams, groups, isUpdated }) => {
    const { errors, control, getValues, trigger, watch, setValue } = useFormContext()
    const optionData = { roles, teams, groups, isUpdated }
    const { role, roleCode } = watch(['role', 'roleCode'])
    useEffect(() => {
        const _roleCode = roles.find(i => i.code === role || i.id === role)?.code
        if (userRole.SUPERVISOR === _roleCode) {
            setValue('roleCode', _roleCode)
            setValue('team', getValues('group'))
        }
        if (userRole.SALES_AGENT === _roleCode) {
            setValue('team', '')
            setValue('roleCode', _roleCode)
        }
    }, [role, roleCode])
    return (
        <>
            <h5 style={{ color: 'var(--color-dark-green)' }}>{localize.strings.UserFormTitle}</h5>
            {FIELDS.map((row, index) => (
                <div className="row" key={`CreateUserForm-${index}`}>
                    {row.map(item => (
                        <div
                            className={`col ${row.length === 1 ? 's12' : 's6'}`}
                            key={item.key}
                            style={{ display: item.hide ? 'none' : 'block' }}
                        >
                            <label className="input-label">{item.label()}</label>
                            <div className="row">
                                <OptionsHOC
                                    type={item.type}
                                    isError={errors[item.key]}
                                    isFullWidth={row.length === 1}
                                    fullWidth={764}
                                >
                                    <Controller
                                        defaultValue={item.defaultValue}
                                        name={item.key}
                                        control={control}
                                        render={item.render(errors, optionData, getValues)}
                                        rules={item.rules({ getValues, trigger, isUpdated })}
                                    />
                                </OptionsHOC>
                                {errors[item.key] && (
                                    <div style={{ wordWrap: 'break-word', color: 'red', width: '368px' }}>
                                        <label
                                            className="input-label error"
                                            style={{ textAlign: 'left', color: 'red' }}
                                        >
                                            <ErrorMessage
                                                errors={errors}
                                                name={item.key}
                                                render={({ message }) => (
                                                    <div dangerouslySetInnerHTML={{ __html: message }} />
                                                )}
                                            />
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </>
    )
}

export default CreateUserForm
