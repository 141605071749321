/* eslint-disable no-undef */

const endpoints = {
    baseMonolithServiceUrl: window._env_.MONOLITH_SERVICE_URL,
    baseTTSServiceUrl: window._env_.TTS_SERVICE_URL,
    ENABLE_TEAM_STRUCTURE: window._env_.ENABLE_TEAM_STRUCTURE,
    ssoRirectUrl: window._env_.SSO_DIRECT_URL,
    auditLogsUrl: '/loans/v1/audits',
    calcLoanUrl: '/loans/v1/calc',
    saveLoanUrl: '/loans/v1/calcs',
    userPermissionUrl: '/loans/v1/userPermission?monolithId={monolithId}',
    userDetailUrl: '/loans/v1/userDetail?monolithId={monolithId}',
    userProductUrl: '/loans/v1/userProduct?monolithId={monolithId}',
    userGroupsUrl: '/loans/v1/teams',
    userTeamsUrl: '/loans/v1/teams?teamId={teamId}',
    userRoleUrl: '/loans/v1/roles',
    userCreateUrl: '/loans/v1/user',
    userListGetUrl: '/loans/v1/users',
    userGetUrl: '/loans/v1/users?monolithId={monolithId}',
    userUpdateUrl: '/loans/v1/user/userId/{userId}',
    authUrl: '/login/',
    userUrl: '/user/priveKey/{monolithId}',
    ssoUrl: '/login/sly/SSO',
    forgetPassword: '/login/password/forgot',
    resetPassword: '/login/password/reset',
    tenant: 'citi-hk',

    /** download audit records in the past N months */
    auditLogsDownloadDurationInMonth: 2,
    defaultLanguage: 'en'
}

export default endpoints
