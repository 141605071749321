import { useEffect } from 'react';

import {
  AgEvent,
  GridApi,
} from 'ag-grid-community';
import debounce from 'lodash/debounce';

import { getRefElement, hasScroll } from '../utils';

const RedrawDeplay = 100;

function patchWidth(event: string, element: HTMLElement, targetWidth: number, redrawDeplay: number) {
  setTimeout(() => {
    if (parseInt(element.style.width) !== targetWidth) {
      element.style.minWidth = element.style.maxWidth = element.style.width = `${targetWidth}px`;
      console.debug(`usePinnedHeaderWidthPatch-${element.getAttribute('ref')}`, event, 'applied');
    }
  }, redrawDeplay);
}

function patch(api: GridApi, event: string) {
  console.debug('usePinnedHeaderWidthPatch', event);
  const eBodyViewport = getRefElement(api, 'eBodyViewport');
  if (!eBodyViewport) return;
  const ePinnedRightHeader = getRefElement(api, 'ePinnedRightHeader');
  if (!ePinnedRightHeader) return;

  const sPinnedRightHeader = window.getComputedStyle(ePinnedRightHeader);
  const pinnedRightHeaderWidth = parseInt(sPinnedRightHeader.width);
  if (pinnedRightHeaderWidth === 0) return;

  let maxInnerWidth = 0;
  for (let i = 0; i < ePinnedRightHeader.childNodes.length; i++) {
    const node = ePinnedRightHeader.childNodes[i] as HTMLElement;
    const style = window.getComputedStyle(node);
    const width = parseInt(style.width);
    if (width > maxInnerWidth) {
      maxInnerWidth = width;
    }
  }

  let targetWidth = maxInnerWidth;
  if (hasScroll(eBodyViewport, 'y')) {
    if (!eBodyViewport.parentElement) return;

    const scrollWidth = eBodyViewport.parentElement.clientWidth - eBodyViewport.clientWidth;
    targetWidth += scrollWidth;
  }

  if (pinnedRightHeaderWidth !== targetWidth) {
    patchWidth(event, ePinnedRightHeader, targetWidth, RedrawDeplay);
  }

  const eHorizontalRightSpacer = getRefElement(api, 'eHorizontalRightSpacer');
  if (!eHorizontalRightSpacer) return;

  if (parseInt(eHorizontalRightSpacer.style.width) !== targetWidth) {
    // eHorizontalRightSpacer width will recalculate after sPinnedRightHeader
    // the deplay should long enough to prevent jiggling.
    patchWidth(event, eHorizontalRightSpacer, targetWidth, RedrawDeplay * 9);
  }
}

const debouncedPatch = debounce(patch, RedrawDeplay);

// Not a good solution, just do the best to handle "common" case.
export default function usePinnedHeaderWidthPatch(apis?: {
  api: GridApi;
}) {
  useEffect(() => {
    if (apis) {
      const { api } = apis;

      const listener = (e: AgEvent) => {
        debouncedPatch(api, e.type);
      }
      debouncedPatch(api, 'Init');

      api.addEventListener('displayedColumnsChanged', listener);
      api.addEventListener('columnResized', listener);
      api.addEventListener('gridSizeChanged', listener);
      return () => {
        debouncedPatch.cancel();
        api.removeEventListener('displayedColumnsChanged', listener);
        api.removeEventListener('columnResized', listener);
        api.removeEventListener('gridSizeChanged', listener);
      };
    }
  }, [apis]);
}