export interface TitleAndDescription {
  title: string;
  description?: string;
}

export type WidgetTitleDescriptionProps = {
  render: (title: string, description: string) => JSX.Element;
};

export type WidgetTitleDescription = React.FunctionComponent<WidgetTitleDescriptionProps>;
export interface WizardLazyLoad {
  /**
   * The wizard step component
   */
  default: React.FunctionComponent<any>;
  /**
   * Helper component to provide localized/dynamic title
   */
  WidgetTitleDescription?: WidgetTitleDescription;
}

/**
 * import('./some.js');
 * a supplier to a promise of {'default': WizardStepComponent}
 */
type ImportComponent = () => Promise<WizardLazyLoad>;
const widgetRegistry = new Map<string, ImportComponent>();

export function registerWizardComponentWidget(componentName: string, component: ImportComponent) {
  if (componentName.indexOf('-') !== -1) {
    // reserved for web component custom element
    throw new Error('Bad component name');
  }
  widgetRegistry.set(componentName, component);
}

export function findRegisteredComponent(componentName: string): ImportComponent {
  const component = widgetRegistry.get(componentName);
  if (!component) {
    throw new Error(`cannot find ${componentName} from registry`);
  }
  return component;
}
