/* eslint-disable react/no-array-index-key */
import React from 'react'
import * as R from 'ramda'
import numeral from 'numeral'
import NumberFormat from 'react-number-format'

const format = (value, field) => {
    if (field.type === 'date') {
        return value
    }
    if (field.type === 'account') {
        return field.format ? (
            <NumberFormat format={field.format} value={value?.toString?.() || ''} displayType="text" />
        ) : (
            value
        )
    }
    try {
        return field.format ? numeral(value).format(field.format) : value
    } catch (e) {
        return value
    }
}

const styleText = {
    width: '250px',
    textAlign: 'left'
    //    "fontFamily": "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,Oxygen-Sans,Ubuntu,Cantarell,\"Helvetica Neue\",sans-serif"
}

const ResultSection = ({ title, fields, data }) => {
    if (R.isEmpty(fields)) return null
    return (
        <>
            <h4>{title()}</h4>
            <div className="panel-default left-right">
                <div className="row">
                    <div className="col s12">
                        {fields.map((field, index) => {
                            const value = format(data[field.key], field)

                            return (
                                <div className="cal-details" key={index}>
                                    <p className="caption">{field.label()}</p>

                                    <p style={styleText}>{value}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResultSection
