/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react'
import classnames from 'classnames'
import { ErrorMessage } from '@hookform/error-message'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import localize from '../../utils/localize'
import { getResetPasswordErrorCode } from './constants'
import { AuthContext } from '../../auth/AuthContextProvider'
import AuthService from '../../services/AuthService'

const StyledFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 480px;
`
const StyledFormDesc = styled.div`
    width: 400px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px;
`

const StyledTips = styled.div`
    margin-top: 20px;
    font-size: 16px;
    cursor: pointer;
    :hover {
        opacity: 0.7;
    }
`
const StyledTitle = styled.h3`
    font-weight: bold;
`

const StyledErrorMsg = styled.div`
    white-space: pre-wrap;
    width: 480px;
`
const StyledSuccessMsg = styled.div`
    white-space: pre-wrap;
    width: 480px;
`

const FIELDS = [
    {
        key: 'code',
        type: 'text',
        label: () => '',
        register: () => ({
            required: true
        }),
        placeholder: localize.strings.CodePlaceholder,
        errorMsg: () => localize.strings.Required,
        autoComplete: 'off'
    },
    {
        key: 'password',
        type: 'password',
        label: () => localize.strings.NewPassword,
        register: () => ({
            required: true,
            validate: value => value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{16,}$/) || localize.strings.InvalidPassword
        }),
        placeholder: '',
        errorMsg: () => localize.strings.Required,
        autoComplete: 'off'
    },
    {
        key: 'newPassword',
        type: 'password',
        label: () => localize.strings.ConfirmPassword,
        register: getValues => ({
            required: true,
            validate: value => value === getValues('password') || localize.strings.PasswordNotMatch
        }),
        placeholder: '',
        errorMsg: () => localize.strings.Required,
        autoComplete: 'off'
    }
]

const ResetPasswordForm = props => {
    const { setStatus } = props
    const { authState } = useContext(AuthContext)
    const [isSubmit, setIsSubmit] = useState(false)
    const [resetPasswordFailed, setResetPasswordFailed] = useState(false)
    const [success, setSuccess] = useState(false)
    const { register, handleSubmit, errors, clearErrors, getValues, trigger } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange'
    })
    const [code, setCode] = useState(false)
    const onSubmit = data => {
        AuthService.resetPassword({ verificationToken: data.code, password: data.password })
            .then(({ result }) => {
                setTimeout(() => {
                    setSuccess(true)
                    setIsSubmit(false)
                }, 1000)
            })
            .catch(error => {
                setTimeout(() => setIsSubmit(false), 500)
                const { result } = error
                setResetPasswordFailed(result)
            })
    }

    const handleGoBack = () => {
        setResetPasswordFailed(false)
        if (code) return setCode(false)
        return setStatus('forgetPassword')
    }

    const handleCodeSumbit = () => (getValues('code').length === 0 ? trigger('code') : setCode(true))

    useEffect(() => {
        clearErrors()
        setResetPasswordFailed(false)
    }, [authState.locale])

    return (
        <form>
            <section className="content container-fluid tts-login">
                <div className="row-section flex-center login-section">
                    <StyledErrorMsg className="row">
                        <p className="error">{getResetPasswordErrorCode(resetPasswordFailed)}</p>
                    </StyledErrorMsg>
                    <StyledFormContainer>
                        <StyledTitle>
                            {code ? localize.strings.ResetPassword : localize.strings.ForgetPassword}
                        </StyledTitle>
                        <StyledFormDesc>
                            {!success
                                ? code
                                    ? localize.strings.ResetPasswordDesc
                                    : localize.strings.VerificationCodeDesc
                                : localize.strings.PASSWORD_RESET_OK}
                        </StyledFormDesc>
                        {FIELDS.map(item => (
                            <div
                                className="row"
                                style={{
                                    display: success
                                        ? 'none'
                                        : (item.key === 'code' && code) || (item.key !== 'code' && !code)
                                        ? 'none'
                                        : 'block'
                                }}
                                key={item.key}
                            >
                                <label className="input-label">{item.label()}</label>
                                <div className="default-input">
                                    <div
                                        className={classnames('input-box', {
                                            error: errors[item.key]
                                        })}
                                    >
                                        <input
                                            ref={register(item.register(getValues))}
                                            type={item.type}
                                            name={item.key}
                                            className="input-box"
                                            autoComplete={item.autoComplete}
                                            placeholder={item.placeholder}
                                            onFocus={() => setResetPasswordFailed(false)}
                                            onKeyUp={e => {
                                                if (e.keyCode === 13) {
                                                    setIsSubmit(true)
                                                    setTimeout(() => {
                                                        code ? handleSubmit(onSubmit)() : handleCodeSumbit()
                                                        setIsSubmit(false)
                                                    }, 300)
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ wordWrap: 'break-word', color: 'red', width: '368px' }}>
                                    <label
                                        className="input-label error"
                                        style={{ textAlign: 'left', color: 'red', whiteSpace: 'pre-wrap' }}
                                    >
                                        <ErrorMessage errors={errors} name={item.key} message={item.errorMsg()} />
                                    </label>
                                </div>
                            </div>
                        ))}
                        <div
                            onClick={() => {
                                if (success) {
                                    window.location.reload()
                                } else {
                                    code ? handleSubmit(onSubmit)() : handleCodeSumbit()
                                }
                            }}
                            className="btn btn-default btn-large"
                            role="button"
                            aria-hidden
                            disabled={isSubmit}
                        >
                            {success ? localize.strings.BackToLogin : localize.strings.Continue}
                        </div>
                        {!success && (
                            <StyledTips onClick={handleGoBack}>
                                {code ? localize.strings.BackToRefillCode : localize.strings.BackToRefillEmail}
                            </StyledTips>
                        )}
                    </StyledFormContainer>
                </div>
            </section>
        </form>
    )
}

export default ResetPasswordForm
