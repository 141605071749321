import React, { forwardRef } from 'react';

import Highcharts from 'highcharts/highstock';
import mergeWith from 'lodash/mergeWith';

import StockBase, { StockCommonProps, StockBaseRef } from './StockBase';
import { replaceIfArray } from './utils';

export const ColumnStock = forwardRef<StockBaseRef, StockCommonProps>(({ defaultOptions, ...otherProps }, ref) => {
  const myDefaultOptions: Highcharts.Options = mergeWith(
    {
      chart: {
        type: 'column',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataGrouping: {
            units: [['month', null]],
          },
        },
      },
      yAxis: {
        labels: {
          align: 'left',
          x: 0,
        },
        opposite: false,
      },
    },
    defaultOptions,
    replaceIfArray,
  );

  return <StockBase ref={ref} defaultOptions={myDefaultOptions} {...otherProps} />;
});

ColumnStock.defaultProps = {};

export default ColumnStock;
