import { darken } from '@material-ui/core/styles';

type Unpack<T> = T extends Array<infer U> ? U : never;
type Color = string;
type Scale = 0 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
type ColorGroup = Unpack<typeof scaleColors>;
type ScaleColorMap = Record<ColorGroup, Record<Scale, Color>>;
type BlackWhiteMap = Record<'black' | 'white', Color>;
type ColorMap = ScaleColorMap & BlackWhiteMap;

/**
 * This should be automatically generated by some plugin/loader/whatever.
 * But we haven't found a decent way to do this, should every time you want
 * to change variable, you change both scss file and ts file.
 */
const colors = {
  technologyBlue000: '#bfd4df',
  technologyBlue100: '#99bbcb',
  technologyBlue200: '#7faabe',
  technologyBlue300: '#6699b2',
  technologyBlue400: '#40809f',
  technologyBlue500: '#1a668b',
  technologyBlue600: '#00557e',
  technologyBlue700: '#00486b',
  technologyBlue800: '#00405e',
  technologyBlue900: '#002a3f',
  priveGreen000: '#d7efe4',
  priveGreen100: '#bfe6d4',
  priveGreen200: '#afdfc9',
  priveGreen300: '#a0d9bf',
  priveGreen400: '#88d0af',
  priveGreen500: '#70c69f',
  priveGreen600: '#60c094',
  priveGreen700: '#52a37e',
  priveGreen800: '#48906f',
  priveGreen900: '#30604a',
  gray000: '#fafafa',
  gray100: '#f5f5f5',
  gray200: '#eeeeee',
  gray300: '#e0e0e0',
  gray400: '#bdbdbd',
  gray500: '#9e9e9e',
  gray600: '#757575',
  gray700: '#616161',
  gray800: '#424242',
  gray900: '#212121',
  greenGray000: '#eceff1',
  greenGray100: '#cfd8dc',
  greenGray200: '#b0bec5',
  greenGray300: '#90a4ae',
  greenGray400: '#78909c',
  greenGray500: '#6e7c85',
  greenGray600: '#50626d',
  greenGray700: '#3d505d',
  greenGray800: '#34444f',
  greenGray900: '#2b3841',
  black: '#000000',
  white: '#ffffff',
  positive000: '#e8f5e9',
  positive100: '#f5f5f5',
  positive200: '#a5d6a7',
  positive300: '#81c784',
  positive400: '#66bb6a',
  positive500: '#4caf50',
  positive600: '#43a047',
  positive700: '#388e3c',
  positive800: '#2e7d32',
  positive900: '#1b5e20',
  negative000: '#ffebee',
  negative100: '#ffcdd2',
  negative200: '#ef9a9a',
  negative300: '#e57373',
  negative400: '#ef5350',
  negative500: '#f44336',
  negative600: '#e53935',
  negative700: '#d32f2f',
  negative800: '#c62828',
  negative900: '#b71c1c',
  pink000: '#fce4ec',
  pink100: '#f8bbd0',
  pink200: '#f48fb1',
  pink300: '#f06292',
  pink400: '#ec407a',
  pink500: '#e91e63',
  pink600: '#d81b60',
  pink700: '#c2185b',
  pink800: '#ad1457',
  pink900: '#880e4f',
  purple000: '#f3e5f5',
  purple100: '#e1bee7',
  purple200: '#ce93d8',
  purple300: '#ba68c8',
  purple400: '#ab47bc',
  purple500: '#9c27b0',
  purple600: '#8e24aa',
  purple700: '#7b1fa2',
  purple800: '#6a1b9a',
  purple900: '#4a148c',
  deepPurple000: '#ede7f6',
  deepPurple100: '#d1c4e9',
  deepPurple200: '#b39ddb',
  deepPurple300: '#9575cd',
  deepPurple400: '#7e57c2',
  deepPurple500: '#673ab7',
  deepPurple600: '#5e35b1',
  deepPurple700: '#512da8',
  deepPurple800: '#4527a0',
  deepPurple900: '#311b92',
  indigo000: '#e8eaf6',
  indigo100: '#c5cae9',
  indigo200: '#9fa8da',
  indigo300: '#7986cb',
  indigo400: '#5c6bc0',
  indigo500: '#3f51b5',
  indigo600: '#3949ab',
  indigo700: '#303f9f',
  indigo800: '#283593',
  indigo900: '#1a237e',
  blue000: '#e3f2fd',
  blue100: '#bbdefb',
  blue200: '#90caf9',
  blue300: '#64b5f6',
  blue400: '#42a5f5',
  blue500: '#2196f3',
  blue600: '#1e88e5',
  blue700: '#1976d2',
  blue800: '#1565c0',
  blue900: '#0d47a1',
  lightBlue000: '#e1f5fe',
  lightBlue100: '#b3e5fc',
  lightBlue200: '#81d4fa',
  lightBlue300: '#4fc3f7',
  lightBlue400: '#29b6f6',
  lightBlue500: '#03a9f4',
  lightBlue600: '#039be5',
  lightBlue700: '#0288d1',
  lightBlue800: '#0277bd',
  lightBlue900: '#01579b',
  lightTeal000: '#e0f7fa',
  lightTeal100: '#b2ebf2',
  lightTeal200: '#80deea',
  lightTeal300: '#4dd0e1',
  lightTeal400: '#26c6da',
  lightTeal500: '#00bcd4',
  lightTeal600: '#00acc1',
  lightTeal700: '#0097a7',
  lightTeal800: '#00838f',
  lightTeal900: '#006064',
  teal000: '#e0f2f1',
  teal100: '#b2dfdb',
  teal200: '#80cbc4',
  teal300: '#4db6ac',
  teal400: '#26a69a',
  teal500: '#009688',
  teal600: '#00897b',
  teal700: '#00796b',
  teal800: '#00695c',
  teal900: '#004d40',
  lightGreen000: '#f1f8e9',
  lightGreen100: '#dcedc8',
  lightGreen200: '#c5e1a5',
  lightGreen300: '#aed581',
  lightGreen400: '#9ccc65',
  lightGreen500: '#8bc34a',
  lightGreen600: '#7cb342',
  lightGreen700: '#689f38',
  lightGreen800: '#558b2f',
  lightGreen900: '#33691e',
  lime000: '#f9fbe7',
  lime100: '#f0f4c3',
  lime200: '#e6ee9c',
  lime300: '#dce775',
  lime400: '#d4e157',
  lime500: '#cddc39',
  lime600: '#c0ca33',
  lime700: '#afb42b',
  lime800: '#9e9d24',
  lime900: '#827717',
  yellow000: '#fffde7',
  yellow100: '#fff9c4',
  yellow200: '#fff59d',
  yellow300: '#fff176',
  yellow400: '#ffee58',
  yellow500: '#ffeb3b',
  yellow600: '#fdd835',
  yellow700: '#fbc02d',
  yellow800: '#f9a825',
  yellow900: '#f57f17',
  amber000: '#fff8e1',
  amber100: '#ffecb3',
  amber200: '#ffe082',
  amber300: '#ffd54f',
  amber400: '#ffca28',
  amber500: '#ffc107',
  amber600: '#ffb300',
  amber700: '#ffa000',
  amber800: '#ff8f00',
  amber900: '#ff6f00',
  orange000: '#fff3e0',
  orange100: '#ffe0b2',
  orange200: '#ffcc80',
  orange300: '#ffb74d',
  orange400: '#ffa726',
  orange500: '#ff9800',
  orange600: '#fb8c00',
  orange700: '#f57c00',
  orange800: '#ef6c00',
  orange900: '#e65100',
  deepOrange000: '#fbe9e7',
  deepOrange100: '#ffccbc',
  deepOrange200: '#ffab91',
  deepOrange300: '#ff8a65',
  deepOrange400: '#ff7043',
  deepOrange500: '#ff5722',
  deepOrange600: '#f4511e',
  deepOrange700: '#e64a19',
  deepOrange800: '#d84315',
  deepOrange900: '#bf360c',
  brown000: '#efebe9',
  brown100: '#d7ccc8',
  brown200: '#bcaaa4',
  brown300: '#a1887f',
  brown400: '#8d6e63',
  brown500: '#795548',
  brown600: '#6d4c41',
  brown700: '#5d4037',
  brown800: '#4e342e',
  brown900: '#3e2723',
  paleOliveGreen000: '#f1f8e9',
  paleOliveGreen100: '#dcedc8',
  paleOliveGreen200: '#c5e1a5',
  paleOliveGreen300: '#ace962',
  paleOliveGreen400: '#9ccc65',
  paleOliveGreen500: '#8bc34a',
  paleOliveGreen600: '#7cb342',
  paleOliveGreen700: '#689f38',
  paleOliveGreen800: '#558b2f',
  paleOliveGreen900: '#33691e',
} as { [key: string]: Color };

const scaleColors = [
  'technologyBlue' as 'technologyBlue',
  'priveGreen' as 'priveGreen',
  'gray' as 'gray',
  'greenGray' as 'greenGray',
  'positive' as 'positive',
  'negative' as 'negative',
  'pink' as 'pink',
  'purple' as 'purple',
  'deepPurple' as 'deepPurple',
  'indigo' as 'indigo',
  'blue' as 'blue',
  'lightBlue' as 'lightBlue',
  'lightTeal' as 'lightTeal',
  'teal' as 'teal',
  'lightGreen' as 'lightGreen',
  'lime' as 'lime',
  'yellow' as 'yellow',
  'amber' as 'amber',
  'orange' as 'orange',
  'deepOrange' as 'deepOrange',
  'brown' as 'brown',
  'paleOliveGreen' as 'paleOliveGreen',
];

const colorMap: ScaleColorMap = scaleColors.reduce<ScaleColorMap>(
  (carry, colorGroup) => ({
    ...carry,
    [colorGroup]: Array(10)
      .fill(null)
      .map((_, index) => index)
      .reduce(
        (carry, index) => ({
          ...carry,
          [index * 100]: colors[`${colorGroup}${(index * 100).toString().padStart(3, '0')}`],
        }),
        {},
      ),
  }),
  {} as ScaleColorMap,
);

const blackWhiteMap: BlackWhiteMap = {
  black: colors.black,
  white: colors.white,
};

const HoverDarkenCoefficient = 0.15;

export const hoverColor = (color: string) => darken(color, HoverDarkenCoefficient);

export default { ...colorMap, ...blackWhiteMap } as ColorMap;
