const strings = {
    Selection: '選擇',

    // dial in language
    DialInLanguage: '撥入語言',
    English: '英語',
    Cantonese: '廣東話',
    Mandarin: '普通話',

    // role
    Admin: '管理員',
    Supervisor: '監督員',
    SalesAgent: '銷售員',

    // product type
    ProductType: '產品類別',
    QuickCash: '(QC)「Quick Cash」套現分期計劃',
    EPP: '(EPP1) 賬單及簽賬「分期更好使」計劃之 - 「月平息方案」',
    EPP2: '(EPP2) 賬單及簽賬「分期更好使」計劃之 - 「一次性手續費方案」',
    PersonalLoan: '(PIL) 私人貸款',
    BalACheck: '(BAC) 折現計劃',

    // Login
    LoginTitle: '登入',
    ChangePasswordNotice:
        '自 2022 年 8 月 27 日八時起，所有 TTS 用戶在首次登陸時都必須重置帳號密碼。請點擊“忘記密碼”進行密碼重置。',
    LoginFailed: '登入失敗',
    UserID: '用戶登入帳號',
    UserIDPlaceHolder: '用戶登入帳號',
    Password: '密碼',
    NewPassword: '新的密碼',
    ConfirmPassword: '確認密碼',
    ForgetPassword: '忘記密碼',
    ResetPassword: '重置密碼',
    PasswordNotMatch: '密碼不符',
    InvalidPassword: '密碼格式不符\n它必須包含至少一個大小寫字母、一個數字和至少 16 個字符。',
    LoginButton: '登入',
    ForgetPasswordDesc: '請輸入用戶登入帳號，我們將會發送一個驗證碼至您的電郵，以方便您重置密碼。',
    ResetPasswordDesc: '新的密碼必須包含至少一個大小寫字母、一個數字和至少 16 個字符。',
    VerificationCodeDesc: '請輸入您在電郵中收到的驗證碼',
    Email: '電郵',
    Continue: '繼續',
    BackToLogin: '回到登入頁面',
    BackToRefillEmail: '重新填寫用戶登入帳號',
    BackToRefillCode: '重新填寫驗證碼',
    CodePlaceholder: '123abc',

    // sidebar
    TextToSpeech: 'TTS 語音',

    // Quick Cash, EPP, EPP2
    LoanAmount: '貸款總額',
    MonthlyFlatRate: '每月行政費用率',
    OneTimeBookingFee: '一次性手續費',
    Tenor: '還款期',
    LoanDate: '批核日',
    CycleDate: '截數日',

    FirstInstallmentDate: '第一期供款日',
    APR: '實際年利率',
    InitialFee: '首次行政費',
    TotalMonthlyFee: '總每月行政費（不包括首次行政費）',
    MonthlyInstallment: '每月還款額',
    TotalInstallmentAmount: '總供款額（申請貸款總額+首次行政費+總每月行政費）',
    TotalInstallmentAmountEPP2: '總供款額（申請貸款總額 + 一次性手續費）',
    FirstInstallmentPrincipalAmount: '首月本金',
    LastInstallmentPrincipalAmount: '最後一期本金',
    FirstInstallmentMonthlyFee: '首月行政費',
    LastInstallmentMonthlyFee: '最後一期行政費',

    // BAC (Bal-a-Check)
    BACLoanAmount: '折現金額',
    AnnualRate: '年息 (p.a.)',
    BACAPR: '實際年利率 (APR)',
    BACTenor: '優惠期',
    AssumingApprovalDate: '假設此計劃之批核日',
    EndDateLowRatePeriod: '低息優惠期的結束日期',
    StartDateNormalRatePeriod: '正常利率期開始日期',
    AvailableCreditLimit: '可用信用額',

    // PIL
    TotalLoanAmount: '總貸款額',
    DisbursementAmount: '發放貸款金額',
    OutstandingLoanAmount: '現貸款結餘金額',
    PILMonthlyFlatRate: '月平息',
    PILFirstInstallmentMonthlyFee: '首月利息',
    PILLastInstallmentMonthlyFee: '最後一期利息',
    ExtensionFee: '延期費用',
    AccountNumber: '還款戶口',
    TotalInterest: '總利息',
    TotalRepaymentAmount: '貸款金額',

    // Create New User
    UserFormTitle: '使用者資料細項',
    FirstName: '姓名',
    LastName: '姓氏',
    Username: '使用者名稱',
    UserType: '使用者類別',
    Group: '組織',
    Team: '團隊',
    Status: '用戶狀態',
    StatusActive: '活躍',
    StatusInactive: '不動戶',

    // Error Messages
    Required: '* 必填項',
    AnyNumberLargerThanZero: '* 請填寫大於零的數字',
    AnyNumberLargerThanOrEqualsZero: '* 請填寫大於或等於零的數字',
    AccountNumberRequired: '* 請輸入還款戶口號碼',
    InvalidCycleDayOfMonth: '* 請輸入介於 1 和 31 之間的有效月曆日期',
    InvalidLoanAmountRangePIL: '* 請輸入介於 $8,000 和 $5,000,000 之間的有效金額',
    InvalidLoanAmountRangeEPP: '* 請輸入介於 $200 和 $3,000,000 之間的有效金額',
    InvalidLoanAmountRangeEPP2: '* 請輸入介於 $200 和 $3,000,000 之間的有效金額',
    InvalidLoanAmountRangeQC: '* 請輸入介於 $3,000 和 $3,000,000 之間的有效金額',
    InvalidAvailableCreditLimitRangeBAC: '* 請輸入介於 $0 和 $3,000,000 之間的有效金額',
    NegativeDisbursementAmount: '* 必須大於（或等於）“現貸款結餘金額”和“延期費用”之總和',
    InvalidAccountNumber: '* 請輸入有效的帳戶號碼',
    invalidMonthlyFlatRateQC: '* 請輸入介於 0.1% - 1.5% 之間的有效百分數',
    invalidMonthlyFlatRateEPP: '* 請輸入介於 0.1% - 1.5% 之間的有效百分數',
    invalidOneTimeBookingFee: '* 請輸入介於 0% - 16% 之間的有效百分數',
    InvalidEmail: '* 無效的電郵',
    invalidMonthlyFlatRateBAC: '* 請輸入介於 1% - 40% 之間的有效百分數',

    DownloadAuditLogErrorTitle: '請求已過期',
    DownloadAuditLogErrorMessage: '您下载數據軌跡的请求已超时。請稍後再試。',
    DownloadAuditLogOKButton: '確認',

    UpdateUserDialogErrorTitle: '更新用戶資料失敗',
    UpdateUserDialogErrorMessage: '請重新嘗試或是聯絡Privé Technologies客服務人員',
    UpdateUserDialogErrorButton: '確認',

    // Others
    CreateTTS: '生成語音',
    CreateNew: '創建使用者',
    NewUser: '新使用者',
    Back: '返回',
    Next: '下一步',
    Calculation: '計算結果',
    LastStep: '最後一步',
    DialInTheNumberBelow: '請撥入下面號碼聽取語音',
    DialIn: '撥入號碼',
    UniqueKey: '主鍵',
    AccountMaintenance: '管理用戶',
    DownloadAuditLog: '下載數據軌跡',
    DynamicTTS: '實時語音',

    OkDatePicker: '確認',
    CancelDatePicker: '取消',
    Create: '創建',
    Save: '更新',
    Edit: '編輯',
    Enabled: '啟用',
    Disabled: '停用',

    PASSWORD_RESET_OK: '密碼已重置。請您回到登入頁面，用新密碼重新登入。',
    // Reset password error message
    PASSWORD_RESET_NO_EMAIL_FOUND:
        '用戶登入帳號或電郵地址不存在\n\n您在系統內尚未登記帳號或電郵，請與您所在銀行的管理員或與Privé支援聯絡（support@privetechnologies.com）。',
    PASSWORD_RESET_VERIFICATION_CODE_ERROR: '驗證錯誤\n\n請檢查您的電郵並輸入正確的驗證碼，或再次嘗試發送新的驗證碼。',
    NO_PRODUCT_AVAILABLE: '沒有可用的產品，請與您所在銀行的管理員或與Privé支援聯絡（support@privetechnologies.com）'
}

const zh = strings
export default zh
