import React, { ReactNode, RefObject } from 'react';
import LazyRenderer from './LazyRenderer';
import { toKebabCase } from 'utils/string';
import * as WebComponent from 'types/WebComponent';
import * as Parsers from './attributeParsers';
interface MyCustomElement<P extends Props, C extends React.ComponentType<P>> extends HTMLElement {
  renderer: LazyRenderer<P, C>;
}

function makeCustomElement<P extends Props, C extends React.ComponentType<P>>(
  /**
   * Since CustomElement & LazyRenderer are circular dependencies,
   * pass this callback to make it work.
   */
  makeRenderer: WebComponent.TMakeRenderer<P, C>,
  config: {
    parsers: {
      [K in keyof P]: WebComponent.TAttributeParser<P[K]>;
    };
    events?: (keyof P & string)[];
    children?: {
      [subElement: string]: WebComponent.TChildMapper<P>;
    };
  },
) {
  return class CustomElement extends HTMLElement implements MyCustomElement<P, C> {
    renderer: LazyRenderer<P, C> = makeRenderer(this);

    /**
     * Prevent accidental `new` call on this class.
     * Since calling `new` on a custom element will throw Error in browser.
     */
    public constructor() {
      super();
      for (const attribute in config.parsers) {
        try {
          Object.defineProperty(this, attribute, {
            enumerable: true,
            get: () => this.getValue(attribute),
            set: newValue => this.setAttribute(toKebabCase(attribute), newValue),
          });
        } catch (ignored) {}
      }
    }

    reactChild?: ReactNode[];

    /**
     * Those will be injected into react component's props.
     * should be kebab cases.
     */
    static get observedAttributes(): string[] {
      return Object.keys(config.parsers).map(toKebabCase);
    }

    /**
     * Get props by attribute.
     */
    getValue<K extends keyof P & string, V extends P[K]>(attribute: K): V {
      const value = this.getAttribute(toKebabCase(attribute));

      return config.parsers[attribute](value);
    }

    /**
     * Get props by attribute.
     */
    getEventForward<K extends keyof P & string, V extends P[K]>(event: K): V {
      return ((...args: any) => {
        const customEvt = new CustomEvent(event.replace(/^on/, '').toLowerCase(), {
          detail: args,
          bubbles: true,
        });
        this.dispatchEvent(customEvt);
      }) as P[K];
    }

    /**
     * Get all props.
     */
    getValues(): P {
      const result: P = {} as P;

      for (const key in config.parsers) {
        result[key] = this.getValue(key);
      }

      if (config.events) {
        for (const key of config.events) {
          result[key] = this.getEventForward(key);
        }
      }
      return result;
    }

    getChildrens(props: P): React.ReactNode[] | undefined {
      if (config.children) {
        const ret = [] as ReactNode[];
        for (const dom of Array.from(this.children)) {
          if (!dom.tagName) {
            continue;
          }
          const tagName = dom.tagName.toLowerCase();
          const callbackFn = config.children[tagName];
          if (!callbackFn) {
            continue;
          }
          const react = callbackFn(dom as HTMLElement, props);
          if (react) {
            ret.push(react);
          }
        }
        if (ret.length) {
          return ret;
        }
      }
      return undefined;
    }

    /**
     * Similar to `componentWillMount`.
     *
     * This is called when browser put this element into the DOM tree.
     *
     * Possbile mutiple times for an element if detach/attach.
     *
     * Possbile by appendChild, innerHTML, etc
     */
    connectedCallback() {
      const props = this.getValues();
      this.reactChild = this.getChildrens(props);
      this.renderer.renderWithProps(props, undefined, this.reactChild);
    }

    /**
     * Similar to `static getDerivedStateFromProps()`.
     *
     * This is called when HTMLElement.setAttribute happened.
     *
     * Possible by setAttribute, innerHTML, element upgrade.
     *
     * Possible before/after connectedCallback/disconnectedCallback
     */
    attributeChangedCallback(/*name, oldValue, newValue*/) {
      if (this.isConnected) {
        const props = this.getValues();
        this.reactChild = this.getChildrens(props);
        this.renderer.renderWithProps(props, undefined, this.reactChild);
      }
    }

    /**
     * Similar to `componentWillUnmount`.
     *
     * Possbile by removeChild, innerHTML, etc
     */
    disconnectedCallback() {
      this.renderer.unmount();
    }
  };
}

class RealDomWrapper extends React.PureComponent<WebComponent.HasElement, any, any> {
  private ref: RefObject<any>;
  private origParent: ParentNode | null;

  constructor(props: WebComponent.HasElement) {
    super(props);
    this.ref = React.createRef<any>();
    this.origParent = props.$ele.parentNode;
  }

  componentDidMount() {
    this.ref.current.appendChild(this.props.$ele);
  }
  componentWillUnmount() {
    if (this.origParent) {
      this.origParent.append(this.props.$ele);
    } else if (this.props.$ele.parentNode) {
      this.props.$ele.parentNode.removeChild(this.props.$ele);
    }
  }
  render() {
    return React.createElement('div', { ref: this.ref });
  }
}

export { makeCustomElement, RealDomWrapper, LazyRenderer, Parsers };
