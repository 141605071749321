import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SnackbarContent, { SnackbarContentProps } from '@material-ui/core/SnackbarContent';
import { CheckCircle, Warning, Error, Info } from '@material-ui/icons';

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
};

type Props = SnackbarContentProps & {
  title?: string;
} & typeof defaultProps;

const defaultProps = Object.freeze({
  iconVariant: 'info' as keyof typeof variantIcon,
  icon: 'left' as 'left' | 'right' | 'none',
});

const useStyles = makeStyles<Theme, Props>((theme) => {
  const chooseColor = ({ iconVariant }: Props) =>
    iconVariant === 'success'
      ? theme.colors.positive[600]
      : iconVariant === 'warning'
      ? theme.colors.amber[600]
      : iconVariant === 'error'
      ? theme.colors.negative[600]
      : iconVariant === 'info'
      ? theme.colors.technologyBlue[600]
      : 'default';

  return createStyles({
    root: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      borderRadius: theme.spacing(0.5),
      display: 'flex',
      flexWrap: 'nowrap',
      borderLeft: (props) => `8px solid ${chooseColor(props)}`,
    },

    message: {
      width: '100%',
      whiteSpace: 'pre-wrap',
    },

    action: {
      marginRight: 'auto',
    },

    title: {
      margin: theme.spacing(0, 0, 1, 0),
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '0.6875rem',
      lineHeight: '1rem',
    },

    messageContainer: {
      flex: 1,
    },

    messageRoot: {
      margin: 0,
      fontSize: '0.875rem',
      lineHeight: '1rem',
      color: theme.colors.greenGray[700],
    },

    icon: {
      color: chooseColor,
    },
  });
});

const CustomSnackbarContent = React.forwardRef((props: Props, ref?: React.Ref<any>) => {
  const { title, iconVariant, icon, message, className, ...others } = props;
  const Icon = variantIcon[iconVariant];
  const classes = useStyles(props);

  return (
    <SnackbarContent
      ref={ref}
      className={className}
      classes={{
        root: classes.root,
        message: classes.message,
        action: classes.action,
      }}
      message={
        <Grid container direction="row" alignItems="center" spacing={1}>
          {icon === 'left' && (
            <Grid item>
              <Icon className={classes.icon} />
            </Grid>
          )}

          <Grid item className={classes.messageContainer}>
            {title && <p className={classes.title}>{title}</p>}
            {message && <p className={classes.messageRoot}>{message}</p>}
          </Grid>

          {icon === 'right' && (
            <Grid item>
              <Icon className={classes.icon} />
            </Grid>
          )}
        </Grid>
      }
      {...others}
    />
  );
});

CustomSnackbarContent.defaultProps = defaultProps;

export type { Props as SnackbarProps };
export default CustomSnackbarContent;
