import React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputBase from './InputBase';

const CustomTextField = (props: TextFieldProps) => (
  <FormControl error={props.error} fullWidth={props.fullWidth}>
    {props.label}
    <InputBase
      inputRef={props.inputRef}
      disabled={props.disabled}
      value={props.value}
      placeholder={props.placeholder}
      onBlur={props.onBlur}
      onClick={props.onClick}
      onChange={props.onChange}
      {...props.InputProps}
    />
    {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
  </FormControl>
);

export default CustomTextField;
