import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    tooltip: {
      padding: theme.spacing(1),
      backgroundColor: theme.colors.greenGray[800],
    },
  }),
)(Tooltip);
