/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
import axios from 'axios'
import endpoints from '../configs/endpoints'

class AuthService {
    /** call login endpoint */
    login({ username, password }) {
        return new Promise((resolve, reject) => {
            const url = window._env_.isWebpack ? '/login/' : endpoints.baseMonolithServiceUrl + endpoints.authUrl

            const loginReq = { username, password }
            const loginHeaders = { 'Content-Type': 'application/json' }
            try {
                axios
                    .post(url, loginReq, { headers: loginHeaders })
                    .then(response => {
                        /** validate status */
                        if (response.status !== 200) {
                            return reject(Error(response.status))
                        }
                        /** validate token */
                        const { accessToken } = response.data
                        if (!accessToken || accessToken === '') {
                            return reject(Error('Login Failed'))
                        }
                        /** resolve result */
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            } catch (e) {
                reject(e)
            }
        })
    }

    forgetPassword({ username }) {
        return new Promise((resolve, reject) => {
            const url = window._env_.isWebpack
                ? '/login/password/forgot'
                : endpoints.baseMonolithServiceUrl + endpoints.forgetPassword
            const loginReq = { username }
            const loginHeaders = { 'Content-Type': 'application/json' }
            try {
                axios
                    .post(url, loginReq, { headers: loginHeaders })
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        if (error.response.status === 401) reject({ result: 'PASSWORD_RESET_USERNAME_NOT_FOUND' })
                        reject(error.response.data)
                    })
            } catch (e) {
                reject(e)
            }
        })
    }

    resetPassword({ verificationToken, password }) {
        return new Promise((resolve, reject) => {
            const url = window._env_.isWebpack
                ? '/login/password/reset'
                : endpoints.baseMonolithServiceUrl + endpoints.resetPassword

            const loginReq = { verificationToken, password }
            const loginHeaders = { 'Content-Type': 'application/json' }
            try {
                axios
                    .post(url, loginReq, { headers: loginHeaders })
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        if (error.response.status === 401) reject({ result: 'PASSWORD_RESET_USERNAME_NOT_FOUND' })
                        reject(error.response.data)
                    })
            } catch (e) {
                reject(e)
            }
        })
    }
}

export default new AuthService()
