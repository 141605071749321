import React, { lazy, useState, useLayoutEffect } from 'react';
import { findRegisteredComponent } from './WidgetRegistry';
import type { LazyExoticComponent, FunctionComponent } from 'react';
import type ClientWidgetConfiguration from './WizardTypes/ClientWidgetConfiguration';
import type { WidgetTitleDescription } from './WidgetRegistry';

const map = new Map<string, LazyExoticComponent<FunctionComponent<any>>>();
export default function getLazyComponent(
  stepConfig: ClientWidgetConfiguration<FunctionComponent<any>>,
): LazyExoticComponent<FunctionComponent<any>> {
  let ret = map.get(stepConfig.component);
  if (ret) {
    // This has to be the same lazy instance to avoid react do in a new wrong instance
    return ret;
  }
  ret = lazy(findRegisteredComponent(stepConfig.component));
  map.set(stepConfig.component, ret);
  return ret;
}

type Variant = 'wait' | 'current' | 'done' | 'error';
interface Props {
  render: (title: string, description: string, variant?: Variant) => JSX.Element;
  variant?: Variant;
  config: ClientWidgetConfiguration<any>;
}
/**
 * A render props
 */
export function LazyTitleAndDescripton({ config, variant, render }: Props): JSX.Element {
  const [Widget, setWidget] = useState<WidgetTitleDescription>();
  useLayoutEffect(() => {
    setWidget(undefined);
    const comp = findRegisteredComponent(config.component);
    comp().then((code) => {
      if ('WidgetTitleDescription' in code && code.WidgetTitleDescription) {
        // avoid execute the FunctionalComponent
        setWidget(() => code.WidgetTitleDescription);
      }
    });
  }, [config]);
  if (Widget) {
    return <Widget {...config.initialProps} render={(title, description) => render(title, description, variant)} />;
  }
  return render(config.title || '...', config.description || '', variant);
}
