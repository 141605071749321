import React from 'react';
import { RadioButtonUncheckedOutlined, RadioButtonCheckedOutlined } from '@material-ui/icons';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      color: theme.colors.positive[600],
      '&$checked': {
        color: theme.colors.positive[600],
      },
    },
    checked: {},
  }),
)((props: RadioProps) => {
  return <Radio icon={<RadioButtonUncheckedOutlined />} checkedIcon={<RadioButtonCheckedOutlined />} {...props} />;
});
