/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react'
import classnames from 'classnames'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { getResetPasswordErrorCode } from './constants'
import localize from '../../utils/localize'
import { AuthContext } from '../../auth/AuthContextProvider'
import AuthService from '../../services/AuthService'

const StyledFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 480px;
`
const StyledFormDesc = styled.div`
    width: 400px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px;
`

const StyledTips = styled.div`
    margin-top: 20px;
    font-size: 16px;
    cursor: pointer;
    :hover {
        opacity: 0.7;
    }
`
const StyledTitle = styled.h3`
    font-weight: bold;
`

const StyledErrorMsg = styled.div`
    white-space: pre-wrap;
    width: 480px;
`

const FIELDS = [
    {
        key: 'username',
        type: 'text',
        label: '',
        register: {
            required: true
        },
        placeholder: () => localize.strings.UserIDPlaceHolder,
        errorMsg: () => localize.strings.Required,
        autoComplete: 'on'
    }
]

const ForgetPasswordForm = props => {
    const { setStatus } = props
    const { authState } = useContext(AuthContext)
    const [sentEmailFailed, setSentEmailFailed] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const { register, handleSubmit, errors, clearErrors } = useForm()
    const setError = code => {
        setTimeout(() => {
            setIsSubmit(false)
        }, 1000)
        setSentEmailFailed(getResetPasswordErrorCode(code))
    }
    const onSubmit = data => {
        setIsSubmit(true)
        AuthService.forgetPassword(data)
            .then(({ result }) => {
                setStatus('resetPassword')
                return sentEmailFailed(false)
            })
            .catch(error => {
                const { result } = error
                setError(result)
            })
    }

    const handleGoBackToLoign = () => setStatus('login')

    useEffect(() => {
        clearErrors()
        setSentEmailFailed(false)
    }, [authState.locale])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <section className="content container-fluid tts-login">
                <div className="row-section flex-center login-section">
                    <StyledErrorMsg className="row">
                        <p className="error">{sentEmailFailed || ''}</p>
                    </StyledErrorMsg>
                    <StyledFormContainer>
                        <StyledTitle>{localize.strings.ForgetPassword}</StyledTitle>
                        <StyledFormDesc>{localize.strings.ForgetPasswordDesc}</StyledFormDesc>
                        {FIELDS.map(item => (
                            <div className="row" key={item.key}>
                                <div className="default-input">
                                    <div
                                        className={classnames('input-box', {
                                            error: errors[item.key]
                                        })}
                                    >
                                        <input
                                            ref={register(item.register)}
                                            onFocus={() => setSentEmailFailed(false)}
                                            type={item.type}
                                            name={item.key}
                                            className="input-box"
                                            autoComplete={item.autoComplete}
                                            placeholder={item.placeholder()}
                                        />
                                    </div>
                                </div>
                                <div style={{ wordWrap: 'break-word', color: 'red', width: '368px' }}>
                                    <label className="input-label error" style={{ textAlign: 'left', color: 'red' }}>
                                        <ErrorMessage errors={errors} name={item.key} message={item.errorMsg()} />
                                    </label>
                                </div>
                            </div>
                        ))}

                        <button type="submit" disabled={isSubmit} className="btn btn-default btn-large">
                            {localize.strings.Continue}
                        </button>
                        <StyledTips onClick={handleGoBackToLoign}>{localize.strings.BackToLogin}</StyledTips>
                    </StyledFormContainer>
                </div>
            </section>
        </form>
    )
}

export default ForgetPasswordForm
