const storage = window.sessionStorage;

/** get item from local storage */
const getItem = key => {
    try {
        let value = storage.getItem(key);
        if (!value) {
            return undefined
        }
        return JSON.parse(value);
    } catch (err) {
        return undefined;
    }
}

/** set item to local storage */
const setItem = (key, value) => {
    try {
        storage.setItem(key, JSON.stringify(value));
    } catch (err) {
        console.log("failed to set value to local storage" + key + " : " + value);
    }
}

export {getItem, setItem};
