/** available action */
const actionTypes = {
    LOGIN : "LOGIN",
    LOGOUT :"LOGOUT",
    SET_ENV : "SET_ENV",
    SET_LOCALE: "SET_LOCALE",
    SET_ENABLE_TEAM_STRUCTURE: "SET_ENABLE_TEAM_STRUCTURE",
    SET_ERROR: "SET_ERROR"
};

export default actionTypes;
