import { Theme } from '@material-ui/core';
import { ColDef, GridOptions, ICellRendererParams, RowNode } from 'ag-grid-community';
import get from 'lodash/get';
import set from 'lodash/set';

import { dateFormatter as d } from 'V2/utils/DateUtils';

import { DataGridPaginationProps } from './DataGridPagination';
import withThemeWrapper from './FrameworkComponents/withThemeWrapper';
import ChipCellRenderer from './FrameworkComponents/ChipCellRenderer';
import CurrencyCellEditor from './FrameworkComponents/CurrencyCellEditor';
import CurrencyCellRenderer from './FrameworkComponents/CurrencyCellRenderer';
import IconCellRenderer from './FrameworkComponents/IconCellRenderer';
import IconHeaderRenderer from './FrameworkComponents/IconHeaderRenderer';
import LinkCellRenderer from './FrameworkComponents/LinkCellRenderer';
import LoadingOverlay from './FrameworkComponents/LoadingOverlay';
import NoRowOverlay from './FrameworkComponents/NoRowOverlay';
import NumberCellEditor from './FrameworkComponents/NumberCellEditor';
import NumberCellRenderer from './FrameworkComponents/NumberCellRenderer';
import PaginationStatusBarComponent from './FrameworkComponents/PaginationStatusBarComponent';
import SelectCellEditor from './FrameworkComponents/SelectCellEditor';
import Tooltip from './FrameworkComponents/Tooltip';

type GridOptionName = Extract<keyof GridOptions, string>;

export const DefaultRowHeight = 32;

export const CellEditableClassRules = Object.freeze({
  'ag-editable': ({ colDef, node }: { colDef: ColDef, node: RowNode }) => (node.group || node.rowPinned) ? false : colDef.editable,
});

export const DefaultColumnTypes: {
  [key: string]: ColDef;
} = Object.freeze({
  /* Numeric */
  number: {
    cellClass: 'ag-right-aligned-cell',
    cellRenderer: 'puiNumberCell',
    cellEditor: 'puiNumberCellEditor',
    headerClass: 'ag-right-aligned-header',
  },
  currency: {
    cellClass: 'ag-right-aligned-cell',
    cellRenderer: 'puiCurrencyCell',
    cellEditor: 'puiCurrencyCellEditor',
    headerClass: 'ag-right-aligned-header',
  },
  /* Date & Time */
  date: {
    cellRenderer: (params: ICellRendererParams) => {
      if (params.value) {
        return d.formatDate(params.value);
      }

      return '';
    },
  },
  dateCustom: {
    cellRenderer: (params: ICellRendererParams) => {
      const format = (params as any).format;

      if (format === undefined) {
        throw new Error('Column type "dateCustom" error: typeProps.format is mandatory.');
      }

      if (params.value) {
        return d.formatCustom(params.value, format);
      }

      return '';
    },
  },
  dateTime24: {
    cellRenderer: (params: ICellRendererParams) => {
      if (params.value) {
        return d.formatDateTime24(params.value);
      }

      return '';
    },
  },
  time24: {
    cellRenderer: (params: ICellRendererParams) => {
      if (params.value) {
        return d.formatTime24(params.value);
      }

      return '';
    },
  },
  longTime24: {
    cellRenderer: (params: ICellRendererParams) => {
      // TODO Handle group
      if (params.value) {
        return d.formatLongTime24(params.value);
      }

      return '';
    },
  },
  /* Custom */
  chip: {
    cellRenderer: 'puiChipCell',
  },
  icon: {
    cellClass: 'ag-center-aligned-cell',
    cellRenderer: 'puiIconCell',
  },
  iconMenu: {
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    resizable: false,
    sortable: false,
    suppressMenu: true,
    cellClass: 'ag-icon-cell',
    cellRenderer: 'puiIconCell',
    headerClass: 'ag-icon-header',
  },
  link: {
    cellRenderer: 'puiLinkCell',
  },
});

export const DefaultColumnDef: ColDef = Object.freeze({
  resizable: true,
  sortable: true,
  tooltipComponent: 'puiTooltip'
});

export function generateDefaultFrameworkComponents(theme: Theme, paginationProps?: DataGridPaginationProps) {
  const paginationStatusProps: any =
    paginationProps !== undefined
      ? {
        textFormatter: paginationProps.textFormatter,
      }
      : undefined;

  return {
    /* Cells */
    puiIconCell: withThemeWrapper(IconCellRenderer, theme),
    puiIconHeader: withThemeWrapper(IconHeaderRenderer, theme),
    puiChipCell: withThemeWrapper(ChipCellRenderer, theme),
    puiCurrencyCell: withThemeWrapper(CurrencyCellRenderer, theme),
    puiLinkCell: withThemeWrapper(LinkCellRenderer, theme),
    puiNumberCell: withThemeWrapper(NumberCellRenderer, theme),
    /* Editors */
    puiCurrencyCellEditor: withThemeWrapper(CurrencyCellEditor, theme, { forwardRef: true }),
    puiNumberCellEditor: withThemeWrapper(NumberCellEditor, theme, { forwardRef: true }),
    puiSelectCellEditor: withThemeWrapper(SelectCellEditor, theme, { forwardRef: true }),
    /* Overlays */
    puiLoadingOverlay: withThemeWrapper(LoadingOverlay, theme),
    puiNoRowOverlay: withThemeWrapper(NoRowOverlay, theme),
    /* StatusBars */
    puiPaginationStatus: withThemeWrapper(PaginationStatusBarComponent, theme, {
      injectedProps: paginationStatusProps,
    }),
    /* Tooltip */
    puiTooltip: withThemeWrapper(Tooltip, theme),
  };
}

export function appendOption(target: GridOptions, propName: GridOptionName, extension?: Object) {
  if (extension === undefined) {
    return;
  }

  const original = get(target, propName);

  if (original === undefined) {
    set(target, propName, extension);
  } else {
    if (Array.isArray(extension)) {
      set(target, propName, [
        ...original,
        ...extension,
      ]);
    } else {
      set(target, propName, {
        ...original,
        ...extension,
      });
    }
  }
}

export function ensureOption(target: GridOptions, propName: GridOptionName, defaultOption: any) {
  if (defaultOption === undefined) {
    return;
  }

  const original = get(target, propName);
  if (original === undefined) {
    set(target, propName, defaultOption);
  }
}

export function prependOption(target: GridOptions, propName: GridOptionName, extension?: Object) {
  if (extension === undefined) {
    return;
  }

  const original = get(target, propName);

  if (original === undefined) {
    set(target, propName, extension);
  } else {
    if (Array.isArray(extension)) {
      set(target, propName, [
        ...extension,
        ...original,
      ]);
    } else {
      set(target, propName, {
        ...extension,
        ...original,
      });
    }
  }
}
