import IconButton from '@material-ui/core/IconButton';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(1) - 1, // Minus border width
    },
  }),
)(IconButton);
