import React from 'react';
import Slider, { SliderProps } from '@material-ui/core/Slider';
import { withStyles, createStyles } from '@material-ui/core/styles';
import ValueLabelComponent from './ValueLabelComponent';

export default withStyles(theme => {
  const thumb = {
    width: 16,
    height: 16,
    borderRadius: 16,
    marginTop: -4,
    marginLeft: -8,

    [theme.breakpoints.up('md')]: {
      width: 40,
      borderRadius: 32,
      marginLeft: -20,
    },
  };

  return createStyles({
    rail: {
      height: 8,
      borderRadius: 8,
      backgroundColor: theme.colors.gray[200],
      opacity: 1,
    },

    thumb,

    mark: {
      display: 'none',
    },

    markLabel: {
      color: theme.colors.gray[600],
    },

    disabled: {
      '& $thumb': thumb,
    },
  });
})((props: SliderProps) => <Slider ValueLabelComponent={ValueLabelComponent} track={false} {...props} />);
