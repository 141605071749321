const strings = {
    Selection: 'Selection',

    // dial in language
    DialInLanguage: 'Dial-in Language',
    English: 'English',
    Cantonese: 'Cantonese',
    Mandarin: 'Mandarin',

    // role
    Admin: 'Controller',
    Supervisor: 'Supervisor',
    SalesAgent: 'Sales Agent',

    // product type
    ProductType: 'Product Type',
    QuickCash: '(QC) “Quick Cash” Installment Program',
    EPP: '(EPP1) “FlexiBill” and “PayLite” Installment Program - “Monthly Flat Rate Plan”',
    EPP2: '(EPP2) “FlexiBill” and “PayLite” Installment Program - “One-Time Booking Fee Plan”',
    PersonalLoan: '(PIL) Personal Loan',
    BalACheck: '(BAC) Cash Conversion “(Bal-a-Check)” Program',

    // Login
    LoginTitle: 'Login',
    ChangePasswordNotice:
        'All TTS users are required to reset their passwords upon their first login on and after 8:00am August 27, 2022. Please click on "Forget Password" to reset your password.',
    LoginFailed: 'Login Failed.',
    UserID: 'User ID',
    UserIDPlaceHolder: 'User ID',
    PasswordPlaceHolder: 'Password',
    Password: 'Password',
    NewPassword: 'New Password',
    ConfirmPassword: 'Confirm Password',
    ForgetPassword: 'Forget Password',
    ResetPassword: 'Reset Password',
    PasswordNotMatch: `The password doesn't match`,
    InvalidPassword:
        'Password format is invalid\nIt must contain at least one uppercase and lowercase letter, one number digit and at least 16 characters.',
    LoginButton: 'Login',
    ForgetPasswordDesc:
        'Enter your User ID below, and we will send you a verification code to your email for allowing you to reset it.',
    ResetPasswordDesc:
        'New password must contain at least one uppercase and lowercase letter, one number digit and at least 16 characters.',
    VerificationCodeDesc: `Enter the verification code that you've received from your email`,
    Email: 'Email',
    Continue: 'Continue',
    BackToLogin: 'Back to login',
    BackToRefillEmail: 'Back to refill the user ID',
    BackToRefillCode: 'Back to refill the code',
    CodePlaceholder: '123abc',

    // sidebar
    TextToSpeech: 'Text To Speech',

    // Quick Cash, EPP, EPP2
    LoanAmount: 'Loan Amount',
    MonthlyFlatRate: 'Monthly Flat Rate',
    OneTimeBookingFee: 'One-Time Booking Fee',
    Tenor: 'Tenor',
    LoanDate: 'Loan Date',
    CycleDate: 'Cycle Date',

    FirstInstallmentDate: 'First Installment Date',
    APR: 'APR',
    InitialFee: 'Initial Fee',
    TotalMonthlyFee: 'Total Monthly Fee (excluding Initial Fee)',
    MonthlyInstallment: 'Monthly Repayment Amount',
    TotalInstallmentAmount: 'Total Installment Amount (Initial Fee + Interest + Principal)',
    TotalInstallmentAmountEPP2: 'Total Installment Amount (Principal + One-Time Booking Fee)',
    FirstInstallmentPrincipalAmount: "First Installment's Principal Amt",
    LastInstallmentPrincipalAmount: "Last Installment's Principal Amt",
    FirstInstallmentMonthlyFee: "First Installment's Monthly Fee",
    LastInstallmentMonthlyFee: "Last Installment's Monthly Fee",

    // PIL
    TotalLoanAmount: 'Total Loan Amount',
    DisbursementAmount: 'Disbursement Amount',
    OutstandingLoanAmount: 'Existing Outstanding Loan Amt',
    PILMonthlyFlatRate: 'Monthly Flat Rate',
    PILFirstInstallmentMonthlyFee: "1st Installment's Interest",
    PILLastInstallmentMonthlyFee: "Last Installment's Interest",
    ExtensionFee: 'Extension Fee',
    AccountNumber: 'Repayment Account',
    TotalInterest: 'Total Interest',
    TotalRepaymentAmount: 'Loan Amount',

    // BAC (Bal-a-Check)
    BACLoanAmount: 'Loan Amount / Total Cash Amount',
    AnnualRate: 'Annual Rate (p.a.)',
    BACAPR: 'APR',
    BACTenor: 'Tenor',
    AssumingApprovalDate: 'Assuming the Program Approval Date',
    AvailableCreditLimit: 'Available Credit Limit',
    EndDateLowRatePeriod: 'End Date of Low Rate Period',
    StartDateNormalRatePeriod: 'Start Date of Normal Rate Period',

    // Create New User
    UserFormTitle: 'Profile Details',
    FirstName: 'First Name',
    LastName: 'Last Name',
    Username: 'Username',
    UserType: 'User Type',
    Group: 'Group (Dept)',
    Team: 'Team',
    Status: 'User Status',
    StatusActive: 'Active',
    StatusInactive: 'Inactive',

    // Error Messages
    Required: '* Required',
    AnyNumberLargerThanZero: '* Any number larger than 0',
    AnyNumberLargerThanOrEqualsZero: '* Any number larger than or equals zero',
    AccountNumberRequired: '* Please enter Account Number',
    InvalidCycleDayOfMonth: '* Please enter a valid calendar day between 1-31',
    InvalidLoanAmountRangePIL: '* Please enter a valid amount between $8,000 and $5,000,000',
    InvalidLoanAmountRangeEPP: '* Please enter a valid amount between $200 - $3,000,000',
    InvalidLoanAmountRangeEPP2: '* Please enter a valid amount between $200 - $3,000,000',
    InvalidLoanAmountRangeQC: '* Please enter a valid amount between $3,000 - $3,000,000',
    InvalidAvailableCreditLimitRangeBAC: '* Please enter a valid amount between $0 - $3,000,000',
    NegativeDisbursementAmount:
        '* Must be greater than (or equal to) the sum of Existing Outstanding Loan Amount and Extension Fee.',
    InvalidAccountNumber: '* Please enter a valid Account Number',
    invalidMonthlyFlatRateQC: '* Please enter a valid percentage between 0.1% - 1.5%',
    invalidMonthlyFlatRateEPP: '* Please enter a valid percentage between 0.1% - 1.5%',
    invalidOneTimeBookingFee: '* Please enter a valid percentage between 0% - 16%',
    InvalidEmail: '* Invalid email address',
    invalidMonthlyFlatRateBAC: '* Please enter a valid percentage between 1% - 40%',

    DownloadAuditLogErrorTitle: 'Request Timeout',
    DownloadAuditLogErrorMessage: 'Your request for downloading the Audit Log has timed out. Please try again later.',
    DownloadAuditLogOKButton: 'OK',

    UpdateUserDialogErrorTitle: 'Update the user failed',
    UpdateUserDialogErrorMessage: 'Please retry again or contact Privé Technologies support team',
    UpdateUserDialogErrorButton: 'OK',
    // Others
    CreateTTS: 'Create TTS',
    CreateNew: 'Create New',
    NewUser: 'New User',
    Back: 'Back',
    Next: 'Next',
    Calculation: 'Calculation',
    LastStep: 'Last Step',
    DialInTheNumberBelow: 'Dial-in the Numbers Below to Listen to TTS',
    DialIn: 'Dial In',
    UniqueKey: 'Unique Key',
    AccountMaintenance: 'Account Maintenance',
    DownloadAuditLog: 'Download Audit Log',
    DynamicTTS: 'Dynamic TTS',

    OkDatePicker: 'OK',
    CancelDatePicker: 'Cancel',
    Create: 'Create',
    Save: 'Save',
    Edit: 'Edit',
    Enabled: 'Enabled',
    Disabled: 'Disabled',

    PASSWORD_RESET_OK:
        'Password has been reset. Please go back to login page and use your new password to login again.',
    // Reset password error message
    PASSWORD_RESET_NO_EMAIL_FOUND:
        'No user ID or email registered\n\nThere is no registered user ID or email found in the system, please contact your bank Admin or the Prive Support (support@privetechnologies.com).',
    PASSWORD_RESET_VERIFICATION_CODE_ERROR:
        'Verification Error\n\nPlease check your email and input the correct verification code, or please try again to send a new verification code.',
    NO_PRODUCT_AVAILABLE:
        'No product available, please contact your bank Admin or the Prive Support (support@privetechnologies.com).'
}

const en = strings
export default en
