import React, { useState } from 'react';
import { SvgIconComponent } from '@material-ui/icons';
import * as Menus from 'Menu';

const { Menu, MenuItem, ListItemText, ListItemIcon } = Menus;

type RenderProp = (options: { setAnchorEl: (target: Nullable<HTMLElement>) => void }) => React.ReactNode;

type TMenuItem = {
  key: string;
  title: string;
  handler: () => void;
  icon?: React.ReactElement<SvgIconComponent>;
};

type Props = {
  children: RenderProp;
  menuItems: TMenuItem[];
};

const SettingMenu = (props: Props) => {
  const { children, menuItems } = props;
  const [anchorEl, setAnchorEl] = useState(null as Nullable<HTMLElement>);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const makeHandler = (handler: () => void) => () => {
    handler();
    handleClose();
  };

  return (
    <>
      {children({ setAnchorEl })}

      <Menu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map(menuItem => (
          <MenuItem key={menuItem.key} onClick={makeHandler(menuItem.handler)}>
            {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
            <ListItemText>{menuItem.title}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SettingMenu;
