import { AccordionSummary } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      color: theme.colors.greenGray[700],
      padding: theme.spacing(0, 2),
      backgroundColor: theme.colors.white,
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: theme.typography.fontWeightBold,
      borderBottom: `1px solid ${theme.colors.gray[400]}`,
      minHeight: 'auto',

      '&$expanded': {
        minHeight: 'auto',
      },
    },

    content: {
      margin: theme.spacing(1, 0),
      fontFamily: theme.typography.fontFamily,

      '&$expanded': {
        margin: theme.spacing(1, 0),
      },
    },

    expandIcon: {
      color: theme.colors.greenGray[700],
      padding: theme.spacing(1),
    },

    /**
     * In order to make those `$ruleName` references work.
     * [reference](https://material-ui.com/customization/components/#use-rulename-to-reference-a-local-rule-within-the-same-style-sheet)
     */
    expanded: {},
  }),
)(AccordionSummary);
