import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import CustomTextField from './CustomTextField';

type Props = Omit<KeyboardDatePickerProps, 'TextFieldComponent'>;

const useStyles = makeStyles(theme =>
  createStyles({
    icon: {
      '&&&': {
        padding: 0,
        color: theme.colors.greenGray[700],
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
);

const CustomKeyboardDatePicker = (props: Props) => {
  const classes = useStyles();
  return (
    <KeyboardDatePicker
      TextFieldComponent={CustomTextField}
      KeyboardButtonProps={{
        className: classes.icon,
      }}
      {...props}
    />
  );
};

CustomKeyboardDatePicker.defaultProps = {
  format: 'yyyy-MM-dd',
};

export default CustomKeyboardDatePicker;
