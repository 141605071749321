/* eslint-disable no-undef */
import React from 'react'
import moment from 'moment'
import axios from 'axios'
import { values as userRole } from '../../model/enum/Role'
import localize from '../../utils/localize'
import endpoints from '../../configs/endpoints'
import { AuthContext } from '../../auth/AuthContextProvider'
import ErrorDialog from '../common/ErrorDialog'

const getAuditLogRequest = (authState, fromDate, toDate) => {
    const { userInfo } = authState.authInfo
    const request = { userInfo }

    if (!authState.enableTeamStructure) delete userInfo.monolithUserKey

    if (fromDate) request.fromDate = fromDate

    if (toDate) request.toDate = toDate

    return request
}

const DownloadAudit = ({ role }) => {
    const { authState } = React.useContext(AuthContext)

    const [downloadingAudit, setDownloadingAudit] = React.useState(false)

    const [error, setError] = React.useState(false)

    const handleCloseDialog = () => {
        setError(false)
    }

    /** handle download audit */
    const handleDownloadAudits = () => {
        if (downloadingAudit.downloading) {
            console.log('another download is going on.. skip this one')
            return
        }
        setDownloadingAudit(true)
        const fromDate = moment(new Date())
            .subtract(endpoints.auditLogsDownloadDurationInMonth, 'months')
            .format('YYYY-MM-DD')
        const toDate = moment(new Date()).format('YYYY-MM-DD')

        const URL = window._env_.isWebpack ? `/loans/v1/audits` : endpoints.baseTTSServiceUrl + endpoints.auditLogsUrl

        axios
            .post(URL, getAuditLogRequest(authState, fromDate, toDate), {
                timeout: 300000,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json'
                    // 'Accept': 'appplication/octet-stream' // not used in this endpoint
                }
            })
            .then(response => {
                if (response.status !== 200) {
                    setDownloadingAudit(false)
                    setError(true)
                    return
                }
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'auditlogs.xlsx')
                document.body.appendChild(link)
                link.click()
                setDownloadingAudit(false)
            })
            .catch(err => {
                setError(true)
                if (err.code === 'ECONNABORTED') console.log('connection timeout')
                setDownloadingAudit(false)
            })
    }

    return [userRole.ADMIN, userRole.SUPERVISOR].includes(role) ? (
        <div className="row">
            <button
                type="button"
                onClick={handleDownloadAudits}
                disabled={downloadingAudit}
                className="btn btn-default btn-block btn-large"
            >
                {localize.strings.DownloadAuditLog}
            </button>
            <ErrorDialog
                title={localize.strings.DownloadAuditLogErrorTitle}
                message={localize.strings.DownloadAuditLogErrorMessage}
                buttonLabel={localize.strings.DownloadAuditLogOKButton}
                isOpen={error}
                handleClose={handleCloseDialog}
            />
        </div>
    ) : (
        <></>
    )
}

export default DownloadAudit
