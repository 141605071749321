import LocalizedStrings from 'react-localization';
import en from '../resources/localization/en';
import zh from '../resources/localization/zh';

const supportedLanguages = ['en', 'zh'];

const tenantStrings = {
    default: {
        en: en,
        zh: zh
    },
    // Customization strings of each tenant goes to here
};

const init = (tenant, languageCode) => {
    const locale = languageCode || 'en';

    if (tenant) {
        const overrideStrings = tenantStrings[tenant];
        if (overrideStrings) {
            const allStrings = supportedLanguages.reduce((aggStrings, lang) => {
                aggStrings[lang] = { ...tenantStrings['default'][lang], ...overrideStrings[lang] };
                return aggStrings;
            }, {});
            strings.setContent(allStrings);
        }
    }
    strings.setLanguage(locale);
};

const strings = new LocalizedStrings(
    supportedLanguages.reduce((aggStrings, lang) => {
        aggStrings[lang] = { ...tenantStrings['default'][lang] };
        return aggStrings;
    }, {}),
);

const getLanguage = () => {
    return strings.getLanguage();
};

const localize = {
    init,
    getLanguage,
    strings,
};

export default localize;
