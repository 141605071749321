export const noop: (...args: any[]) => void = () => {};

export const hasClass = (classNames: string, classNameToCheck: string): boolean => {
  if (!classNames || !classNameToCheck) {
    return false;
  }

  return new RegExp('(\\s|^)' + classNameToCheck + '(\\s|$)').test(classNames);
};

declare global {
  interface Window {
    priveApp: PriveApp;
  }
  interface PriveApp {
    /**
     * The base URL of this JAVASCRIPT deployment.
     *
     * @see prive-ui/src/public-path.js
     */
    baseurl: string;
    /**
     * The URL monolith.
     */
    monolithUrl?: string;
    /**
     * The base URL of api endpoints.
     * Default null | "." .relative to browser current URL.
     *
     * Note: depends on usecase, it might not be monolith.
     */
    apiBase?: string;
    /**
     * Map to published endpoint URL for widgets to auto-detect.
     * should be relative URLs,
     */
    endpoints: { [key: string]: string };
    /**
     * Access module toggle for current user, when loading in monolith.
     */
    hasModuleToggle(moduleToggle: string): boolean;
    /**
     * Not availiable in monolith yet.
     *
     * A JWT token to call APIs when they are not using cookie for authorization on same browser domain, need for CORS.
     */
    token?: string;
  }
}

export function getPriveApp(): PriveApp | undefined {
  if (typeof window.priveApp === 'object') {
    return window.priveApp;
  }
}

export function getDefaultApiEndpoint(name: string, fallback: string): string {
  const priveApp = getPriveApp();
  const endpoints = priveApp?.endpoints;
  let endpoint = endpoints && name in endpoints ? endpoints[name] : fallback;
  if (priveApp?.apiBase) {
    return new URL(endpoint, priveApp?.apiBase).toString();
  }
  return endpoint;
}
