import React from 'react';

/**
 * A helper to create a Context and Provider with no upfront default value,
 * and without having to check for undefined all the time.
 */
export function createContext<ContextType>() {
  const ctx = React.createContext<ContextType | undefined>(undefined);
  function useContext() {
    const context = React.useContext(ctx);
    if (!context) throw new Error('useContext must be inside a Provider with a value');
    return context;
  }
  return [useContext, ctx.Provider] as const;
}
