/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { KeyboardDatePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem'
import { ErrorMessage } from '@hookform/error-message'
import NumberFormat from 'react-number-format'
import classnames from 'classnames'
import OptionsHOC from '../common/OptionsHOC'
import localize from '../../utils/localize'

const COLUMN_KEYS = ['loanAmount', 'monthlyFlatRate', 'tenor', 'loanDate', 'cycleDayOfMonth']

const FIELDS = [
    [
        {
            label: () => localize.strings.LoanAmount,
            defaultValue: 0,
            key: 'loanAmount',
            rules: getValues => ({
                required: localize.strings.Required,
                validate: value => (value <= 3000000 && value >= 200) || localize.strings.InvalidLoanAmountRangeEPP
            }),
            render: errors => ({ onChange, value }) => (
                <NumberFormat
                    value={typeof value === 'number' ? value : 0}
                    thousandSeparator
                    prefix="$"
                    allowNegative={false}
                    maxLength={20}
                    decimalScale={2}
                    fixedDecimalScale
                    autoComplete="off"
                    onFocus={event => setTimeout(() => event.target.select(), 0)}
                    onValueChange={_value => onChange(_value.floatValue || _value)}
                />
            )
        },
        {
            label: () => localize.strings.MonthlyFlatRate,
            defaultValue: 0,
            key: 'monthlyFlatRate',
            rules: getValues => ({
                required: localize.strings.Required,
                validate: value =>
                    (value.floatValue <= 1.5 && value.floatValue >= 0.1) || localize.strings.invalidMonthlyFlatRateQC
            }),
            render: errors => ({ onChange, value }) => {
                return (
                    <NumberFormat
                        value={value?.floatValue || 0}
                        suffix="%"
                        decimalScale={2}
                        maxLength={7}
                        fixedDecimalScale
                        autoComplete="off"
                        allowLeadingZeros={false}
                        onFocus={event => setTimeout(() => event.target.select(), 0)}
                        onValueChange={onChange}
                    />
                )
            }
        }
    ],
    [
        {
            type: 'selection',
            label: () => localize.strings.Tenor,
            defaultValue: '',
            key: 'tenor',
            rules: getValues => ({
                required: localize.strings.Required
            }),
            render: errors => ({ onChange, value }) => (
                <FormControl className="default-input">
                    <Select
                        displayEmpty
                        defaultValue={value}
                        className={classnames('input-box', { error: errors.tenor })}
                        labelId="customized-select-label"
                        id="customized-select"
                        disableUnderline
                        style={{
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            fontFamily:
                                '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif',
                            color: '#3D505D',
                            fontSize: '16px'
                        }}
                        onChange={onChange}
                    >
                        {Array(10)
                            .fill(1)
                            .map((i, index) => (
                                <MenuItem key={`tenor-${index * 6 + 6}`} value={`${index * 6 + 6}`}>
                                    {index * 6 + 6}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            )
        },
        {
            type: 'date',
            label: () => localize.strings.LoanDate,
            defaultValue: '',
            key: 'loanDate',
            rules: getValues => ({
                required: localize.strings.Required
            }),
            render: errors => _props => (
                <KeyboardDatePicker
                    className={classnames('input-box', { error: errors.loanDate })}
                    style={{ width: '368px' }}
                    id="date-picker-dialog"
                    format="yyyy-MM-dd"
                    okLabel={localize.strings.OkDatePicker}
                    cancelLabel={localize.strings.CancelDatePicker}
                    TextFieldComponent={_dateProps => <TextField {..._dateProps} disabled />}
                    error={false}
                    disablePast
                    helperText={null}
                    InputProps={{
                        disableUnderline: true
                    }}
                    {..._props}
                    onChange={_value => _props.onChange(moment(_value).format('yyyy-MM-DD'))}
                />
            )
        }
    ],
    [
        {
            label: () => localize.strings.CycleDate,
            defaultValue: 0,
            key: 'cycleDayOfMonth',
            rules: getValues => ({
                required: localize.strings.Required,
                validate: value => {
                    const intValue = parseInt(value.value, 10)
                    if (intValue !== value.floatValue) {
                        return localize.strings.InvalidCycleDayOfMonth
                    }
                    return (value.floatValue <= 31 && value.floatValue >= 1) || localize.strings.InvalidCycleDayOfMonth
                }
            }),
            render: () => ({ onChange, value }) => (
                <NumberFormat
                    value={value?.floatValue || ''}
                    decimalScale={0}
                    maxLength={2}
                    allowLeadingZeros={false}
                    onFocus={event => setTimeout(() => event.target.select(), 0)}
                    onValueChange={onChange}
                />
            )
        }
    ]
]

export const savedFields = [
    {
        key: 'loanAmount',
        type: 'currency',
        label: () => localize.strings.LoanAmount,
        format: '$0,0.00'
    },
    {
        key: 'monthlyFlatRate',
        type: 'percent',
        label: () => localize.strings.MonthlyFlatRate,
        format: '0.00%'
    },
    {
        key: 'tenor',
        label: () => localize.strings.Tenor
    },
    {
        key: 'loanDate',
        label: () => localize.strings.LoanDate,
        type: 'date',
        format: 'yyyy-MM-dd'
    },
    {
        key: 'cycleDateCalc',
        label: () => localize.strings.CycleDate,
        type: 'date',
        format: 'yyyy-MM-dd'
    }
]

export const calcFields = [
    {
        key: 'apr',
        label: () => localize.strings.APR,
        format: '0.00%'
    },
    {
        key: 'initialFee',
        label: () => localize.strings.InitialFee,
        format: '$0,0.00'
    },
    {
        key: 'totalInterestPayable',
        label: () => localize.strings.TotalMonthlyFee,
        format: '$0,0.00'
    },
    {
        key: 'monthlyInstallment',
        label: () => localize.strings.MonthlyInstallment,
        format: '$0,0.00'
    },
    {
        key: 'totalRepaymentAmount',
        label: () => localize.strings.TotalInstallmentAmount,
        format: '$0,0.00'
    },
    {
        key: 'firstInstPrincipleAmount',
        label: () => localize.strings.FirstInstallmentPrincipalAmount,
        format: '$0,0.00'
    },
    {
        key: 'firstInstMonthlyFee',
        label: () => localize.strings.FirstInstallmentMonthlyFee,
        format: '$0,0.00'
    },
    {
        key: 'lastInstPrincipleAmount',
        label: () => localize.strings.LastInstallmentPrincipalAmount,
        format: '$0,0.00'
    },
    {
        key: 'lastInstMonthlyFee',
        label: () => localize.strings.LastInstallmentMonthlyFee,
        format: '$0,0.00'
    },
    {
        key: 'firstInstallmentDate',
        label: () => localize.strings.FirstInstallmentDate
    }
]

const EPPForm = ({ onSubmit }) => {
    const { errors, control, getValues, trigger, watch } = useFormContext()
    const { loanAmount, monthlyFlatRate, tenor, loanDate, cycleDayOfMonth } = watch(COLUMN_KEYS)

    const onSubmitMemo = useCallback(onSubmit, [])

    useEffect(() => {
        if (loanAmount && monthlyFlatRate && tenor && loanDate && cycleDayOfMonth) onSubmitMemo()
    }, [loanAmount, monthlyFlatRate, tenor, loanDate, cycleDayOfMonth])

    useEffect(() => {
        if (loanDate && cycleDayOfMonth && loanDate !== '' && cycleDayOfMonth !== '')
            trigger(['loanDate', 'cycleDayOfMonth'])
    }, [loanDate, cycleDayOfMonth])

    return (
        <>
            <h5>{localize.strings.EPP}</h5>
            {FIELDS.map((row, index) => (
                <div className="row" key={`EPPForm-${index}`}>
                    {row.map(item => (
                        <div className="col s6" key={item.key}>
                            <label className="input-label">{item.label()}</label>
                            <div className="row">
                                <OptionsHOC type={item.type} isError={errors[item.key]}>
                                    <Controller
                                        defaultValue={item.defaultValue}
                                        name={item.key}
                                        control={control}
                                        render={item.render(errors, getValues)}
                                        rules={item.rules(getValues, trigger)}
                                    />
                                </OptionsHOC>
                                <div style={{ wordWrap: 'break-word', color: 'red', width: '368px' }}>
                                    <label className="input-label error" style={{ textAlign: 'left', color: 'red' }}>
                                        <ErrorMessage
                                            errors={errors}
                                            name={item.key}
                                            render={({ message }) => (
                                                <div dangerouslySetInnerHTML={{ __html: message }} />
                                            )}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </>
    )
}

export default EPPForm
