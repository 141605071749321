import { mergeDeepRight } from 'ramda';

import { InputBase } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';

// Used for correcting the styles that got overridden by monolith
const stylesReset = (placeholder: CSSProperties) => ({
  input: {
    '&&&': {
      background: 'none',
      border: '0 !important',
      height: '1.1875em !important',
      margin: '0 !important',
      transition: 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
      '&::-webkit-input-placeholder': placeholder,
      '&::-moz-placeholder': placeholder, // Firefox 19+
      '&:-ms-input-placeholder': placeholder, // IE 11
      '&::-ms-input-placeholder': placeholder, // Edge
    },
    '&:focus': {
      boxShadow: 'none !important',
    },
  },
});

export default withStyles(theme => {
  const placeholder = {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  };

  return createStyles(
    mergeDeepRight(stylesReset(placeholder), {
      root: {
        '&&&': {
          color: theme.colors.greenGray[700],
          borderRadius: theme.spacing(1),
          backgroundColor: theme.colors.gray[100],
        },
      },

      input: {
        '&&&': {
          paddingTop: `${theme.spacing(1)}px !important`,
          paddingBottom: `${theme.spacing(1)}px !important`,
          paddingLeft: `${theme.spacing(2)}px !important`,
          // NOTE: since this should be able to override when we use <InputBase /> in <Select />
          paddingRight: theme.spacing(2),
        },
      },

      error: {
        '&&&': {
          boxShadow: `inset 0 0 0 1px ${theme.colors.negative[600]}`,
        },
      },

      focused: {
        '&&&': {
          boxShadow: `inset 0 0 0 1px ${theme.colors.technologyBlue[600]}`,
        },
      },

      adornedStart: {
        '&&&': {
          paddingLeft: theme.spacing(1),
        },
      },

      adornedEnd: {
        '&&&': {
          paddingRight: theme.spacing(1),
        },
      },

      formControl: {
        '&&&': {
          'label + &': {
            marginTop: theme.spacing(3),
          },
        },
      },

      inputMultiline: {
        '&&&': {
          height: 'auto !important',
        },
      },
    }),
  );
})(InputBase);
