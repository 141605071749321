import React, { useCallback, useReducer, FC } from 'react';

import { createContext } from '../utils/ReactUtils';
import WidgetContainer, { Mode, WidgetContainerProps } from './WidgetContainer';

export interface WidgetState {
  scrollTo: (positionY: number) => void;
  mode: Mode;
  getScrollContainer: () => Element | undefined;
}

export interface WidgetProps extends WidgetState {
  setMode: (mode: Mode) => void;
}

function isNewStyle() {
  return document.documentElement.classList.contains('newStyle');
}

function scrollToInFitHeightMode(positionY: number) {
  console.debug('Scroll to is not supported in `fit-height` mode.');
}
function scrollToInNormalMode(positionY: number) {
  const scrollContainer = getScrollContainer();
  if (scrollContainer) {
    scrollContainer.scrollTo(0, positionY);
  }
}
function getScrollContainer() {
  const mainPanel = document.getElementById('mainPanel');
  if (mainPanel) {
    const wrappers = mainPanel.getElementsByClassName('wrapper');
    if (wrappers.length === 1) {
      return wrappers[0];
    } else if (wrappers.length > 1) {
      return wrappers[1];
    }
  }
}

enum Action {
  SetMode,
}

type ACTIONTYPE = { type: Action.SetMode; payload: Mode };

function reducer(state: WidgetState, action: ACTIONTYPE) {
  switch (action.type) {
    case Action.SetMode:
      let mode = action.payload;
      if (mode === Mode.FitHeight) {
        if (!isNewStyle()) {
          console.debug('`fit-height` is supported with `newStyle` only. Fallback to `normal` ', mode);
          mode = Mode.Normal;
        }
      }

      return {
        ...state,
        mode,
        scrollTo: mode === Mode.Normal ? scrollToInNormalMode : scrollToInFitHeightMode,
      };
    default:
      throw new Error('Action not supported: ' + JSON.stringify(action));
  }
}

const [useWidgetContext, WidgetContextProvider] = createContext<WidgetProps>();

export { useWidgetContext };

export const Widget: FC<WidgetContainerProps> = ({ children, mode = Mode.Normal, ...otherProps }) => {
  const [state, dispatch] = useReducer(reducer, {
    scrollTo: mode === Mode.Normal ? scrollToInNormalMode : scrollToInFitHeightMode,
    mode,
    getScrollContainer,
  });

  const setMode = useCallback(
    (mode: Mode) => {
      dispatch({ type: Action.SetMode, payload: mode });
    },
    [dispatch],
  );

  return (
    <WidgetContextProvider
      value={{
        ...state,
        setMode,
      }}
    >
      <WidgetContainer {...otherProps} mode={state.mode}>
        {children}
      </WidgetContainer>
    </WidgetContextProvider>
  );
};
