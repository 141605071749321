import React, { useCallback, useEffect, useState } from 'react';
import Empty from '../Empty';
import ErrorRounded from '@material-ui/icons/ErrorRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Translation } from 'react-i18next';
import { i18n } from '../i18n';
// types
import type ServerSuccessResult from './WizardTypes/ServerSuccessResult';
import type WizardJounaryConfiguration from './WizardTypes/WizardJounaryConfiguration';
import type SharedWidgetContext from './WizardTypes/SharedWidgetContext';
import type StoredSession from './WizardTypes/StoredSession';
import State from './WizardTypes/State';
import type ProgressBarProps from './WizardTypes/ProgressBarProps';
// impl
import useStableCallback from './useStableCallback';
import InnerWizard from './InnerWizard';
import { apiLoad, apiInit, apiSave, apiCheckPoint, ApiErrors } from './api';
import { getDefaultApiEndpoint } from 'utils/function';
import { ButtonsLabel } from './WizardTypes/ButtonsLabel';
import VersionErrorAlert from './VersionErrorAlert';

interface WizardProps /*docz has bug when extends FlowHandlers*/ {
  /**
   * URL to the server side API endpoint for wizard.
   * @default auto-detect
   */
  api?: string;
  /**
   * Session id to resume
   */
  sessionId?: string;
  /**
   * journeyTemplateId.  Check `wizards` microservice for valid ID
   */
  journeyTemplateId: string;
  /**
   * Configuration of the widgets to override server side config
   * @default load from server for `journeyTemplateId`
   */
  config?: WizardJounaryConfiguration | ((config: WizardJounaryConfiguration) => WizardJounaryConfiguration);
  /**
   * Optional title.
   */
  fixedTitle?: string;
  /**
   *Initial sharedWidgetContext, if a new session is created.
   @default {}
   */
  initialSharedWidgetContextForNewSession?: SharedWidgetContext;
  /**
   * change button default labels
   *
   * Priority: override by current widget > buttonsLabel > default label
   */
  buttonsLabel?: ButtonsLabel;
  /**
   * A regex to control should the progress indicator show or hide for selected step.
   *
   * Note: it is "show/hide the bar from a step"
   * Note: it is not "show a step from the bar"
   *
   * @default .*
   */
  progressBarVisibleCondition?: RegExp;
  /**
   * A component to override default usage of ProgressIndicator in progressBar
   */
  progressBarComponent?: React.FunctionComponent<ProgressBarProps>;
  /**
   * A call back to fire when it start a new session.
   *
   * @param sessionId unique if persistent, otherwise can be duplicated executions.
   */
  onNewSession?: (sessionId: string) => void;
  /**
   * A call back to fire when resume session
   */
  onResumeSession?: (sessionId: string, currentStep: number) => void;
  /**
   * Cannot resume session. or cannot save assion
   */
  onError: (sessionId: string, currentStep: number, failureType: string, e: Error) => void;
  /**
   * on after stepped (back or front) except finish
   */
  onStepChange?: (sessionId: string, currentStep: number, currentPath: string, navigation: string[][]) => void;
  /**
   * on after cancelled.
   */
  onCancel: (sessionId: string, currentStep: number) => void;
  /**
   * On everything done. After checkpoint on server side.
   *
   * There are three terminal state/point of FlexWizard. Caller is mandatory to listen them call.
   * - onError
   * - onCancel
   * - onFinish
   * - (browser closed)
   */
  onFinish: (sessionId: string, sharedWidgetContext: SharedWidgetContext) => void;
  /**
   * Use to route to corrsponding step by exact match of `path` in `enabled visited steps`.
   *
   * i.e. steps.find((conf,stepIndex)=>stepIndex<=session.farthestStep && conf.path === jumpTo && isEnabledStep(conf)).
   *
   * It take a ONE-ELEMENT-ARRAY, the array is used to trigger the StepWizard to reroute.
   * The parent component should useState to keep the array stable avoid unwanted redraw/page lock.
   * and provide a new array for route request.
   *
   */
  jumpTo?: [string];
}

const temporySessionStore = new Map<string, StoredSession>();

export default function StepWizard(prop: WizardProps) {
  const {
    api = getDefaultApiEndpoint('flexWizard', './restful/widgets-proxy/1/flexWizard'),
    fixedTitle,
    journeyTemplateId = '',
    sessionId: resumeSessionId,
    initialSharedWidgetContextForNewSession,
    config: clientConfig,
    buttonsLabel,
    progressBarVisibleCondition,
    progressBarComponent,
    jumpTo,
  } = prop;
  /***************
   * States
   **************/
  const onNewSession = useStableCallback(prop.onNewSession);
  const onResumeSession = useStableCallback(prop.onResumeSession);
  const onCancel = useStableCallback(prop.onCancel);
  const onError = useStableCallback(prop.onError);
  const onStepChange = useStableCallback(prop.onStepChange);
  const onFinish = useStableCallback(prop.onFinish);
  const [state, setState] = useState<State>(State.New);
  const [isCorrectVersion, setIsCorrectVersion] = useState(true);
  const [effectiveSession, setEffectiveSession] = useState<StoredSession>({
    sessionId: '',
    currentStep: 0,
    farthestStep: 0,
    privateWidgetContext: [],
    sharedWidgetContext: initialSharedWidgetContextForNewSession || {},
    version: 0,
  });
  const [journeyConfig, setJourneyConfig] = useState<Nullable<WizardJounaryConfiguration>>(
    typeof clientConfig === 'object' ? clientConfig : null,
  );
  const mode = journeyConfig?.mode ?? 'persistent';
  /***************
   * Functions
   **************/
  const fireNewSession = useCallback(
    (res: StoredSession) => {
      onNewSession(res.sessionId);
    },
    [onNewSession],
  );
  const fireResumeSession = useCallback(
    (res: StoredSession) => {
      onResumeSession(res.sessionId, res.currentStep);
      return res;
    },
    [onResumeSession],
  );
  const resetEffectiveSession = useCallback(
    (sessionId: string): StoredSession => {
      const session = {
        sessionId,
        currentStep: 0,
        farthestStep: 0,
        privateWidgetContext: [],
        sharedWidgetContext: initialSharedWidgetContextForNewSession || {},
        version: 0,
      };
      setEffectiveSession(session);
      return session;
    },
    [initialSharedWidgetContextForNewSession],
  );
  const newEmptySession = useCallback(
    (storeInTempSessionStore): StoredSession => {
      const newSessionId = new Date().getTime().toString(36) + '$' + Number(Math.random()).toString(36);
      const session = resetEffectiveSession(newSessionId);
      setState(State.Loaded);
      if (storeInTempSessionStore) {
        temporySessionStore.set(newSessionId, session);
      }
      return session;
    },
    [resetEffectiveSession],
  );
  const resumeSessionFrom = useCallback(async (lastContext: StoredSession) => {
    setEffectiveSession(lastContext);
    setState(State.Loaded);
    return lastContext;
  }, []);
  const resumeSessionFromServer = useCallback(
    async ({ data: lastContext, wizardConfig, sessionId }: ServerSuccessResult) => {
      if (!clientConfig) {
        setJourneyConfig(wizardConfig);
      } else if (typeof clientConfig === 'function') {
        setJourneyConfig(clientConfig(wizardConfig));
      }
      return resumeSessionFrom(lastContext || resetEffectiveSession(sessionId));
    },
    [clientConfig, resumeSessionFrom, resetEffectiveSession],
  );

  const reloadLatestVersion = useCallback(() => {
    if (!isCorrectVersion) {
      const abortController = new AbortController();
      apiLoad(abortController.signal, api, journeyTemplateId, effectiveSession.sessionId)
        .then(resumeSessionFromServer)
        .then(fireResumeSession)
        .then((r) => {
          setIsCorrectVersion(true);
          return r;
        })
        .catch((e) => {
          console.log(e);
          setState(State.Error);
        });
    }
  }, [
    isCorrectVersion,
    api,
    effectiveSession.sessionId,
    fireResumeSession,
    journeyTemplateId,
    resumeSessionFromServer,
  ]);
  /***************
   * Effects
   **************/
  /**
   * Bootstrap, this hook load the config and initialize/resume session.
   */
  useEffect(() => {
    setState(State.New);
    const abortController = new AbortController();
    switch (mode) {
      case 'persistent':
        if (typeof resumeSessionId === 'string') {
          apiLoad(abortController.signal, api, journeyTemplateId, resumeSessionId)
            .then(resumeSessionFromServer)
            .then(fireResumeSession)
            .catch((e) => {
              console.log(e);
              setState(State.Error);
            });
        } else {
          apiInit(abortController.signal, api, journeyTemplateId)
            .then(resumeSessionFromServer)
            .then(fireNewSession)
            .catch((e) => {
              console.log(e);
              setState(State.Error);
            });
        }
        break;
      case 'session':
        const lastContext = resumeSessionId ? temporySessionStore.get(resumeSessionId) : null;
        if (lastContext) {
          resumeSessionFrom(lastContext).then(fireResumeSession);
        } else {
          fireNewSession(newEmptySession(true));
        }
        break;
      case 'transient':
        fireNewSession(newEmptySession(false));
        break;
    }
    return abortController.abort.bind(abortController);
  }, [
    api,
    fireNewSession,
    fireResumeSession,
    journeyTemplateId,
    mode,
    newEmptySession,
    resumeSessionFrom,
    resumeSessionFromServer,
    resumeSessionId,
  ]);
  useEffect(() => {
    if (!journeyConfig) {
      if (mode === 'persistent' && !effectiveSession.sessionId) {
        return;
      }
      setState(State.Error);
    }
  }, [mode, journeyConfig, effectiveSession, effectiveSession.sessionId]);

  /***************
   * Callbacks
   **************/
  const runAutoSave = useCallback(
    async (session: StoredSession) => {
      switch (mode) {
        case 'transient':
          break;
        case 'session':
          temporySessionStore.set(session.sessionId, session);
          break;
        case 'persistent':
          setState(State.Flushing);
          try {
            const res = await apiSave(api, journeyTemplateId, session);
            return res.data;
          } catch (e) {            
            if(ApiErrors.VERSION_CONFLICT === e.message) {
              console.log("AutoSave failed with Version conflict. Please load the latest changes from server")
              setIsCorrectVersion(false)               
            } else {                            
              setState(State.Error);
              onError(session.sessionId, session.currentStep, 'AutoSaveFailed', e);
            }
          }
          break;      
      }
      return session;
    },
    [api, journeyTemplateId, mode, onError],
  );
  const runServerSideWidget = useCallback(
    async (session: StoredSession) => {
      setState(State.Flushing);
      try {
        const json = await apiCheckPoint(api, journeyTemplateId, session);
        return json.data;
      } catch (e) {
        if (ApiErrors.VERSION_CONFLICT === e.message) {
          console.log('Checkpoint failed with Version conflict. Please call Load()');
          setIsCorrectVersion(false);
        } else {
          setState(State.Error);
          onError(session.sessionId, session.currentStep, 'CheckpointFailed', e);
        }
        return session;
      }
    },
    [api, journeyTemplateId, onError],
  );
  /***************
   * Render
   **************/
  if (state === State.Error) {
    return (
      <Translation i18n={i18n}>
        {(t) => <Empty title={t('StepWizard:Error')} state="negative" icon={ErrorRounded} />}
      </Translation>
    );
  }
  if (!isCorrectVersion) {
    return (
      <Translation i18n={i18n}>
        {(t) => (
          <VersionErrorAlert reloadButtonLabel={t('StepWizard:VersionErrorReload')} onReload={reloadLatestVersion} />
        )}
      </Translation>
    );
  }
  if (state === State.New) {
    return <CircularProgress />;
  }
  if (!journeyConfig) {
    setState(State.Error);
    return <CircularProgress />;
  }
  return (
    <InnerWizard
      key={effectiveSession.sessionId}
      state={state}
      setState={setState}
      api={api}
      fixedTitle={fixedTitle}
      journeyTemplateId={journeyTemplateId}
      sessionId={effectiveSession.sessionId}
      branches={journeyConfig.branches || {}}
      stepsConfig={journeyConfig.steps}
      navigationMode={journeyConfig.nav}
      onError={onError}
      onStepChange={onStepChange}
      onCancel={onCancel}
      onFinish={onFinish}
      runAutoSave={runAutoSave}
      runServerSideWidget={runServerSideWidget}
      initialSession={effectiveSession}
      buttonsLabel={buttonsLabel}
      progressBarVisibleCondition={progressBarVisibleCondition}
      progressBarComponent={progressBarComponent}
      jumpTo={jumpTo}
    />
  );
}
