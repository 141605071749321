import React from "react";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import lang_en from "../../assets/img/nav/lang_en.svg";
import lang_zh_TW from "../../assets/img/nav/lang_zh_TW.svg";
import RoutePaths from "../../configs/RoutePaths";
import * as queryString from "query-string";
import { Route , withRouter} from 'react-router-dom';
import {AuthContext} from "../../auth/AuthContextProvider";
import {logout, setLocale} from "../../auth/authActions";


export const NavButtons = {
    "CHANGE_LOCALE" : "CHANGE_LOCALE",
    "LOGOUT" : "LOGOUT",
};

const Nav = (props) => {
    let {enabledButtons} = props;
    if (!enabledButtons) {
        enabledButtons = Object.values(NavButtons);
    }
    const [anchorEl, setAnchorEl] = React.useState(null);

    const { authState, dispatch } = React.useContext(AuthContext);


    /** handle logout */
    const handleLogout = () => {
        console.log("### logging out...");
        logout()(dispatch);
    }

    const handleClickLangMenu = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        // console.log("Clicked language")
    };

    const handleChangeLocale = (locale) => {
        setAnchorEl(null);
        console.log('change locale', locale);
        locale && setLocale({locale})(dispatch);
    };


    return (
        <nav>
            <div className="navbar-top-links right">
                <ul>
                    {/*<li><a href="#"><i className="material-icons">help_outline</i></a></li>*/}
                    {enabledButtons.includes(NavButtons.CHANGE_LOCALE) &&
                    <li>
                        <div>
                            <a href="#" onClick={handleClickLangMenu}><i className="material-icons">public</i></a>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                // keepMounted
                                open={Boolean(anchorEl)}
                                onClose={() => handleChangeLocale}
                            >
                                <MenuItem onClick={() => handleChangeLocale('en')}><img src={lang_en} width="130px" alt="lang_en"/></MenuItem>
                                <MenuItem onClick={() => handleChangeLocale('zh')}><img src={lang_zh_TW} width="130px" alt="lang_zh_TW"/></MenuItem>
                            </Menu>
                        </div>
                    </li>
                    }
                    {enabledButtons.includes(NavButtons.LOGOUT) &&
                        <li><a href="#" onClick={handleLogout}><i className="material-icons">exit_to_app</i></a></li>
                    }
                </ul>
            </div>
        </nav>
    )
}

export default withRouter(Nav);
