import * as WebComponent from 'types/WebComponent';

export function makeStringParser(defaultValue: string = ''): WebComponent.TAttributeParser<string> {
  return value => (value === null ? defaultValue : value);
}

export function makeIntegerParser(defaultValue: number = 0): WebComponent.TAttributeParser<number> {
  return value => (value === null ? defaultValue : parseInt(value));
}

export function makeNumberParser(defaultValue: number = 0): WebComponent.TAttributeParser<number> {
  return value => (value === null ? defaultValue : Number(value));
}

export const makeBooleanParser = (defaultValue: boolean = false): WebComponent.TAttributeParser<boolean> => (
  value: Nullable<string>,
) => {
  switch (value) {
    // <component />
    case null:
      return defaultValue;

    // <component attribute />
    case '':
      return true;

    // <component attribute="true" />
    case 'true':
      return true;

    // <component attribute="false" />
    case 'false':
      return false;

    // <component attribute="any other" />
    default:
      return defaultValue;
  }
};

export function makeJSONParser<T extends object>(): WebComponent.TAttributeParser<T>;
export function makeJSONParser<T extends object>(defaultValue: T): WebComponent.TAttributeParser<T>;
export function makeJSONParser<T extends object>(defaultValue?: T): WebComponent.TAttributeParser<T> {
  return value => {
    if (value === null) {
      if (defaultValue === undefined) {
        throw new Error(`Can't parse null`);
      }
      return { ...defaultValue };
    } else {
      return JSON.parse(value) as T;
    }
  };
}

export function makeEnumParser<T extends string>(enums: T[], defaultValue: T): WebComponent.TAttributeParser<T> {
  return value => {
    if (value === null) {
      return defaultValue;
    }

    return enums.includes(value as T) ? (value as T) : defaultValue;
  };
}
