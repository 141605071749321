import ListItemIcon from '@material-ui/core/ListItemIcon';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      minWidth: 'auto',
      marginRight: theme.spacing(1),
    },
  }),
)(ListItemIcon) as typeof ListItemIcon;
// Ref: https://github.com/mui-org/material-ui/issues/15695
