import { Dialog } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    paper: {
      borderRadius: theme.spacing(1),
    },
  }),
)(Dialog);
