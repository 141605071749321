import React, { useEffect, FC } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider, Theme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import '../../styles/scss/widget-container.module.scss';

export enum Mode {
  Normal = 'normal',
  FitHeight = 'fit-height',
}

const scrollbarCssClass = 'g-scrollbar';

// <## GLOBAL ###
// Mutiple instances is not supported, consider to throw exception
let instanceCount: number = 0;

const register = () => {
  instanceCount += 1;
  document.documentElement.classList.add(scrollbarCssClass);
  console.debug('Widget Registered', instanceCount);
  if (instanceCount > 1) {
    console.warn('Mutiple widget instance is nort supported.');
  }
};
const unregister = () => {
  instanceCount -= 1;
  if (instanceCount <= 0) {
    document.documentElement.classList.remove(scrollbarCssClass);
  }
  console.debug('Widget Unregistered', instanceCount);
};

const registerMode = (mode: Mode) => {
  document.documentElement.classList.add(`${scrollbarCssClass}-${mode}`);
  console.debug('Add mode', mode);
};
const unregisterMode = (mode: Mode) => {
  document.documentElement.classList.remove(`${scrollbarCssClass}-${mode}`);
  console.debug('Remove mode', mode);
};
// ### GLOBAL ##>

export interface WidgetContainerProps extends HTMLDivElement {
  /**
   * A theme object to extend the outer theme.
   */
  theme: Theme;
  /**
   * Suppress widget theme.
   */
  suppressWidgetTheme?: boolean;
  /**
   * Switch the widget mode. Default: normal.
   *
   * `normal`: Freeze g-header, support scrolling outside the widget.
   * `fit-height`: Freeze widget height to fit display screen, support scrolling inside the widget.
   */
  mode: Mode;
}

const WidgetThemeExtender = (theme: Theme) => {
  if (!theme) return theme;

  const typography = {
    h3: {
      fontSize: 26,
      lineHeight: '40px',
      margin: 0,
      padding: 0,
    },
    h4: {
      fontSize: 23,
      lineHeight: '32px',
      margin: 0,
      padding: 0,
    },
    h5: {
      fontSize: 20,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '24px',
      margin: 0,
      padding: 0,
    },
    h6: {
      fontSize: 18,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '24px',
      margin: 0,
      padding: 0,
    },
    caption: {
      fontSize: 14,
      lineHeight: '16px',
      margin: 0,
      padding: 0,
    },
  };

  const overrides = {
    MuiCssBaseline: {
      '@global': {
        '.Mui-disabled': { cursor: 'not-allowed', opacity: 0.5 },
        '.MuiButton-containedPrimary.Mui-disabled': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    Mui: {
      disabled: {
        cursor: 'not-allowed',
        opacity: 0.5,
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: theme.colors.negative[500],
      },
    },
  };

  return {
    ...theme,
    typography: {
      ...theme.typography,
      ...typography,
    },
    overrides: {
      ...theme.overrides,
      ...overrides,
    },
  };
};

const WidgetContainer: FC<WidgetContainerProps> = ({
  className,
  children,
  suppressWidgetTheme,
  theme,
  mode = Mode.Normal,
  ...otherProps
}) => {
  useEffect(() => {
    register();

    return () => unregister();
  }, []);

  useEffect(() => {
    registerMode(mode);

    return () => unregisterMode(mode);
  }, [mode]);

  return (
    <ThemeProvider theme={suppressWidgetTheme ? theme : WidgetThemeExtender(theme)}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {
          //@ts-ignore
          <div name="widget-container" className={className} {...otherProps}>
            <CssBaseline />
            {children || instanceCount}
          </div>
        }
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default WidgetContainer;
