
export enum ProgressIndicatorEnum {
  DEFAULT = 'Default',
  CIRCLE = 'Circle',
}

export type ProgressIndicatorType = 'Default' | 'Circle';

export type ProgressIndicatorConfig = {
  type?: ProgressIndicatorType,
  showLastProgressBar?: boolean;
  showDescription?: boolean;
};
