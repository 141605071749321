import React from 'react';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      color: theme.colors.gray[600],
    },
  }),
)((props: InputLabelProps) => <InputLabel shrink {...props} />);
