import React, { FC, ReactNode } from 'react';

import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

export interface WidgetContentProps extends WithStyles<typeof styles> {
  /**
   * The content of the component.
   */
  children?: ReactNode;
  /**
   * The `className`.
   */
  className?: string;
}

const WidgetContent: FC<WidgetContentProps> = ({ classes, className, children, ...otherProps }) => {
  return (
    <div {...otherProps} className={clsx('g-content', className, classes.root)}>
      {children}
    </div>
  );
};

export default withStyles(styles)(WidgetContent);
