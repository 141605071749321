import React from 'react';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import { Close } from '@material-ui/icons';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      borderRadius: theme.spacing(1),
      overflow: 'hidden',
    },

    sizeSmall: {
      borderRadius: theme.spacing(0.5),
    },

    avatar: {
      height: 32,
      width: 32,
      marginLeft: 0,
      borderRadius: 0,
    },

    avatarSmall: {
      height: 24,
      width: 24,
    },
  }),
)((props: ChipProps) => <Chip deleteIcon={<Close />} {...props} />);
