import React from 'react';
import { Box, Grid, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

type State = 'normal' | 'positive' | 'negative';

type ClassNameProps = {
  className?: string;
};

type Props<IP extends ClassNameProps = ClassNameProps> = {
  title: string;
  icon: React.ComponentType<IP>;
  subTitle?: string;
} & typeof defaultProps;

const defaultProps = Object.freeze({
  state: 'normal' as State,
});

const makeStateMapper = (stateMap: Record<State, string>) => (props: Props) => stateMap[props.state];

const iconContainerSize = 128;

const useStyles = makeStyles<Theme, Props>(theme => {
  return createStyles({
    iconContainer: {
      width: iconContainerSize,
      height: iconContainerSize,
      borderRadius: '50%',
      backgroundColor: makeStateMapper({
        normal: theme.colors.gray[100],
        positive: theme.colors.positive[0],
        negative: theme.colors.negative[0],
      }),
    },

    icon: {
      width: iconContainerSize / 2,
      height: iconContainerSize / 2,
      color: makeStateMapper({
        normal: theme.colors.technologyBlue[0],
        positive: theme.colors.positive[200],
        negative: theme.colors.negative[200],
      }),
    },

    title: {
      marginTop: theme.spacing(4),
      color: theme.colors.gray[400],
      fontSize: '1.625rem',
      lineHeight: '2.5rem',
    },

    subTitle: {
      marginTop: theme.spacing(1),
      color: theme.colors.gray[400],
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
    },
  });
});

const Empty = (props: Props) => {
  const { title, subTitle, icon: Icon } = props;
  const classes = useStyles(props);

  return (
    <Grid container direction="column" alignItems="center">
      <Grid container alignItems="center" justify="center" className={classes.iconContainer}>
        {React.createElement(Icon, { className: classes.icon })}
      </Grid>

      <Box className={classes.title}>{title}</Box>

      {typeof subTitle !== 'undefined' && <Box className={classes.subTitle}>{subTitle}</Box>}
    </Grid>
  );
};

Empty.defaultProps = defaultProps;

export default Empty;
