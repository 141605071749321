import React, { FC } from 'react';
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

import DumbbellChart from './DumbbellChart';
import { SeriesData } from './DumbbellType';
import PercentageBarChart from './PercentageBarChart';

export const styles = (theme: Theme) =>
  createStyles({
    root: { display: 'flex' },
    dumbbell: { flex: '0 0 80%' },
    bar: { flex: '0 0 20%' },
  });

export type Point = {
  value: number,
  color: string,
}
export type SummaryCalculatorFunction = (lowSeries: Array<number>, highSeries: Array<number>) => [number, number];
export type BarCalculatorFunction = (
  low: number,
  high: number,
  lowSummary: number,
  highSummary: number,
  lowSeries: Array<number>,
  highSeries: Array<number>,
  id?: string
) => number;

export interface DumbbellWidgetProps {
  data: Array<SeriesData>;
  summaryName?: string;
  summaryCalculator?: SummaryCalculatorFunction;
  barCalculator?: BarCalculatorFunction;
}

export const DumbbellWidget: FC<DumbbellWidgetProps & WithStyles<typeof styles>> = ({
  classes,
  data,
  summaryName,
  summaryCalculator,
  barCalculator,
}) => {
  const defaultSummaryCalculator: SummaryCalculatorFunction = (ls, hs) => {
    return [
      ls.reduce((s, l) => s + (l || 0), 0) / lowSeries.length,
      hs.reduce((s, h) => s + (h || 0), 0) / hightSeries.length,
    ];
  };

  const defaultBarCalculator: BarCalculatorFunction = (l, h) => {
    return h - l;
  };

  const lowSeries = data.map((d) => d.low || 0);
  const hightSeries = data.map((d) => d.high || 0);

  const [low, high] = (summaryCalculator || defaultSummaryCalculator)(lowSeries, hightSeries);
  const dumbbellData = [
    ...data.map(({dataLabels, ...o }) => o),
    {
      name: '',
    },
    {
      name: summaryName || 'Portfolio Fitness',
      low,
      high,
    },
  ];

  const barData = [
    ...data.map((v) => ({
      name: v.name,
      y: (barCalculator || defaultBarCalculator)(v.low || 0, v.high || 0, low, high, lowSeries, hightSeries, v.id as string),
      color: '#b0bec5',
      dataLabels: {
        align: 'left',
        inside: false,
        enabled: true,
        format: '{point.y:,.2f}',
        style: {
          color: '#999'
        },
        ...(v.dataLabels as Highcharts.DataLabelsOptions)
      },
    })),
    { name: 'dummy1' },
    { name: 'dummy2' },
  ];

  const chartOptions = {
    tooltip: {
      enabled: false
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.dumbbell}>
        <DumbbellChart
          data={dumbbellData}
          chartOptions={{
            tooltip: {
              followPointer: true,
              pointFormat:
                '<span style="color:{series.lowColor}">●</span><b> {point.low}</b><br/> <span style="color:{series.color}">●</span><b> {point.high}</b><br/>',
            },
          }}
        />
      </div>
      <div className={classes.bar}>
        <PercentageBarChart data={barData} chartOptions={chartOptions}/>
      </div>
    </div>
  );
};

export default withStyles(styles, { name: 'DumbbellWidget' })(DumbbellWidget);
