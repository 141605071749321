import React, { forwardRef, useEffect, useMemo, useRef, useState, FC } from 'react';

import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import {
  ColumnApi,
  ColumnGroupOpenedEvent,
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ILoadingOverlayComp,
  INoRowsOverlayComp,
  OriginalColumnGroup,
  StatusPanelDef,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import clsx from 'clsx';
import debounce from 'lodash/debounce';

import { mergeOptionalProps } from '../utils/PropUtils';

import DataGridPagination from './ChildComponents/DataGridPagination';
import ColumnNavigator from './Components/ColumnNavigator';
import { useTopPanel, DataGridTopPanelProps, DefaultTopPanelProps } from './Components/TopPanel';
import usePinnedHeaderWidthPatch from './Patch/usePinnedHeaderWidthPatch';

import { DataGridContextProvider } from './DataGridContext';
import {
  appendOption,
  ensureOption,
  generateDefaultFrameworkComponents,
  prependOption,
  CellEditableClassRules,
  DefaultColumnTypes,
  DefaultColumnDef,
  DefaultRowHeight,
} from './DataGridDefaultOptions';
import {
  generateCheckboxSelectionSettings,
  DataGridCheckboxSelectionProps,
  DefaultCheckboxSelectionProps,
} from './DataGridCheckboxSelection';
import { generatePaginationSettings, DataGridPaginationProps, DefaultPaginationProps } from './DataGridPagination';
import { ensureLicense } from './DataGridLicense';

import 'ag-grid-community/dist/styles/ag-grid.css';
import '../../styles/scss/ag-theme-prive.module.scss';

ensureLicense();

export interface DataGridProps {
  /**
   * Turns on or off auto fit columns. Default `false`.
   */
  autoFitColumns: boolean;
  /**
   * Defines checkbox selection behaviour.
   */
  checkboxSelection?: DataGridCheckboxSelectionProps;
  /**
   * Standard HTML Element class name.
   */
  className?: string;
  /**
   * Array of Column Definitions.
   */
  columnDefs?: (ColDef | ColGroupDef)[];
  /**
   * Exposes [AG Grid Options](https://www.ag-grid.com/javascript-grid-properties) for advance customization.
   */
  gridOptions?: GridOptions;
  /**
   * The label of row group panel.
   */
  rowGroupPanelLabel: string;
  /**
   * Defines the grid height.
   */
  height: number | string;
  /**
   * Turns on or off the status bar default compoments. e.g. Row Count, Selected Row Count Default `false`.
   */
  hideStatusBarDefaultComponents: boolean;
  /**
   * Turns on or off the status bar. Default `false`.
   */
  hideStatusBar: boolean;
  /**
   * Turns on or off the loading overlay. Default `false`.
   */
  loading: boolean;
  /**
   * Provides a custom loading overlay component.
   */
  loadingOverlayComponent:
    | {
        new (): ILoadingOverlayComp;
      }
    | string;
  /**
   * Provides a custom no rows message.
   */
  noRowsMessage: string | ((api: GridApi) => string);
  /**
   * Provides a custom no rows overlay component.
   */
  noRowsOverlayComponent:
    | {
        new (): INoRowsOverlayComp;
      }
    | string;
  /**
   * Set the data to be displayed as rows in the grid.
   */
  rowData?: any[];
  /**
   * Defines pagination behaviour.
   */
  pagination?: DataGridPaginationProps;
  /**
   * Turns on or off column navigator. Default `false`.
   */
  showColumnNavigator: boolean;
  /**
   * Adds zebra-striping to grid row within grid body
   */
  striped: boolean;
  /**
   * Suppress column group accordant behaviour. Default `false`.
   */
  suppressColumnGroupAccordant: boolean;
  /**
   * Suppress cell editable style. Default `false`.
   */
  suppressEditableCellStyle: boolean;
  /**
   * Defines the grid width.
   */
  width: number | string;
  /**
   * Override or extend the styles applied to the component.
   */
  classes?: {
    root: string;
  };
  /**
   * Defines top panel behaviour.
   */
  topPanel?: DataGridTopPanelProps;
}

const OverlayAnimationDebounceValue = 500;

function triggerAutoFitColumnHandler({ api, source }: { api: GridApi; source: string }) {
  // Filter out the chained events to prevent dead loop.
  if (source !== 'sizeColumnsToFit') {
    api.sizeColumnsToFit();
  }
}

function triggerOverlayHandler({ api }: { api: GridApi }, loading: boolean) {
  if (loading) {
    api.showLoadingOverlay();
  } else if (api.getModel().isEmpty()) {
    // Show no rows overlay when no rows displayed.
    // Maybe no rows, or all rows are filtered.
    api.showNoRowsOverlay();
  } else {
    api.hideOverlay();
  }
}

function triggerColumnGroupAccordantHandler({
  columnApi,
  columnGroup,
}: {
  columnApi: ColumnApi;
  columnGroup: OriginalColumnGroup;
}) {
  let hasColumnGroupStateChanged = false;
  if (columnGroup.isExpanded()) {
    const columnGroupStates = columnApi.getColumnGroupState();
    for (let columnGroupState of columnGroupStates) {
      if (columnGroupState.groupId !== columnGroup.getGroupId()) {
        if (columnGroupState.open !== false) {
          columnGroupState.open = false;
          hasColumnGroupStateChanged = true;
        }
      }
    }
    if (hasColumnGroupStateChanged) {
      columnApi.setColumnGroupState(columnGroupStates);
    }
  }

  return hasColumnGroupStateChanged;
}

const useStyles = makeStyles<Theme, DataGridProps>((theme) =>
  createStyles({
    root: {
      '& .ag-column-drop-icon.ag-faded': {
        opacity: 1,
      },
      '& .ag-column-drop-icon:before': {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.colors.greenGray[700],
        content: (props) => `"${props.rowGroupPanelLabel}"`,
      },
    },
  }),
);

const DataGrid: FC<DataGridProps> = forwardRef<AgGridReact, DataGridProps>((props, ref) => {
  const {
    /* Self */
    autoFitColumns,
    checkboxSelection,
    className,
    columnDefs,
    gridOptions,
    height,
    hideStatusBar,
    hideStatusBarDefaultComponents,
    loading,
    loadingOverlayComponent,
    noRowsMessage,
    noRowsOverlayComponent,
    pagination,
    rowData,
    showColumnNavigator,
    striped,
    suppressColumnGroupAccordant,
    suppressEditableCellStyle,
    width,
    topPanel,
    /* Other */
    children,
    ...otherProps
  } = props;

  const theme = useTheme();
  const classes = { ...useStyles(props), ...props.classes };

  const gridContainerRef = useRef<HTMLDivElement>(null);
  const [apis, setApis] = useState<GridReadyEvent>();
  const myGridOptions: GridOptions = { ...gridOptions };
  const hasRowData = rowData !== undefined;

  // Apply patch
  usePinnedHeaderWidthPatch(apis);

  const gridReadyHandler = (event: GridReadyEvent) => {
    // Fix Warning: Can't perform a React state update on an unmounted component
    if ((ref as any).current === null) return;
    // Keep the Grid API and Column API for later use.
    setApis(event);

    if (myGridOptions.onGridReady) {
      myGridOptions.onGridReady(event);
    }

    // Auto Fit Column on grid ready if need
    if (autoFitColumns) {
      triggerAutoFitColumnHandler({ api: event.api, source: 'gridReady' });
    }
  };

  appendOption(myGridOptions, 'context', { loading });
  prependOption(myGridOptions, 'defaultColDef', DefaultColumnDef);
  prependOption(myGridOptions, 'columnTypes', DefaultColumnTypes);
  ensureOption(myGridOptions, 'rowSelection', 'single');
  prependOption(myGridOptions, 'columnDefs', columnDefs);

  if (!suppressEditableCellStyle) {
    if (myGridOptions.defaultColDef) {
      myGridOptions.defaultColDef = {
        ...myGridOptions.defaultColDef,
        cellClassRules: {
          ...CellEditableClassRules,
          ...myGridOptions.defaultColDef.cellClassRules,
        },
      };
    }

    if (myGridOptions.columnDefs) {
      myGridOptions.columnDefs = myGridOptions.columnDefs.map((columnDef) => {
        const typedColumnDef = columnDef as ColDef;
        if (typedColumnDef.cellClassRules) {
          return {
            ...typedColumnDef,
            cellClassRules: {
              ...CellEditableClassRules,
              ...typedColumnDef.cellClassRules,
            },
          };
        }
        return columnDef;
      });
    }
  }

  // Top Panel
  const topPanelProps = mergeOptionalProps(topPanel, DefaultTopPanelProps);
  const TopPanel = useTopPanel(apis, topPanelProps.enabled);

  const statusBarSettings: {
    statusBar: {
      statusPanels: StatusPanelDef[];
    };
  } = {
    statusBar: {
      statusPanels: [],
    },
  };
  if (!hideStatusBarDefaultComponents) {
    statusBarSettings.statusBar.statusPanels.push(
      ...[
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left',
        },
        {
          statusPanel: 'agSelectedRowCountComponent',
          align: 'left',
        },
      ],
    );
  }
  if (myGridOptions.statusBar && myGridOptions.statusBar.statusPanels) {
    statusBarSettings.statusBar.statusPanels.push(...myGridOptions.statusBar.statusPanels);
  }

  const checkboxSelectionProps = mergeOptionalProps(checkboxSelection, DefaultCheckboxSelectionProps);
  const checkboxSelectionSettings = generateCheckboxSelectionSettings(checkboxSelectionProps);
  appendOption(myGridOptions, 'defaultColDef', checkboxSelectionSettings);

  const paginationProps = mergeOptionalProps(pagination, DefaultPaginationProps);
  const paginationSettings = generatePaginationSettings(paginationProps);
  if (paginationSettings !== undefined) {
    if (paginationProps.location === 'status-bar') {
      statusBarSettings.statusBar.statusPanels.push({
        statusPanel: 'puiPaginationStatus',
        align: 'right',
      });
    } else if (paginationProps.location === 'top-panel') {
      topPanelProps.children = (
        <>
          <DataGridPagination textFormatter={paginationProps.textFormatter!} />
          {topPanelProps.children}
        </>
      );
    }
  }

  const frameworkComponents = useMemo(() => {
    return generateDefaultFrameworkComponents(theme, paginationProps);
  }, [theme, paginationProps]);
  prependOption(myGridOptions, 'frameworkComponents', frameworkComponents);

  // Handle overlay
  // Create single debounced overlayhandler instance per DataGrid instance.
  const overlayHandler = useMemo(
    () =>
      debounce(
        (loading: boolean) => {
          if (apis) {
            return triggerOverlayHandler(apis, loading);
          }
        },
        OverlayAnimationDebounceValue,
        {
          leading: true,
        },
      ),
    [apis],
  );
  useEffect(() => {
    // Let AgGridReact handle the loading overlay when rowData is undefined.
    if (loading !== undefined && hasRowData) {
      // Give sometime for the overlay animation.
      overlayHandler(loading);
    }

    return () => {
      overlayHandler.cancel();
    };
  }, [loading, hasRowData, overlayHandler]);

  if (noRowsOverlayComponent === 'puiNoRowOverlay') {
    const specialGridOptions = myGridOptions as any;
    if (specialGridOptions.noRowsOverlayComponentParams === undefined) {
      specialGridOptions.noRowsOverlayComponentParams = {};
    }
    if (specialGridOptions.noRowsOverlayComponentParams.noRowsMessage === undefined) {
      specialGridOptions.noRowsOverlayComponentParams.noRowsMessage = noRowsMessage;
    }
  }

  // Auto Fit Columns
  useEffect(() => {
    if (apis) {
      const { api } = apis;

      const handleColumnGroupOpened = (params: ColumnGroupOpenedEvent) => {
        let hasColumnGroupStateChanged = suppressColumnGroupAccordant
          ? false
          : triggerColumnGroupAccordantHandler(params);
        if (!hasColumnGroupStateChanged) {
          triggerAutoFitColumnHandler({
            api: params.api,
            source: '',
          });
        }
      };

      // Clean up if any dependency is changed.
      const cleanup = () => {
        api.removeEventListener('columnVisible', triggerAutoFitColumnHandler);
        api.removeEventListener('columnResized', triggerAutoFitColumnHandler);
        api.removeEventListener('gridSizeChanged', triggerAutoFitColumnHandler);
        api.removeEventListener('columnGroupOpened', handleColumnGroupOpened);
        api.removeEventListener('columnGroupOpened', triggerColumnGroupAccordantHandler);
      };

      if (autoFitColumns) {
        api.addEventListener('gridSizeChanged', triggerAutoFitColumnHandler);
        api.addEventListener('columnResized', triggerAutoFitColumnHandler);
        api.addEventListener('columnVisible', triggerAutoFitColumnHandler);
        api.addEventListener('columnGroupOpened', handleColumnGroupOpened);
      } else if (!suppressColumnGroupAccordant) {
        api.addEventListener('columnGroupOpened', triggerColumnGroupAccordantHandler);
      }

      return cleanup;
    }
  }, [apis, autoFitColumns, suppressColumnGroupAccordant]);

  return (
    <div
      className={clsx('ag-theme-prive', classes.root, striped && 'ag-striped', className)}
      style={{
        height: height,
        width: width,
      }}
    >
      <div className="ag-toolbar">
        {apis && (
          <DataGridContextProvider
            value={{
              columnApi: apis.columnApi,
              gridApi: apis.api!,
            }}
          >
            {children}
          </DataGridContextProvider>
        )}
      </div>

      <div ref={gridContainerRef} className="ag-grid">
        <AgGridReact
          rowHeight={DefaultRowHeight}
          suppressDragLeaveHidesColumns={true}
          // tooltipShowDelay={1} // Not support in v22
          {...otherProps}
          {...myGridOptions}
          /* Props below should not be overrided */
          ref={ref}
          loadingOverlayComponent={loadingOverlayComponent}
          noRowsOverlayComponent={noRowsOverlayComponent}
          onGridReady={gridReadyHandler}
          {...paginationSettings}
          rowData={rowData}
          {...(hideStatusBar ? {} : statusBarSettings)}
          // Re-enable when AG Grid allow custom properties.
          suppressPropertyNamesCheck={true}
        />
        {showColumnNavigator && apis && (
          <ColumnNavigator columnApi={apis.columnApi} gridApi={apis.api} gridContainer={gridContainerRef.current} />
        )}
      </div>

      <TopPanel>
        {apis && (
          <DataGridContextProvider
            value={{
              columnApi: apis.columnApi,
              gridApi: apis.api!,
            }}
          >
            {topPanelProps.children}
          </DataGridContextProvider>
        )}
      </TopPanel>
    </div>
  );
});

DataGrid.defaultProps = {
  height: 200,
  hideStatusBar: false,
  loading: false,
  loadingOverlayComponent: 'puiLoadingOverlay',
  noRowsMessage: 'No results found!',
  noRowsOverlayComponent: 'puiNoRowOverlay',
  rowGroupPanelLabel: 'Grouping',
  showColumnNavigator: false,
  suppressColumnGroupAccordant: false,
  suppressEditableCellStyle: false,
  width: '100%',
};

export default DataGrid;
