import React, { forwardRef } from 'react';

import Highcharts from 'highcharts';
import mergeWith from 'lodash/mergeWith';

import ChartBase, { ChartCommonProps, ChartBaseRef } from './ChartBase';
import { replaceIfArray } from './utils';

export const ScatterChart = forwardRef<ChartBaseRef, ChartCommonProps>(({ defaultOptions, ...otherProps }, ref) => {
  const myDefaultOptions: Highcharts.Options = mergeWith(
    {
      chart: {
        type: 'scatter',
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 10,
          },
        },
      }
    },
    defaultOptions,
    replaceIfArray,
  );

  return <ChartBase ref={ref} defaultOptions={myDefaultOptions} {...otherProps} />;
});

ScatterChart.defaultProps = {};

export default ScatterChart;
