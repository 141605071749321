/* eslint-disable react/no-array-index-key */
/* eslint-disable new-cap */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React from 'react'
import * as R from 'ramda'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { ErrorMessage } from '@hookform/error-message'
import MenuItem from '@material-ui/core/MenuItem'
import classnames from 'classnames'
import OptionsHOC from '../common/OptionsHOC'
import { AuthContext } from '../../auth/AuthContextProvider'
import localize from '../../utils/localize'

const StyledNoProductView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    font-size: 28px;
    font-weight: bold;
`

const LANGUAGE_OPTIONS = [
    { value: 'CAN', name: () => localize.strings.Cantonese },
    { value: 'ENG', name: () => localize.strings.English },
    { value: 'MAN', name: () => localize.strings.Mandarin }
]

export const PRODUCT_TYPE_OPTIONS = [
    { value: 'QUICK_CASH', name: () => localize.strings.QuickCash },
    { value: 'EPP', name: () => localize.strings.EPP },
    { value: 'EPP2', name: () => localize.strings.EPP2 },
    { value: 'PERSONAL_LOAN', name: () => localize.strings.PersonalLoan },
    { value: 'BAL_A_CHECK', name: () => localize.strings.BalACheck }
]

const FIELDS = products => [
    [
        {
            type: 'selection',
            label: () => localize.strings.DialInLanguage,
            defaultValue: 'CAN',
            key: 'language',
            rules: getValues => ({
                required: localize.strings.Required
            }),
            render: errors => ({ onChange, value }) => (
                <FormControl className="default-input" style={{ width: 790 }}>
                    <Select
                        value={value}
                        displayEmpty
                        className={classnames('input-box')}
                        labelId="customized-select-label"
                        id="customized-select"
                        disableUnderline
                        style={{
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            fontFamily:
                                '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif',
                            color: '#3D505D',
                            fontSize: '16px'
                        }}
                        onChange={onChange}
                    >
                        {LANGUAGE_OPTIONS.map(i => (
                            <MenuItem key={i.value} value={i.value}>
                                {i.name()}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )
        },
        {
            type: 'selection',
            label: () => localize.strings.ProductType,
            defaultValue: PRODUCT_TYPE_OPTIONS.filter(i => Object.keys(products).indexOf(i.value) !== -1)[0].value,
            key: 'productType',
            rules: getValues => ({
                required: localize.strings.Required
            }),
            render: errors => ({ onChange, value }) => (
                <FormControl className="default-input" style={{ width: 790 }}>
                    <Select
                        value={value}
                        displayEmpty
                        className={classnames('input-box')}
                        labelId="customized-select-label"
                        id="customized-select"
                        disableUnderline
                        style={{
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            fontFamily:
                                '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif',
                            color: '#3D505D',
                            fontSize: '16px'
                        }}
                        onChange={onChange}
                    >
                        {PRODUCT_TYPE_OPTIONS.filter(i => Object.keys(products).indexOf(i.value) !== -1).map(i => (
                            <MenuItem key={i.value} value={i.value}>
                                {i.name()}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )
        }
    ]
]

const SelectionForm = ({ title }) => {
    const { errors, control, getValues, trigger } = useFormContext()
    const { authState } = React.useContext(AuthContext)
    const { products } = authState.authInfo
    if (R.isEmpty(products)) return <StyledNoProductView>{localize.strings.NO_PRODUCT_AVAILABLE}</StyledNoProductView>
    return (
        <>
            <h5>{title}</h5>
            {FIELDS(products).map((row, index) => (
                <div className="row" key={`SelectionForm-${index}`}>
                    {row.map(item => (
                        <div className="col s12" key={item.key}>
                            <label className="input-label">{item.label()}</label>
                            <div className="row">
                                <OptionsHOC type={item.type} isError={errors[item.key]}>
                                    <Controller
                                        defaultValue={item.defaultValue}
                                        name={item.key}
                                        control={control}
                                        render={item.render(errors)}
                                        rules={item.rules(getValues, trigger)}
                                    />
                                </OptionsHOC>
                                <div style={{ wordWrap: 'break-word', color: 'red', width: '368px' }}>
                                    <label className="input-label error" style={{ textAlign: 'left', color: 'red' }}>
                                        <ErrorMessage errors={errors} name={item.key} />
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </>
    )
}

export default SelectionForm
