import { Accordion } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      boxShadow: 'none',
      borderBottom: 0,

      '&$expanded': {
        margin: 0,
      },

      '&:before': {
        display: 'none',
      },
    },

    /**
     * In order to make those `$ruleName` references work.
     * [reference](https://material-ui.com/customization/components/#use-rulename-to-reference-a-local-rule-within-the-same-style-sheet)
     */
    expanded: {},
  }),
)(Accordion);
