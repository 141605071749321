import React from 'react';
import { CheckBoxOutlineBlank, CheckBox, IndeterminateCheckBox } from '@material-ui/icons';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      color: theme.colors.positive[600],
      '&$checked': {
        color: theme.colors.positive[600],
      },
    },
    checked: {},
  }),
)((props: CheckboxProps) => {
  return (
    <Checkbox
      icon={<CheckBoxOutlineBlank />}
      checkedIcon={<CheckBox />}
      indeterminateIcon={<IndeterminateCheckBox />}
      {...props}
    />
  );
});
