import React, { useEffect, useCallback, useState } from 'react';

import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ColumnApi, GridApi } from 'ag-grid-community';
import { getCenterViewport, getGridPanel } from '../utils';

// Depend on the OS & browser, there is a +/- 3px vary in the scroll width.
// Add threshold to compensate for the variation
const NextThreshold = 3;

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      position: 'absolute',
      '.newStyle button&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
      'button&:hover': {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.4,
      },
    },
    shadow: {
      background: 'radial-gradient(100% 49.18% at 0% 50.82%, #E0E0E0 0%, rgba(224, 224, 224, 0) 100%)',
      height: '100%',
      position: 'absolute',
      top: 0,
      width: 20,
    },
  });

const ColumnNavigator = ({
  classes,
  columnApi,
  gridApi,
  gridContainer,
}: {
  columnApi: ColumnApi;
  gridApi: GridApi;
  gridContainer: HTMLElement | null;
} & WithStyles<typeof styles>) => {
  const [position, setPosition] = useState<{
    center?: number;
    left?: number;
    right?: number;
  }>({});
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const refreshLayout = useCallback(() => {
    if (gridApi && gridContainer) {
      const gridPanel = getGridPanel(gridApi);
      const centerViewport = getCenterViewport(gridPanel);

      const containerRect = gridContainer.getBoundingClientRect();
      const bodyRect = gridPanel.getBodyClientRect();
      const centerViewportRect = centerViewport.getBoundingClientRect();
      setPosition({
        center: bodyRect.top - containerRect.top + bodyRect.height / 2,
        left: centerViewportRect.left - containerRect.left,
        right: containerRect.right - centerViewportRect.right,
      });

      const scrollLeft = gridPanel.getCenterViewportScrollLeft();
      const scrollWidth = gridPanel.getCenterContainer().clientWidth;
      const centerWidth = gridPanel.getCenterWidth();
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - centerWidth);
    }
  }, [gridApi, gridContainer]);

  function handleLeftArrowClick() {
    if (gridApi && columnApi) {
      const gridPanel = getGridPanel(gridApi);
      const columns = columnApi.getDisplayedCenterColumns();

      let left = gridPanel.getCenterViewportScrollLeft();
      for (let column of columns) {
        left -= column.getActualWidth();
        if (left <= NextThreshold) {
          gridApi.ensureColumnVisible(column.getColId());
          break;
        }
      }
    }
  }

  function handleRightArrowClick() {
    if (gridApi && columnApi) {
      const gridPanel = getGridPanel(gridApi);
      const columns = columnApi.getDisplayedCenterColumns().slice().reverse();

      const scrollLeft = gridPanel.getCenterViewportScrollLeft();
      const scrollWidth = gridPanel.getCenterContainer().clientWidth;
      const centerWidth = gridPanel.getCenterWidth();
      let right = scrollWidth - centerWidth - scrollLeft;
      for (let i = 0; i < columns.length; i++) {
        const column = columns[i];
        right -= column.getActualWidth();
        if (right <= NextThreshold) {
          if (centerWidth < column.getActualWidth()) {
            if (i > 0) {
              gridApi.ensureColumnVisible(columns[i - 1].getColId());
              break;
            }
          }

          gridApi.ensureColumnVisible(column.getColId());
          break;
        }
      }
    }
  }

  useEffect(() => {
    if (gridApi) {
      refreshLayout();

      const gridPanel = getGridPanel(gridApi);
      const centerViewport = getCenterViewport(gridPanel);
      const ro = new ResizeObserver(refreshLayout);

      ro.observe(centerViewport);
      gridApi.addEventListener('displayedColumnsChanged', refreshLayout);
      gridApi.addEventListener('columnResized', refreshLayout);
      gridApi.addEventListener('gridSizeChanged', refreshLayout);
      gridApi.addEventListener('columnGroupOpened', refreshLayout);
      gridApi.addEventListener('bodyScroll', refreshLayout);

      return () => {
        gridApi.removeEventListener('displayedColumnsChanged', refreshLayout);
        gridApi.removeEventListener('columnResized', refreshLayout);
        gridApi.removeEventListener('gridSizeChanged', refreshLayout);
        gridApi.removeEventListener('columnGroupOpened', refreshLayout);
        gridApi.removeEventListener('bodyScroll', refreshLayout);
        ro.disconnect();
      };
    }
  }, [gridApi, refreshLayout]);

  return (
    <>
      {canScrollLeft && (
        <>
          <div
            className={classes.shadow}
            style={{
              left: position.left || 0,
            }}
          />
          <IconButton
            className={classes.icon}
            onClick={handleLeftArrowClick}
            size="small"
            style={{
              top: position.center || 0,
              left: position.left || 0,
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </>
      )}

      {canScrollRight && (
        <>
          <div
            className={classes.shadow}
            style={{
              right: position.right || 0,
              transform: 'rotate(-180deg)',
            }}
          />
          <IconButton
            className={classes.icon}
            onClick={handleRightArrowClick}
            size="small"
            style={{
              top: position.center || 0,
              right: position.right || 0,
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(ColumnNavigator);
