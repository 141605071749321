import React, { useContext } from 'react';
import { mergeDeepRight } from 'ramda';

import { Box, IconButton, Grid } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { makeStyles, createStyles, ThemeProvider, useTheme } from '@material-ui/core/styles';
import { ColDef } from 'ag-grid-community';

import { GridContext } from '../DataGrid';
import promisify, { CANCEL } from 'Dialog/promisify';
import ExpandButton from './ExpandButton';
import SettingMenu from './SettingMenu';
import SearchInput from './SearchInput';
import CustomizeGridDialog from './CustomizeGridDialog';
import useCustomizeColumns from './hooks/useCustomizeColumns';

type Props = typeof defaultProps;

const defaultProps = Object.freeze({
  showSearchInput: true,
  showExpandButton: false,
  showSettingMenu: false,
});

// Used for correcting the styles that got overridden by monolith
const stylesReset = {
  iconButtonRoot: {
    '&&&': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
};

const useStyles = makeStyles(theme =>
  createStyles(
    mergeDeepRight(stylesReset, {
      iconButtonRoot: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        padding: 0,
        border: `1px solid ${theme.palette.primary.main}`,
      },

      searchContainer: {
        flex: 1,
      },

      expandContainer: {
        marginRight: theme.spacing(2),
      },
    }),
  ),
);

const GridOperations = ({ showSearchInput, showExpandButton, showSettingMenu }: Props) => {
  const classes = useStyles();
  const { gridApi, columnApi } = useContext(GridContext)!;
  const { update } = useCustomizeColumns(gridApi, columnApi);
  const defaultTheme = useTheme();

  const handleOpenCustomizeDialog = async () => {
    try {
      const colDefs = await promisify<ColDef[]>(({ open, onSubmit, onDismiss }) => (
        <ThemeProvider theme={defaultTheme}>
          <CustomizeGridDialog
            open={open}
            gridApi={gridApi}
            columnApi={columnApi}
            onSubmit={onSubmit}
            onDismiss={onDismiss}
          />
        </ThemeProvider>
      ));

      update(colDefs);
    } catch (e) {
      if (e !== CANCEL) {
        console.log(e);
      }
    }
  };

  const menuItems = [
    {
      key: 'customize',
      title: 'Customize Columns',
      handler: handleOpenCustomizeDialog,
    },
  ];

  return (
    <Box mb={3}>
      <Grid container justify="space-between">
        {showSearchInput && (
          <Grid item className={classes.searchContainer}>
            <SearchInput />
          </Grid>
        )}
        {showExpandButton && (
          <Grid item className={classes.expandContainer}>
            <ExpandButton className={classes.iconButtonRoot} />
          </Grid>
        )}
        {showSettingMenu && (
          <Grid item>
            <SettingMenu menuItems={menuItems}>
              {({ setAnchorEl }) => (
                <IconButton
                  color="primary"
                  onClick={e => {
                    setAnchorEl(e.currentTarget);
                  }}
                  classes={{ root: classes.iconButtonRoot }}
                >
                  <Settings />
                </IconButton>
              )}
            </SettingMenu>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

GridOperations.defaultProps = defaultProps;

export default GridOperations;
