import React, { useState, Dispatch, FC, MouseEvent, MouseEventHandler, ReactElement, SetStateAction } from 'react';

import { Menu, MenuItem, ListItemText, ListItemIcon } from 'Menu';

export interface ControlledMenuItem {
  key: string;
  label: string;
  handler: MouseEventHandler<HTMLLIElement>;
  /**
   * The icon of the component, normally `Icon`, `SvgIcon`,
   * or a `@material-ui/icons` SVG icon element.
   */
  icon?: ReactElement;
}

export interface ControlledMenuProps {
  triggerComponent: (setAnchorEl: Dispatch<SetStateAction<Element | undefined>>) => ReactElement;
  menuItems: ControlledMenuItem[];
}

const ControlledMenu: FC<ControlledMenuProps> = ({ triggerComponent, menuItems }) => {
  const [anchorEl, setAnchorEl] = useState<Element>();
  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const makeHandler = (handler: MouseEventHandler<HTMLElement>) => (event: MouseEvent<HTMLElement>) => {
    handler(event);
    handleClose();
  };

  return (
    <>
      {triggerComponent(setAnchorEl)}

      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {menuItems.map(menuItem => (
          <MenuItem key={menuItem.key} onClick={makeHandler(menuItem.handler)}>
            {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
            <ListItemText>{menuItem.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ControlledMenu;
