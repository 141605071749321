/* eslint-disable import/prefer-default-export */
/* eslint-disable no-restricted-properties */
import localize from './localize'

/**
 * calcPILLoan
 * (Number, Number, Number) -> (Boolean || String)
 * @param {Number} loanAmount
 * @param {Number} outstandingLoanAmount
 * @param {Number} extensionFee
 * @returns {Boolean || String}
 */
const calcPILLoan = (loanAmount, outstandingLoanAmount, extensionFee) => {
    const r1 = outstandingLoanAmount.toString().split('.')[1]?.length || 0
    const r2 = extensionFee.toString().split('.')[1]?.length || 0
    const m = Math.pow(10, Math.max(r1, r2))
    if (loanAmount > 5000000 || loanAmount < 8000) return localize.strings.InvalidLoanAmountRangePIL
    if (loanAmount < (outstandingLoanAmount * m + extensionFee * m) / m) {
        return localize.strings.NegativeDisbursementAmount
    }
    return true
}

export { calcPILLoan }
