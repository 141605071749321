import React from 'react';
import { prop } from 'ramda';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, useTheme, Theme } from '@material-ui/core/styles';
import { ProgressIndicatorEnum } from '../StepWizard/WizardTypes/ProgressIndicatorConfiguration';

type Props = {
  title: string;
  description: string;
  currentStep: number;
  maxStep: number;
} & typeof defaultProps;

type Variant = 'wait' | 'current' | 'done' | 'error';

const defaultProps = Object.freeze({
  variant: 'wait' as Variant,
});

const makeCircleStyles = (theme: any) => {
  return createStyles({
    root: {
      minWidth: 0,
    },

    bar: {
      height: 4,
      backgroundColor: theme.colors.paleOliveGreen[300],
      margin: theme.spacing(0, 2),
      transform: 'translateY(-18px)',
    },

    wait: {
      backgroundColor: theme.colors.gray[500],
      '&:hover': {
        backgroundColor: theme.colors.gray[500],
      },
    },
    current: {
      backgroundColor: theme.colors.gray[500],
    },
    title: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme.palette.common.black,
      fontSize: 12,
      borderRadius: '50%',
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.colors.gray[500],
      margin: 0,
      marginRight: theme.spacing(-2) + 4,
      marginLeft: theme.spacing(-2) - 1,
      zIndex: 100,
      width: 34,
      height: 34,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    titleDone: {
      color: theme.palette.common.black,
      backgroundColor: theme.colors.paleOliveGreen[300],
      borderColor: theme.colors.paleOliveGreen[300],
      fontWeight: 'bold',
    },

    titleWait: {
      backgroundColor: theme.palette.common.white,
      color: theme.colors.gray[500],
      borderColor: theme.colors.gray[500],
    },

    titleCurrent: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      borderColor: theme.colors.paleOliveGreen[300],
      fontWeight: 'bold',
    },

    stepLabel: {
      fontSize: 12,
      transform: 'translateX(-50%)',
      textAlign: 'center',
    },

    stepLabelCurrent: {
      fontWeight: 'bold',
    },

    lastStepLabel: {
      marginTop: '4px',
    },
    [theme.breakpoints.down('xs')]: {
      stepLabel: {
        fontSize: 12,
      },
      title: {
        padding: 0,
        minWidth: 20,
        fontSize: 12,
        marginLeft: 0,
        textAlign: 'center',
        transform: 'translateX(-50%)',
      },
      bar: {
        transform: 'translateY(-17px)',
        height: 2,
        margin: 0,
      },
    },
  });
};

const useStyles = makeStyles<Theme, Props>((theme) => {
  if (theme.progressIndicator && theme.progressIndicator.type === ProgressIndicatorEnum.CIRCLE) {
    return makeCircleStyles(theme);
  }

  const colorMapper = ({ variant }: Props) =>
    prop(variant, {
      wait: theme.colors.technologyBlue[0],
      current: theme.colors.technologyBlue[600],
      done: theme.colors.positive[600],
      error: theme.colors.negative[600],
    });

  return createStyles({
    root: {
      minWidth: 0,
    },

    bar: {
      height: 4,
      backgroundColor: colorMapper,
      margin: theme.spacing(1, 0),
    },

    wait: {
      '&:hover': {
        backgroundColor: theme.colors.technologyBlue[200],
      },
    },

    title: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(2),
      color: colorMapper,
      fontSize: '0.875rem',
    },
  });
});

const ProgressIndicatorItem = (props: Props) => {
  const { variant, title, description, currentStep, maxStep } = props;
  const theme = useTheme();
  const classes = useStyles(props);

  let showLastProgressBar = true;
  if (theme.progressIndicator && theme.progressIndicator.showLastProgressBar === false) {
    if (currentStep >= maxStep) showLastProgressBar = false;
  }

  return (
    <Grid container item xs direction="column" className={classes.root}>
      {
        <Grid
          item
          className={clsx(classes.title, {
            [classes.titleWait]: variant === 'wait',
            [classes.titleDone]: variant === 'done',
            [classes.titleCurrent]: variant === 'current',
          })}
        >
          {title}
        </Grid>
      }

      {showLastProgressBar && (
        <Grid
          item
          className={clsx(classes.bar, {
            [classes.wait]: variant === 'wait',
            [classes.done]: variant === 'done',
            [classes.current]: variant === 'current',
          })}
        />
      )}

      {theme.progressIndicator && theme.progressIndicator.showDescription && (
        <Grid
          item
          className={clsx(classes.stepLabel, {
            [classes.lastStepLabel]: currentStep === maxStep,
            [classes.stepLabelWait]: variant === 'wait',
            [classes.stepLabelDone]: variant === 'done',
            [classes.stepLabelCurrent]: variant === 'current',
          })}
        >
          {description}
        </Grid>
      )}
    </Grid>
  );
};

ProgressIndicatorItem.defaultProps = defaultProps;

export default ProgressIndicatorItem;
