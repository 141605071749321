import React from 'react'
import classnames from 'classnames'
import enLocale from 'date-fns/locale/en-US'
import zhLocale from 'date-fns/locale/zh-TW'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import localize from '../../utils/localize'

const localeMap = {
    en: enLocale,
    zh: zhLocale
}
const OptionsHOC = ({ children, type, isError, isFullWidth = false, fullWidth = 780 }) => {
    if (type === 'selection') {
        return <>{children}</>
    }
    if (type === 'date') {
        return (
            <div className="default-input">
                <MuiPickersUtilsProvider
                    className="default-input"
                    locale={localeMap[localize.getLanguage()]}
                    utils={DateFnsUtils}
                >
                    {children}
                </MuiPickersUtilsProvider>
            </div>
        )
    }
    return (
        <div className="default-input" style={{ width: isFullWidth ? fullWidth : 368 }}>
            <div className={classnames('input-box', { error: isError })}>{children}</div>
        </div>
    )
}

export default OptionsHOC
