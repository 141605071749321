import React, { FC, useState, useEffect, useRef } from 'react';

import Highcharts from 'highcharts';
import HighchartsCustomEvent from 'highcharts-custom-events';
import mergeWith from 'lodash/mergeWith';

import ChartBase from '../ChartBase';
import SlideNavigator from './SlideNavigator';
import { replaceIfArray } from '../utils';

HighchartsCustomEvent(Highcharts);
// Bug Fix: Custom event call undefined events
const customEvent = (Highcharts.Chart.prototype as any).customEvent;
const originAdd = customEvent.add;
const noop = () => {};
customEvent.add = (SVGelem: any, events: any, elemObj: any, eventElement: any, isPoint: any) => {
  if (events) {
    for (const key of Object.keys(events)) {
      if (events[key] === undefined) {
        events[key] = noop;
      }
    }
  }
  originAdd(SVGelem, events, elemObj, eventElement, isPoint);
};

interface SeriesBarOptions extends Highcharts.SeriesBarOptions {
  name: string;
  data: Array<number>;
}

interface SeriesLayer {
  layerId: string;
  title: string;
  categories: Array<string>;
  series: Array<SeriesBarOptions>;
  labelsOption: any; // Highcharts.XAxisLabelsOptions
  defaultOptions?: Highcharts.Options;
}

export type SeriesLayerClickCallbackFunction = (
  series: any, //Highcharts.Series,
  layer: SeriesLayer,
  event: Highcharts.SeriesClickEventObject,
) => void;

export interface BarChartSeriesProps {
  chartMinWidth: number;
  layers: Array<SeriesLayer>;
  clickCallback: SeriesLayerClickCallbackFunction;
  seriesVisibles: Array<boolean>;
}

interface BarChartSeriesState {
  chartWidth: number | undefined;
  chartsPerSlide: number;
  totalSlides: number;
}

const BarChartSeriesStateDefault: BarChartSeriesState = {
  chartWidth: undefined,
  chartsPerSlide: 1,
  totalSlides: 1,
};

export const BarChartSeries: FC<BarChartSeriesProps> = ({ chartMinWidth, layers, clickCallback }) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<BarChartSeriesState>(BarChartSeriesStateDefault);
  useEffect(() => {
    if (!sliderRef.current) {
      throw new Error('Slider Ref must not be null.');
    }

    const newState: BarChartSeriesState = BarChartSeriesStateDefault;
    const sliderWidth = sliderRef.current.clientWidth;
    if (sliderWidth > chartMinWidth) {
      newState.chartsPerSlide = Math.floor(sliderWidth / chartMinWidth);
      newState.chartWidth = Math.floor(100 / newState.chartsPerSlide);
      newState.totalSlides = layers.length;
    } else {
      newState.chartWidth = sliderWidth;
    }
    setState((s) => ({ ...s, ...newState }));
  }, [chartMinWidth, layers, sliderRef]);

  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const changeSlide = (targetSlide: number) => setCurrentSlide(targetSlide);

  const charts: Array<JSX.Element> = !state.chartWidth
    ? []
    : layers.slice(currentSlide, currentSlide + state.chartsPerSlide).map((layer) => {
        const defaultOptions: Highcharts.Options = mergeWith(
          {
            title: {
              align: 'left',
              text: layer.title,
            },
            chart: {
              spacing: [20, 20, 25, 20],
              type: 'bar',
            },
            tooltip: {
              shared: true,
            },
            plotOptions: {
              series: {
                animation: false,
              },
              // bar: {
              //   events: {
              //     click(event: any) {
              //       clickCallback(this, layer, event);
              //     },
              //   },
              // },
            },
          },
          layer.defaultOptions,
          replaceIfArray,
        );
        const labelsOption = {
          events: {
            click(event: any) {
              clickCallback(this, layer, event);
            },
          },
          ...layer.labelsOption,
        } as Highcharts.XAxisLabelsOptions;
        const options: Highcharts.Options = {
          xAxis: {
            categories: layer.categories,
            labels: labelsOption,
          },
          series: layer.series,
        };
        const containerProps = {
          style: {
            flex: `0 0 ${state.chartWidth}%`,
          },
        };
        return (
          <ChartBase
            key={layer.layerId}
            containerProps={containerProps}
            defaultOptions={defaultOptions}
            highcharts={Highcharts}
            options={options}
          />
        );
      });
  return (
    <>
      <div style={{ width: '100%', display: 'flex', overflow: 'hidden' }} ref={sliderRef}>
        {charts.length > 0 ? charts : 'loading'}
      </div>
      <SlideNavigator
        currentSlide={currentSlide}
        activeSlide={state.chartsPerSlide}
        totalSlides={state.totalSlides}
        onClick={changeSlide}
      />
    </>
  );
};

export default BarChartSeries;
