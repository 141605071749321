import React, { FC } from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import clsx from 'clsx';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    wrapper: {
      '& .MuiIconButton-root': {
        fontSize: 20,
        padding: theme.spacing(0, 0.5),
        height: '100%',
      },
    },
  }),
);

const DataGridTopPanelIconButton: FC<IconButtonProps> = ({ className, ...otherProps }) => {
  const styles = useStyles();

  return (
    <div className={clsx(styles.wrapper, className)}>
      <IconButton {...otherProps} />
    </div>
  );
};

export default DataGridTopPanelIconButton;
