import React, { FC } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import HeaderRenderer, { HeaderRendererProps } from './HeaderRenderer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '1.5em',
      padding: theme.spacing(0.5),
    },
    clickable: {
      borderRadius: 0,
      padding: theme.spacing(0.5),
    },
  }),
);

const IconHeaderRenderer: FC<HeaderRendererProps> = (props) => {
  const classes = useStyles();

  const { column, context } = props;
  const colDef = column.getColDef();
  const headerComponentProps = (colDef as any).headerComponentProps || {};
  const onClickCallback = (props as any).onClick || (context && context.onIconHeaderClick);

  const component = (props: any) => {
    const { clickable, icon, ...otherProps } = props;
    return (
      <div className={classes.root} {...otherProps}>
        {icon === undefined ? <MoreHorizIcon /> : icon}
      </div>
    );
  };

  const clickableComponent = (props: any) => {
    const { clickable, icon, ...otherProps } = props;
    return (
      <IconButton
        classes={{
          root: classes.clickable,
        }}
        color="inherit"
        aria-label="more"
        {...otherProps}
      >
        {icon === undefined ? <MoreHorizIcon /> : icon}
      </IconButton>
    );
  };

  return (
    <HeaderRenderer
      {...props}
      component={headerComponentProps.clickable === false ? component : clickableComponent}
      onClick={onClickCallback}
    />
  );
};

export default IconHeaderRenderer;
