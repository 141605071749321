import React, {Fragment} from "react";
import localize from "../../utils/localize";

const DialInSection = ({data = {dialInInfo: {phoneNo:undefined, pin:undefined}}}) => {
    return data && data.dialInInfo && data.dialInInfo.pin ?
        <Fragment>
            <h2>{localize.strings.LastStep}</h2>
            <p>{localize.strings.DialInTheNumberBelow}</p>
            <div className="panel-default gradient">
                <div className="row">
                    <div className="col s6">
                        <div className="cal-details">
                            <p className="caption">{localize.strings.DialIn}</p>
                            <p>{data.dialInInfo.phoneNo}</p>
                        </div>
                    </div>
                    <div className="col s6">
                        <div className="cal-details">
                            <p className="caption">{localize.strings.UniqueKey}</p>
                            <p>{data.dialInInfo.pin}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
        : <Fragment/>
}

export default DialInSection;
