import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      color: theme.colors.technologyBlue[0],
    },

    separator: {
      color: theme.colors.greenGray[700],
    },

    ol: {
      '& > $li:last-child': {
        color: theme.colors.greenGray[700],
      },
    },

    li: {},
  }),
)(Breadcrumbs);
