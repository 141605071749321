import { Column, ColumnApi } from 'ag-grid-community';

export enum HeaderCheckboxMode {
  Normal = 'normal',
  FilteredOnly = 'filteredOnly',
}

export interface DataGridCheckboxSelectionProps {
  /**
   * Turns on or off the built-in checkbox selection. Default `false`.
   */
  enabled: boolean;
  /**
   * Defines the header checkbox behaviour. Default `normal`.
   */
  headerCheckboxMode: HeaderCheckboxMode;
  /**
   * Shows or hides the header checkbox. Default `false`.
   */
  showHeaderCheckbox: boolean;
}

export const DefaultCheckboxSelectionProps: DataGridCheckboxSelectionProps = Object.freeze({
  enabled: false,
  headerCheckboxMode: HeaderCheckboxMode.Normal,
  showHeaderCheckbox: false,
});

function isFirstColumn({ column, columnApi }: { column: Column; columnApi: ColumnApi }): boolean {
  if (columnApi) {
    const displayedColumns = columnApi.getAllDisplayedColumns();
    return displayedColumns[0] === column;
  }
  return false;
}

export function generateCheckboxSelectionSettings(inputProps?: DataGridCheckboxSelectionProps) {
  if (inputProps !== undefined && inputProps.enabled) {
    return {
      checkboxSelection: isFirstColumn,
      headerCheckboxSelection: inputProps.showHeaderCheckbox ? isFirstColumn : undefined,
      headerCheckboxSelectionFilteredOnly: inputProps.headerCheckboxMode === HeaderCheckboxMode.FilteredOnly,
    };
  }

  return undefined;
}
