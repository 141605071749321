import { GridApi, GridCore, GridPanel } from 'ag-grid-community';

/**
 * Get grid panel directly from api instead of query from DOM.
 * Note: Grid panel is private and may remove in later version.
 */
export function getGridCore(gridApi: GridApi): GridCore {
  return gridApi['gridCore'];
}

export function getGridPanel(gridApi: GridApi): GridPanel {
  return gridApi['gridPanel'];
}

export function getCenterViewport(gridPanel: GridPanel): HTMLElement {
  return gridPanel['eCenterViewport'];
}

export function getRefElement(gridApi: GridApi, refName: string): HTMLElement | null {
  return getGridPanel(gridApi).getGui().querySelector(`div[ref="${refName}"]`);
}

export function hasScroll(el: HTMLElement, axis?: 'x' | 'y') {
  let s = window.getComputedStyle(el),
    sX = s.overflowX,
    sY = s.overflowY,
    hidden = 'hidden', // minifiers would like this better
    visible = 'visible',
    scroll = 'scroll';

  if (!axis) { // better check than undefined
    //Check both x and y declarations
    if (sX === sY && (sY === hidden || sY === visible)) { //same check but shorter syntax
      return false;
    }
    if (sX === scroll || sY === scroll) {
      return true;
    }
    return parseInt(s.height) < el.scrollHeight ||
      parseInt(s.width) < el.scrollWidth;
  } else if (axis === 'x') { // don't mix ifs and switches on the same variable
    if (sX === hidden || sX === visible) {
      return false;
    }
    if (sX === scroll) {
      return true;
    }
    return parseInt(s.width) < el.scrollWidth;
  } else if (axis === 'y') {
    if (sY === hidden || sY === visible) {
      return false;
    }
    if (sY === scroll) {
      return true
    };
    return parseInt(s.height) < el.scrollHeight;
  }
}

export function hasEllipsis(el: Element) {
  // They're always equal to each other on flex children, and thus, won't work for them
  // Anyways, it cover most "common" cases.
  return el.clientWidth < el.scrollWidth;
}