/** set which environment in the context to read from config */
import actionTypes from './actionTypes'

const setError = ({ error }) => dispatch => {
    dispatch({
        type: actionTypes.SET_ERROR,
        payload: {
            error
        }
    })
}
const setEnv = ({ env }) => dispatch => {
    dispatch({
        type: actionTypes.SET_ENV,
        payload: {
            env
        }
    })
}

const setLocale = ({ locale }) => dispatch => {
    dispatch({
        type: actionTypes.SET_LOCALE,
        payload: {
            locale
        }
    })
}

/** set login state in the context */

const login = ({ username, tenant, role, token, userInfo, userDetails, products }) => dispatch => {
    dispatch({
        type: actionTypes.LOGIN,
        payload: {
            username,
            tenant,
            token,
            role,
            userInfo,
            userDetails,
            products
        }
    })
}

/** set logout state in the context */
const logout = () => dispatch => {
    dispatch({
        type: actionTypes.LOGOUT,
        payload: {}
    })
}

const setEnableTeamStructure = ({ enableTeamStructure }) => dispatch => {
    dispatch({
        type: actionTypes.SET_ENABLE_TEAM_STRUCTURE,
        payload: {
            enableTeamStructure
        }
    })
}

export { login, logout, setEnv, setLocale, setEnableTeamStructure, setError }
