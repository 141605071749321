import React, { forwardRef, useImperativeHandle } from 'react';

import { ITooltipParams } from 'ag-grid-community';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { getRefElement, hasEllipsis } from '../utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.colors.greenGray[800],
      borderRadius: theme.spacing(1),
      color: theme.palette.common.white,
      overflow: 'hidden',
      padding: theme.spacing(1),
      pointerEvents: 'none',
      transition: 'opacity 1s',
      '& > *': {
        color: theme.palette.common.white,
      },
    },
    content: {
      whiteSpace: 'pre',
    },
  }),
);

function findCell(props: ITooltipParams) {
  const { api, column, rowIndex } = props;
  const colId = column.getColId();
  const eBodyViewport = getRefElement(api, 'eBodyViewport');
  if (!eBodyViewport) return;

  const rows = eBodyViewport.querySelectorAll(`div[row-index="${rowIndex}"]`);
  if (rows && rows.length > 0) {
    for (let i = 0; i < rows.length; i++) {
      const cell = rows[i].querySelector(`div[col-id="${colId}"]`);
      if (cell) {
        return cell;
      }
    }
  }
}

function isTruncated(props: ITooltipParams) {
  const cell = findCell(props);
  if (cell) {
    return hasEllipsis(cell);
  }
}

function isHeader(props: ITooltipParams) {
  return props.rowIndex === undefined;
}

export default forwardRef((props: ITooltipParams, ref) => {
  const classes = useStyles();

  // Not working properly in V22, please re-visit this after upgrade Ag-Grid
  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ['custom-tooltip'];
      },
    };
  });

  const { render, showOnlyWhenTruncated } = props as any;
  if (!isHeader(props) && showOnlyWhenTruncated) {
    if (!isTruncated(props)) {
      return null;
    }
  }

  return (
    <div className={clsx(classes.root, 'custom-tooltip')}>
      {render ? render(props) : <Typography className={classes.content}>{props.value}</Typography>}
    </div>
  );
});
