/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { Router, useParams, Route, Switch, Redirect } from 'react-router-dom'
import React, { Fragment, useContext, useEffect } from 'react'
import { createHashHistory } from 'history'
import Login from './components/login/Login'
import Home from './components/home/Home'
import Loan from './components/loan/Loan'
import AccountMaintenance from './components/accountMaintenance/AccountMaintenance'
import AuthedRoute from './auth/AuthedRoute'
import endpoints from './configs/endpoints'
import { AuthContext } from './auth/AuthContextProvider'
import { setEnableTeamStructure } from './auth/authActions'
import localize from './utils/localize'
import RoutePaths from './configs/RoutePaths'

const queryString = require('query-string')

const history = createHashHistory()

const getLocale = authState => {
    const { locale: urlLocale } = queryString.parse(location.search)
    const { locale: stateLocale } = authState
    let locale
    if (stateLocale) {
        locale = stateLocale
    } else if (urlLocale) {
        locale = urlLocale
    } else {
        locale = 'en'
    }
    return locale
}

const getEnableTeamStructure = () => (window._env_.isWebpack ? true : window._env_.ENABLE_TEAM_STRUCTURE === 'true')

const Landing = props => {
    const { authState, dispatch } = useContext(AuthContext)

    const locale = getLocale(authState)
    localize.init(endpoints.tenant, locale)

    /** load url params on startup */
    useEffect(() => {
        setEnableTeamStructure({ enableTeamStructure: getEnableTeamStructure() })(dispatch)
    }, [])

    return (
        <>
            <Router history={history}>
                <Switch>
                    <Route exact path={RoutePaths.ROOT_PATH} component={Login} />
                    <Route exact path={RoutePaths.LOGIN_PATH} component={Login} />
                    <AuthedRoute exact path={RoutePaths.HOME_PATH} component={Home} />
                    <AuthedRoute exact path={RoutePaths.LOAN_PATH} component={Loan} />
                    <AuthedRoute exact path={RoutePaths.ACCOUNT_MAINTENANCE} component={AccountMaintenance} />
                    <Redirect to={RoutePaths.ROOT_PATH} />
                </Switch>
            </Router>
        </>
    )
}

export default Landing
