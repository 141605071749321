import React from 'react';

import { GridApi } from 'ag-grid-community';
import isFunction from 'lodash/isFunction';

const NoRowOverlay = ({ api, noRowsMessage }: { api: GridApi; noRowsMessage: string | ((api: GridApi) => string) }) => {
  let message = isFunction(noRowsMessage) ? noRowsMessage(api) : noRowsMessage;
  return <div className="ag-overlay-loading-center">{message}</div>;
};

export default NoRowOverlay;
