import localize from "../../utils/localize";

export const values = Object.freeze({
    QUICK_CASH: "QUICK_CASH",
    EPP: "EPP",
    EPP2: "EPP2",
    PERSONAL_LOAN: "PERSONAL_LOAN"
    // BAL_A_CHECK: "BAL_A_CHECK",
    // PERSONAL_LOAN: "PERSONAL_LOAN"
    }
);

const ProductType = new Map([
    [values.QUICK_CASH, ()=> localize.strings.QuickCash],
    [values.EPP, ()=> localize.strings.EPP],
    [values.EPP2, () => localize.strings.EPP2],
    [values.PERSONAL_LOAN, ()=> localize.strings.PersonalLoan]
]);

export default ProductType;

