import merge from 'lodash/merge';

export function mergeOptionalProps<TProps>(inputProps: TProps | undefined, defaultProps: TProps): TProps {
  if (inputProps === undefined || inputProps === null) {
    return merge({}, defaultProps);
  }

  return merge({}, defaultProps, inputProps);
}

export const nameOf = <T>(name: Extract<keyof T, string>): string => name;
