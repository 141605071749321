import React, { forwardRef, useImperativeHandle, useEffect, useState, FC } from 'react';

import NumberFormat, { NumberFormatValues } from 'react-number-format';

import { enhanceCellEditorProps, CellEditorProps } from './CellEditor';

const NumberCellEditor: FC<CellEditorProps> = forwardRef((params, ref) => {
  const { componentProps, getStartValue, formatEndValue } = enhanceCellEditorProps(params);

  const [inputElement, setInputElement] = useState<HTMLInputElement>();
  const [inputtedValue, setInputtedValue] = useState(getStartValue());

  function handleOnChange(values: NumberFormatValues) {
    setInputtedValue(values.floatValue);
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => formatEndValue(inputtedValue),
    };
  });

  useEffect(() => {
    if (inputElement) {
      // https://github.com/facebook/react/issues/7835#issuecomment-395504863
      setTimeout(() => {
        inputElement.focus();
        inputElement.setSelectionRange(0, inputElement.value.length);
      }, 10);
    }
  }, [inputElement]);

  return (
    <div className="ag-input-wrapper" role="presentation">
      <NumberFormat
        thousandSeparator={true}
        {...componentProps}
        className="ag-cell-edit-input"
        getInputRef={(el: HTMLInputElement) => setInputElement(el)}
        onValueChange={handleOnChange}
        value={inputtedValue}
      />
    </div>
  );
});

export default NumberCellEditor;
