import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme =>
    createStyles({
        wrapper: {
            '&&&': {
                position: 'relative',
                pointerEvents: props => (props.visible ? 'none' : 'inherit')
            }
        },

        loading: {
            '&&&': {
                zIndex: theme.zIndex.tooltip,
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: props => `-${props.size / 2}px`,
                marginLeft: props => `-${props.size / 2}px`
            }
        },

        mask: {
            '&&&': {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: theme.colors.priveGreen[0],
                opacity: 0.5
            }
        }
    })
)

const Loading = props => {
    const { visible, children, size } = props
    const classes = useStyles(props)

    return (
        <div className={classes.wrapper}>
            {visible && <CircularProgress size={size} className={classes.loading} />}
            {children}
        </div>
    )
}

export default Loading
