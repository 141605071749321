import lightLogo from "../../assets/img/Logo/White.svg";
import textToSpeech from "../../assets/img/sidebar/text-to-speech.png";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import RoutePaths from "../../configs/RoutePaths";
import localize from "../../utils/localize";

const Sidebar = () => (
    <aside className="nav-sidebar">
        <div className="brand">
            {/*<a href="general-page.html">*/}
                <img className="light-logo" src={lightLogo}/>
            {/*</a>*/}
        </div>
        <ul>
            <li>
                <Link to={RoutePaths.HOME_PATH}><img src={textToSpeech}/><span>{localize.strings.TextToSpeech}</span></Link>
            </li>
        </ul>
    </aside>
)

export default Sidebar;
