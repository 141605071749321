import React, { forwardRef, useImperativeHandle, useEffect, useState, ChangeEvent, FC } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { enhanceCellEditorProps, CellEditorProps } from './CellEditor';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      paddingLeft: theme.spacing(1),
    },
    select: {
      appearance: 'none',
    },
  }),
);

function generateMapOption(values: Map<any, any>) {
  const options = [];
  for (let [key, value] of values) {
    options.push(
      <option key={key} value={key}>
        {value}
      </option>,
    );
  }
  return options;
}

const SelectCellEditor: FC<CellEditorProps> = forwardRef((params, ref) => {
  const { api, componentProps, getStartValue, formatEndValue } = enhanceCellEditorProps(params);

  const classes = useStyles();
  const [selected, setSelected] = useState(false);
  const [selectedValue, setSelectedValue] = useState(getStartValue());
  const values = componentProps.values || [];

  function handleOnChange(event: ChangeEvent<HTMLSelectElement>) {
    setSelectedValue(event.target.value);
    setSelected(true);
  }

  const options =
    values instanceof Map
      ? generateMapOption(values)
      : values.map((o: any) => (
          <option key={o} value={o}>
            {o}
          </option>
        ));

  useImperativeHandle(ref, () => {
    return {
      getValue: () => formatEndValue(selectedValue),
    };
  });

  useEffect(() => {
    if (selected) {
      api && api.stopEditing();
    }
  }, [api, selected]);

  return (
    <div className={clsx(classes.root, 'ag-cell-edit-input')}>
      <select className={clsx(classes.select, 'ag-cell-edit-input')} value={selectedValue} onChange={handleOnChange}>
        {options}
      </select>
    </div>
  );
});

export default SelectCellEditor;
