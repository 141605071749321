import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';

// avoid default i18next as it may crash with existing instances (e.g. defaultNamespace)
// avoid initReactI18next, which is a global e.g. could cause problem on conflicting namespace
// target is to get other modules add resources /w namepsace to this instance and share on i18n instance
const i18n = i18next.createInstance();
const i18nAsync = i18n.use(LanguageDetector).init(
  {
    resources: {
      en,
    },
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  },
  (err) => {
    console.debug('Failed to init i18n', err);
  },
);

export { i18n, i18nAsync };
