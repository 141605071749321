import React, { FC } from 'react';

import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

import Pagination, { PaginationProps } from '../Components/Pagination';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      '& .MuiIconButton-root': {
        padding: theme.spacing(1),
      },
    },
    button: {},
    text: {
      marginRight: theme.spacing(1),
      paddingBottom: theme.spacing(1, 2),
    },
  });

export type PaginationStatusBarComponentProps = PaginationProps;

const PaginationStatusBarComponent: FC<PaginationStatusBarComponentProps> = (props) => {
  return <Pagination {...props} />;
};

export default withStyles(styles)(PaginationStatusBarComponent);
