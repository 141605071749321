import React, { useCallback, useEffect, useState, FC } from 'react';

import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { GridApi } from 'ag-grid-community';

export function defaultTextFormatter(
  isLastPageFound: boolean,
  pageSize: number,
  currentPageIndex: number,
  totalPages: number,
  rowCount: number,
): string {
  const total = isLastPageFound ? rowCount : '?';
  const from = rowCount ? currentPageIndex * pageSize + 1 : 0;
  const to = Math.min((currentPageIndex + 1) * pageSize, rowCount);
  return `${from} - ${to} of ${total}`;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      flexWrap: 'nowrap',
      lineHeight: '30px',
      width: 'auto',
      '& .MuiIconButton-root': {
        fontSize: 20,
      },
    },
    button: {
      padding: theme.spacing(0, 0.5),
    },
    text: {
      fontWeight: theme.typography.fontWeightRegular,
      padding: theme.spacing(0, 1),
    },
  });

export type PaginationTextFormatter = (
  isLastPageFound: boolean,
  pageSize: number,
  currentPageIndex: number,
  totalPages: number,
  rowCount: number,
) => string;

export type PaginationProps = {
  api: GridApi;
  textFormatter: PaginationTextFormatter;
} & WithStyles<typeof styles>;

const Pagination: FC<PaginationProps> = ({ api, classes, textFormatter }) => {
  const [paginationText, setPaginationText] = useState('');
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);

  const handlePaginationChanged = useCallback(() => {
    setHasNextPage(api.paginationGetCurrentPage() + 1 < api.paginationGetTotalPages());
    setHasPreviousPage(api.paginationGetCurrentPage() !== 0);
    setPaginationText(
      textFormatter(
        api.paginationIsLastPageFound(),
        api.paginationGetPageSize(),
        api.paginationGetCurrentPage(),
        api.paginationGetTotalPages(),
        api.paginationGetRowCount(),
      ),
    );
  }, [api, setHasNextPage, setHasPreviousPage, setPaginationText, textFormatter]);

  useEffect(() => {
    // Run the first round
    handlePaginationChanged();
    // Listen for upcoming change event
    api.addEventListener('paginationChanged', handlePaginationChanged);
    return () => {
      api.removeEventListener('paginationChanged', handlePaginationChanged);
    };
  }, [api, handlePaginationChanged]);

  return (
    <Grid className={classes.root} container justify="flex-end">
      <div className={classes.text}>{paginationText}</div>
      <IconButton
        className={classes.button}
        disabled={!hasPreviousPage}
        onClick={() => api.paginationGoToPreviousPage()}
      >
        <ChevronLeftIcon fontSize="inherit" />
      </IconButton>
      <IconButton className={classes.button} disabled={!hasNextPage} onClick={() => api.paginationGoToNextPage()}>
        <ChevronRightIcon fontSize="inherit" />
      </IconButton>
    </Grid>
  );
};

Pagination.defaultProps = {
  textFormatter: defaultTextFormatter,
};

export default withStyles(styles)(Pagination);
