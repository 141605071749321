import ListItemText from '@material-ui/core/ListItemText';
import colors from 'styles/colors';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      color: colors.greenGray[700],
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
  }),
)(ListItemText);
