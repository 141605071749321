import isArray from 'lodash/isArray';

export function toHighchartCssUnit(value: any): string | undefined {
  if (value === undefined) return value;

  if (isNaN(value)) return value;

  return `${value}px`;
}

export function replaceIfArray(_: any, srcValue: any): any {
  if (isArray(srcValue)) {
    return srcValue;
  }
}

export const DefaultdateTimeLabelFormats: Highcharts.AxisDateTimeLabelFormatsOptions = {
  millisecond: '%H:%M:%S.%L',
  second: '%H:%M:%S',
  minute: '%H:%M',
  hour: '%H:%M',
  day: '%e. %b',
  week: '%e. %b',
  month: '%b %Y',
  year: '%Y',
};