import { Tabs } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },

    vertical: {
      '& $indicator': {
        width: 4,
        left: 0,
      },
    },
  }),
)(Tabs);
