import React, { FC } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import isNil from 'lodash/isNil';

import CellRenderer, { CellRendererProps } from './CellRenderer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

const LinkCellRenderer: FC<CellRendererProps> = (props) => {
  const { context, value, valueFormatted } = props;
  const classes = useStyles();
  const onClickCallback = props.onClick || (context && context.onLinkCellClick);

  const displayValue = valueFormatted === null ? value : valueFormatted;

  const component = (props: any) => (
    <Link classes={{ root: classes.root }} color="initial" {...props}>
      {displayValue}
    </Link>
  );

  return isNil(displayValue) ? <></> : <CellRenderer {...props} component={component} onClick={onClickCallback} />;
};

export default LinkCellRenderer;
