import React, { forwardRef } from 'react';

import { createStyles, withStyles, useTheme, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Highcharts from 'highcharts/highstock';
import mergeWith from 'lodash/mergeWith';

import ChartBase, { ChartBaseRef, ChartCommonProps } from './ChartBase';
import { enforceOptions as enforceNavigatorOptions, StockBaseNavigatorOptions } from './StockBaseNavigator';
import { enforceOptions as enforceRangeSelectorOptions, StockBaseRangeSelectorOptions } from './StockBaseRangeSelector';
import { replaceIfArray } from './utils';

const styles = () =>
  createStyles({
    root: {},
  });

export interface StockBaseCustomOptions {
  /**
   * Custom Range Selector options
   */
  rangeSelector?: StockBaseRangeSelectorOptions;
  /**
   * Custom Navigator options
   */
  navigator?: StockBaseNavigatorOptions;
}

export interface StockBaseRef extends ChartBaseRef {}

export interface StockCommonProps extends ChartCommonProps {
  /**
   * Custom features to be applied by default. Please do customization youself with Highchart `options`, if the custom feature doesn't fullfil your requirement.
   */
  custom?: StockBaseCustomOptions;
}

export interface StockBaseProps extends StockCommonProps {}

function enforceDefaultOptions(
  theme: Theme,
  options?: Highcharts.Options,
  custom?: StockBaseCustomOptions,
): Highcharts.Options {
  const defaultOptions: Highcharts.Options = {};

  enforceRangeSelectorOptions(theme, defaultOptions, custom?.rangeSelector);
  enforceNavigatorOptions(theme, defaultOptions, custom?.navigator);

  return mergeWith(defaultOptions, options, replaceIfArray);
}

const StockBase = forwardRef<StockBaseRef, StockBaseProps>(
  ({ classes, containerProps, custom, defaultOptions, highcharts, ...otherProps }, ref) => {
    const theme = useTheme();

    const myDefaultOptions = enforceDefaultOptions(theme, defaultOptions, custom);

    return (
      <ChartBase
        ref={ref}
        constructorType="stockChart"
        containerProps={{
          ...containerProps,
          className: clsx(classes.root, containerProps?.className),
        }}
        defaultOptions={myDefaultOptions}
        highcharts={highcharts || Highcharts}
        {...otherProps}
      />
    );
  },
);

export default withStyles(styles, { name: 'StockBase' })(StockBase);
