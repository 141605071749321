import React, { FC } from 'react';

import { CellRendererProps } from './CellRenderer';
import NumberCellRenderer from './NumberCellRenderer';

const DefaultComponetProps = {
  decimalScale: 2,
  fixedDecimalScale: true,
  prefix: '$',
};

const CurrencyCellRenderer: FC<CellRendererProps> = props => {
  const { context } = props;
  const onClickCallback = props.onClick || (context && context.onCurrencyCellClick);

  const componentProps =
    props.componentProps === undefined
      ? DefaultComponetProps
      : {
          ...DefaultComponetProps,
          ...props.componentProps,
        };

  const extendedProps = {
    ...props,
    componentProps,
    onClick: onClickCallback,
  };

  return <NumberCellRenderer {...extendedProps} />;
};

export default CurrencyCellRenderer;
