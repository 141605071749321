/**
 * Internal state of wizard
 */
enum State {
  /**
   * Incomplete config, context or currentStep
   */
  New,
  /**
   * Known context and currentStep
   */
  Loaded,
  /**
   * Widget loaded and processsing, navigation is blocked
   */
  Validating,
  /**
   * Syncing with server side, navigation is block
   */
  Flushing,
  /**
   * The wizard cannot load correctly. (e.g. missing wizard or sync error)
   */
  Error,
}
export default State;
