/* eslint-disable no-unused-vars */
import React from 'react'
import logo from '../../assets/img/citibank/logo.png'
import Nav from '../common/Nav'
import { AuthContext } from '../../auth/AuthContextProvider'
import DownloadAudit from './DownloadAudit'
import AccountMaintenance from './AccountMaintenance'
import CreateTTS from './CreateTTS'

const Home = () => {
    const { authState } = React.useContext(AuthContext)
    return (
        <div className="wrapper tts">
            <div className="nav-sidebar" />
            <div className="content-wrapper">
                <Nav />
                <section className="content container-fluid tts-login">
                    <div className="row-section flex-center">
                        <img src={logo} alt="Not Found" />
                    </div>
                    <div className="row-section flex-center login-section">
                        {/* <h3>{ROLE[activeRole]()}</h3> */}
                        <br />

                        <AccountMaintenance role={authState.authInfo.role.code} token={authState.authInfo.token} />

                        <DownloadAudit role={authState.authInfo.role.code} />

                        <CreateTTS role={authState.authInfo.role.code} />
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Home
