import { Theme } from '@material-ui/core/styles';
import Highcharts from 'highcharts/highstock';
import mergeWith from 'lodash/mergeWith';

import { replaceIfArray } from './utils';

export interface StockBaseNavigatorOptions {
  /**
   * Enable or disable the Custom Navigator (Default: false)
   */
  enabled: boolean;
  /**
   * Navigator options
   */
  options?: Highcharts.NavigatorOptions;
  /**
   * Show / hide the preview chart in navigator (Default: false)
   * 
   * Use `plotOptions.series.showInNavigator` if you want to show/hide the preview dynamicly.
   */
  previewHided: boolean;
}

export function buildOptions(
  theme: Theme,
  options: StockBaseNavigatorOptions,
): Highcharts.NavigatorOptions {
  const baseOptions: Highcharts.NavigatorOptions = {
    handles: {
      borderColor: 'rgba(200, 200, 200, 0.5)',
      backgroundColor: theme.colors.white,
      lineWidth: 1,
    },
    outlineColor: theme.colors.gray[500],
    maskFill: 'rgba(191, 212, 223, 0.5)',
  };
  if (options?.previewHided) {
    baseOptions.series = {
      fillOpacity: 0,
      lineWidth: 0,
    };
  }
  return baseOptions;
};

export function enforceOptions(theme: Theme, options: Highcharts.Options, navigatorOptions?: StockBaseNavigatorOptions) {
  if (navigatorOptions?.enabled) {
    const navigator = buildOptions(theme, navigatorOptions);
    if (navigatorOptions.options) {
      mergeWith(navigator, navigatorOptions.options, replaceIfArray);
    }
    options.navigator = navigator;
  } else {
    options.navigator = {
      enabled: false,
    };
  }
}
