import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { createStyles, withStyles, useTheme, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import mergeWith from 'lodash/mergeWith';

import ColorScheme from './ColorScheme';
import { replaceIfArray, DefaultdateTimeLabelFormats } from './utils';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      '& g.highcharts-button': {
        fontWeight: theme.typography.button.fontWeight,
        fontFamily: theme.typography.button.fontFamily,
        fontSize: theme.typography.button.fontSize,
      },
    },
  });

export interface ChartBaseRef {
  /**
   * Chart reference
   */
  chart: Highcharts.Chart;
  /**
   * Container reference
   */
  container: React.RefObject<HTMLDivElement>;
  /**
   * Refresh the chart with updated default options.
   */
  refresh: () => void;
}

export interface ChartCommonProps {
  /**
   * Classes style.
   */
  classes: Record<'root', string>;
  /**
   * Flag for `Chart.update` call (Default: true)
   */
  allowChartUpdate?: boolean;
  /**
   * Properties of the chart container
   */
  containerProps?: { [key: string]: any };
  /**
   * Highcharts options which you think you won't change in runtime.
   */
  defaultOptions?: Highcharts.Options;
  /**
   * Highcharts namespace
   */
  highcharts?: typeof Highcharts;
  /**
   * Immutably recreates the chart on receiving new props
   */
  immutable?: boolean;
  /**
   * Highcharts options
   */
  options?: Highcharts.Options;
  /**
   * Flags for `Chart.update` call: redraw, oneToOne, and animation. (Default:
   * [true, true, true])
   */
  updateArgs?: [boolean] | [boolean, boolean] | [boolean, boolean, boolean];
  /**
   * Callback for the chart factory
   */
  callback?: Highcharts.ChartCallbackFunction;
}

export interface ChartBaseProps extends ChartCommonProps {
  /**
   * Reference to the chart factory (Default: chart)
   */
  constructorType?: keyof typeof Highcharts;
}

function enforceDefaultOptions(theme: Theme, options?: Highcharts.Options): Highcharts.Options {
  const defaultOptions: Highcharts.Options = {
    colors: ColorScheme(theme),
    title: undefined,
    // Give default value to single axis only
    xAxis: {
      dateTimeLabelFormats: DefaultdateTimeLabelFormats,
      title: undefined,
      labels: {
        style: {
          color: theme.colors.greenGray[700],
        },
      },
    },
    yAxis: {
      dateTimeLabelFormats: DefaultdateTimeLabelFormats,
      title: undefined,
      labels: {
        style: {
          color: theme.colors.greenGray[700],
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  return mergeWith(defaultOptions, options, replaceIfArray);
}

const ChartBase = forwardRef<ChartBaseRef, ChartBaseProps>(
  ({ classes, containerProps, defaultOptions, highcharts, options, ...otherProps }, ref) => {
    const theme = useTheme();

    const chartRef = useRef<{
      chart: Highcharts.Chart;
      container: React.RefObject<HTMLDivElement>;
    }>(null);

    const [myOptions, setMyOptions] = useState(enforceDefaultOptions(theme, defaultOptions));

    useEffect(() => {
      if (options) {
        setMyOptions(options);
      }
    }, [options]);

    useImperativeHandle(
      ref,
      () => ({
        get chart() {
          return chartRef.current!.chart;
        },
        get container() {
          return chartRef.current!.container;
        },
        refresh: () => {
          setMyOptions(mergeWith(enforceDefaultOptions(theme, defaultOptions), options, replaceIfArray));
        },
      }),
      [defaultOptions, options, theme],
    );

    return (
      <HighchartsReact
        ref={chartRef}
        containerProps={{
          ...containerProps,
          className: clsx(classes.root, containerProps?.className),
        }}
        highcharts={highcharts || Highcharts}
        options={myOptions}
        {...otherProps}
      />
    );
  },
);

export default withStyles(styles, { name: 'ChartBase' })(ChartBase);
