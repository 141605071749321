/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import * as R from 'ramda'
import { AuthContext } from '../../auth/AuthContextProvider'
import localize from '../../utils/localize'
import ResultSection from '../common/ResultSection'
import DialInSection from '../common/DialInSection'
import Nav from '../common/Nav'
import Sidebar from '../common/Sidebar'
import Header from '../common/Header'
import QuickCashForm, {
    calcFields as QuickCashFormCalcFields,
    savedFields as QuickCashFormSavedFields
} from './QuickCashForm'
import { debounce } from '../../utils/CommonUtils'
import EPPForm, { calcFields as EPPFormCalcFields, savedFields as EPPFormSavedFields } from './EPPForm'
import EPP2Form, { calcFields as EPP2FormCalcFields, savedFields as EPP2FormSavedFields } from './EPP2Form'
import PILForm, { calcFields as PILFormcalcFields, savedFields as PILFormSavedFields } from './PILForm'
import BACForm, { calcFields as BACFormcalcFields, savedFields as BACFormSavedFields } from './BACForm'
import SelectionForm, { PRODUCT_TYPE_OPTIONS } from './SelectionForm'
import LoanService from '../../services/LoanService'

const DEBOUNCE_TIME = 300

const FORM_TITLES = {
    QUICK_CASH: () => localize.strings.QuickCash,
    EPP: () => localize.strings.EPP,
    EPP2: () => localize.strings.EPP2,
    PERSONAL_LOAN: () => localize.strings.PersonalLoan,
    BAL_A_CHECK: () => localize.strings.BalACheck
}

const CALC_FIELDS = {
    QUICK_CASH: QuickCashFormCalcFields,
    EPP: EPPFormCalcFields,
    EPP2: EPP2FormCalcFields,
    PERSONAL_LOAN: PILFormcalcFields,
    BAL_A_CHECK: BACFormcalcFields
}

const SAVED_FIELDS = {
    QUICK_CASH: QuickCashFormSavedFields,
    EPP: EPPFormSavedFields,
    EPP2: EPP2FormSavedFields,
    PERSONAL_LOAN: PILFormSavedFields,
    BAL_A_CHECK: BACFormSavedFields
}

const renderProductForm = (productType, onSubmit) => {
    if (productType === 'QUICK_CASH') return <QuickCashForm onSubmit={onSubmit} />
    if (productType === 'EPP') return <EPPForm onSubmit={onSubmit} />
    if (productType === 'EPP2') return <EPP2Form onSubmit={onSubmit} />
    if (productType === 'PERSONAL_LOAN') return <PILForm onSubmit={onSubmit} />
    if (productType === 'BAL_A_CHECK') return <BACForm onSubmit={onSubmit} />
    return null
}

const Loan = () => {
    const { authState } = React.useContext(AuthContext)
    const { products } = authState.authInfo
    const [isSaved, setIsSaved] = useState(false)
    const [calcResult, setCalcResult] = useState({})
    const [saveResult, setSaveResult] = useState({})
    const [formData, setFormData] = useState({})
    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange'
    })
    const { handleSubmit, errors, control, getValues, watch, clearErrors, formState, reset, setValue } = methods

    const productType =
        watch('productType') ||
        PRODUCT_TYPE_OPTIONS.filter(i => Object.keys(products).indexOf(i.value) !== -1)?.[0]?.value ||
        []

    useEffect(() => {
        clearErrors()
    }, [authState.locale])

    useEffect(() => {
        setCalcResult({})
        reset({ productType, language: getValues('language') })
    }, [productType])

    const handleBack = () => setIsSaved(false)

    const onSubmit = onlyCalc =>
        debounce(!onlyCalc, DEBOUNCE_TIME, (_data, e) => {
            let _formData
            const {
                language,
                monthlyFlatRate,
                oneTimeBookingFee,
                annualizedRate,
                availableCreditLimit,
                cycleDayOfMonth
            } = _data

            if (monthlyFlatRate)
                _formData = {
                    ..._data,
                    monthlyFlatRate: monthlyFlatRate.floatValue / 100,
                    cycleDayOfMonth: cycleDayOfMonth?.floatValue
                }

            if (oneTimeBookingFee)
                _formData = {
                    ..._data,
                    oneTimeBookingFee: oneTimeBookingFee.floatValue / 100,
                    cycleDayOfMonth: cycleDayOfMonth?.floatValue
                }

            if (annualizedRate) _formData = { ..._data, ..._formData, annualizedRate: annualizedRate.floatValue / 100 }
            if (availableCreditLimit)
                _formData = { ..._data, ..._formData, availableCreditLimit: availableCreditLimit.floatValue }

            setFormData(_formData)

            if (onlyCalc) {
                LoanService.calcForm(
                    {
                        language,
                        userInfo: authState.authInfo.userInfo,
                        productInfo: _formData
                    },
                    ({ status, data, error }) => {
                        if (status === 'success') {
                            setCalcResult({ ...data, ..._formData })
                            setFormData({ ..._formData, cycleDate: calcResult.cycleDateCalc })
                        } else if (status === 'fail') {
                            console.log(error)
                        }
                    }
                )
            } else {
                LoanService.saveForm(
                    {
                        language,
                        userInfo: authState.authInfo.userInfo,
                        productInfo: _formData
                    },
                    ({ status, data, error }) => {
                        if (status === 'success') {
                            setIsSaved(true)
                            setSaveResult(data)
                        } else if (status === 'fail') {
                            console.log(error)
                        }
                    }
                )
            }
        })()

    const handleSubmitMemo = useCallback(handleSubmit(onSubmit(false)), [])

    const renderHeaderRightPanel = () => {
        if (isSaved) {
            return (
                <button type="button" className="btn btn-default" onClick={handleBack}>
                    {localize.strings.Back}
                </button>
            )
        }
        return (
            <button type="button" className="btn btn-default" onClick={handleSubmitMemo}>
                {localize.strings.Next}
            </button>
        )
    }

    return (
        <div className="wrapper">
            <Sidebar />
            <div className="content-wrapper">
                <Nav />
                <div className="boxes">
                    <Header title={localize.strings.CreateTTS} rightpanel={renderHeaderRightPanel} />
                    <section className="content container-fluid">
                        <div className="row-section flex-center tts-forms">
                            <div style={{ display: isSaved ? 'block' : 'none' }}>
                                <DialInSection data={saveResult} />
                                <ResultSection
                                    title={FORM_TITLES[productType]}
                                    fields={SAVED_FIELDS[productType] || []}
                                    data={{ ...formData, ...calcResult }}
                                />
                                <ResultSection
                                    title={() => localize.strings.Calculation}
                                    fields={CALC_FIELDS[productType] || []}
                                    data={calcResult}
                                />
                            </div>
                            <div style={{ display: isSaved ? 'none' : 'block' }}>
                                <FormProvider {...methods}>
                                    <form>
                                        <SelectionForm title={localize.strings.Selection} />
                                        {renderProductForm(productType, handleSubmit(onSubmit(true)))}
                                    </form>
                                </FormProvider>
                                {!R.isEmpty(calcResult) && (
                                    <ResultSection
                                        title={() => localize.strings.Calculation}
                                        fields={CALC_FIELDS[productType]}
                                        data={calcResult}
                                    />
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Loan
