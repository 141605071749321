import { useState, ReactNode, ChangeEvent, useMemo, useCallback } from 'react';
import { SelectProps } from '@material-ui/core/Select';

type ValueRenderer = (selected: string[]) => ReactNode;

type Options<V> = {
  options: Map<string, V>;
  renderValue?: ValueRenderer;
  initialValue?: string[];
  onChange?(e: ChangeEvent<{ value: string[] }>): void;
};

const useMultiple = <V>(options: Options<V>) => {
  const defaultRenderValue: ValueRenderer = useCallback(
    selected => selected.map(key => options.options.get(key)).join(', '),
    [options.options],
  );
  const { renderValue = defaultRenderValue, initialValue = [], onChange } = options;
  const [selected, setSelected] = useState<string[]>(initialValue);
  const handleChange = useCallback(
    (e: ChangeEvent<{ value: string[] }>) => {
      setSelected(e.target.value);
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );
  const selectProps = useMemo(
    () =>
      ({
        renderValue,
        value: selected,
        onChange: handleChange,
        multiple: true,
      } as SelectProps),
    [handleChange, renderValue, selected],
  );

  return {
    selected,
    selectProps,
  };
};

export default useMultiple;
