import React, { createContext, useReducer, useEffect } from 'react'
import { getItem, setItem } from '../store/localStorage'
import authReducer from './authReducer'

const initialAuthState = {
    isAuthenticated: false,
    authInfo: {},
    error: ''
}

export const AuthContext = createContext(initialAuthState)

const AuthContextProvider = props => {
    const { children } = props
    const [authState, dispatch] = useReducer(authReducer, initialAuthState, () => {
        return getItem('authState') ? getItem('authState') : {}
    })
    useEffect(() => {
        setItem('authState', authState)
    }, [authState])
    return <AuthContext.Provider value={{ authState, dispatch }}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
