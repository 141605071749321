type Fonts = {
  fontSize: number;
  fontWeightBold: number;
  fontWeightRegular: number;
  fontFamily: string;
};

export default {
  fontSize: 16,
  fontWeightBold: 700,
  fontWeightRegular: 400,
  fontFamily: "'Proxima Nova', Helvetica, sans-serif",
} as Fonts;
