/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import WatchIcon from '@material-ui/icons/WatchLater'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
    root: {
        margin: 0,
        padding: '20px',
        backgroundColor: '#00557e',
        overflow: 'hidden',
        color: '#ffffff',
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        color: '#FFFFFF',
        padding: '64px',
        backgroundColor: '#40809f',
        borderRadius: '50%',
        right: '-32px',
        top: '-32px',
        '&:hover': {
            backgroundColor: '#40809f'
        }
    }
})

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} style={{ display: 'flex' }}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
})

const DialogContent = withStyles(theme => ({
    root: {
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '20px',
        textAlign: 'center',
        color: '#3D505D',
        fontSize: '23px',
        lineHeight: '30px',
        height: '200px'
    }
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions)

const ErrorDialog = ({ title, message, buttonLabel, isOpen, handleClose }) => (
    <div
        style={{
            display: isOpen,
            position: 'absolute',
            left: '400px'
        }}
    >
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            style={{ width: '704px', left: '600px' }}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {title}
            </DialogTitle>
            <DialogContent>
                <WatchIcon style={{ color: 'red', fontSize: '30px' }} />
                <p style={{ color: '#3D505D', fontSize: '23px', lineHeight: '30px' }}>{message}</p>
            </DialogContent>
            <DialogActions style={{ marginTop: '0px' }}>
                <Button
                    autoFocus
                    onClick={handleClose}
                    style={{
                        color: '#FFFFFF',
                        backgroundColor: '#00557e',
                        borderRadius: '17px',
                        height: '34px,',
                        minWidth: '90px',
                        width: 'auto',
                        margin: '30px'
                    }}
                >
                    {buttonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    </div>
)

export default ErrorDialog
