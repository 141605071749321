import { format } from 'date-fns';
import { mergeDeepRight } from 'ramda';
import numeral from 'numeral';
import { DataGridColDef, GridOptions, ValueGetterParams, ICellRendererParams } from 'ag-grid-community';

/**
 * WTF: This is kind of WTF since we're handling group situation.
 */
const makeNullableValueGetter = (defaultValue: any) => (params: ValueGetterParams) => {
  if (typeof params.data === 'undefined') {
    return undefined;
  } else {
    const field = params.column.getUserProvidedColDef().field!;
    const data = typeof params.data === 'object' ? params.data[field] : params.data;

    return data === null ? defaultValue : data;
  }
};

const baseGridOptions = {
  defaultColDef: {
    autoHeight: true,
    sortable: true,
    filter: true,
    resizable: true,
    enableRowGroup: false,
  },

  rowGroupPanelShow: 'always',

  groupMultiAutoColumn: true,

  suppressAggFuncInHeader: true,

  alwaysShowVerticalScroll: true,

  animateRows: true,

  autoGroupColumnDef: {
    // automatically pinned grouped columns
    pinned: 'left',
  },

  columnTypes: {
    string: {
      valueGetter: makeNullableValueGetter('N/A'),
    },

    number: {
      enableRowGroup: false,
      numberFormat: '0,0.00',

      /**
       * Set default to `-Infinity` to preserve sortability.
       */
      valueGetter: makeNullableValueGetter(-Infinity),

      cellRenderer: (params: ICellRendererParams) => {
        const numberFormat = (params.column.getColDef() as DataGridColDef).numberFormat as string;
        const formattedValue = numeral(params.value).format(numberFormat);

        const summable = typeof params.column.getAggFunc() !== 'undefined';
        const isInGroupRow = params.node.group;

        if ((isInGroupRow && summable) || !isInGroupRow) {
          return formattedValue;
        }
      },
    },

    date: {
      /**
       * Date values are treated as number, since the API
       * responses are timestamp.
       * Set default to `-Infinity` to preserve sortability.
       */
      valueGetter: makeNullableValueGetter(-Infinity),

      cellRenderer: (params: ICellRendererParams) => {
        const isInGroupRow = params.node.group;

        const formatDate = (timestamp: number) => format(new Date(timestamp), 'dd-MMM-yyyy');

        if (!isInGroupRow) {
          const { value } = params;
          if (Number.isFinite(value)) {
            return formatDate(value);
          } else {
            return 'N/A';
          }
        } else {
          if (params.value) {
            return formatDate(+params.value);
          }
        }
      },
    },
  },
} as GridOptions;

const makeGridOptions = (options: GridOptions) => mergeDeepRight(baseGridOptions, options) as GridOptions;

export { makeGridOptions };
export default baseGridOptions;
