import React, { useState, createContext, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { AgGridReact } from 'ag-grid-react';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import * as AgGridEnterprise from 'ag-grid-enterprise';
import { makeGridOptions } from './baseGridOptions';
import { Config, StaticConfig, AgGridLicense, ServerSideDataSourceWithKeyword } from 'types/DataGrid';
import useRespectInitialOption from './useRespectInitialOption';
import agGridLicKey from './ag-grid-license-key.json';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

export type Props<T extends {}> = {
  rows: T[];
  initialOptions: Config & StaticConfig;
  emptyComponent: React.ComponentType;
  wordings: Record<'grouping', string>;
  refresh: any;
} & typeof defaultProps;

const getAgGridLicKeyConfig = () => agGridLicKey as AgGridLicense;
AgGridEnterprise.LicenseManager.setLicenseKey(getAgGridLicKeyConfig().key);

const defaultProps = Object.freeze({
  children: null as React.ReactNode,
  gridOptions: {} as GridOptions,
});

const useStyles = makeStyles<Theme, Props<{}>>(theme =>
  createStyles({
    '@global': {
      '.ag-dnd-ghost': {
        zIndex: theme.zIndex.tooltip,
      },
    },

    grid: {
      '&&&': {
        height: '100%',

        '& .ag-root-wrapper, .ag-root, .ag-header': {
          fontFamily: theme.typography.fontFamily,
          fontSize: '0.875rem',
        },

        '& .ag-root': {
          border: 'none',
          borderTop: `1px solid ${theme.colors.gray[400]}`,
        },

        '& .ag-header': {
          color: theme.colors.greenGray[700],
          backgroundColor: theme.colors.white,
          border: 'none',
          fontWeight: theme.typography.fontWeightBold,
          borderBottom: `1px solid ${theme.colors.gray[400]}`,
        },

        '& .ag-column-drop-horizontal': {
          backgroundColor: theme.colors.white,
          border: 'none',
        },

        '& .ag-column-drop-icon:before': {
          fontFamily: theme.typography.fontFamily,
          fontWeight: theme.typography.fontWeightBold,
          color: theme.colors.greenGray[700],
          content: props => `"${props.wordings.grouping}"`,
        },

        '& .ag-icon-small-right:before': {
          color: theme.colors.greenGray[500],
        },

        '& .ag-column-drop-cell': {
          height: 20,
          minHeight: 'auto',
          padding: theme.spacing(0, 0.5),
          borderRadius: theme.spacing(0.5),
          background: theme.colors.technologyBlue[0],
          color: theme.colors.technologyBlue[600],
          fontWeight: theme.typography.fontWeightBold,

          '& .ag-column-drag': {
            margin: 0,
          },

          '& .ag-icon': {
            display: 'block',

            '&:before': {
              fontWeight: theme.typography.fontWeightBold,
            },
          },

          '& .ag-icon-grip:before': {
            content: '"\\F11F"',
            color: theme.colors.technologyBlue[200],
          },

          '& .ag-icon-cancel:before': {
            content: '"\\F10E"',
            color: theme.colors.technologyBlue[600],
            fontSize: '1.5rem',
            position: 'relative',
            right: theme.spacing(-1),
          },
        },

        '& .ag-row-group': {
          backgroundColor: theme.colors.gray[100],
          color: theme.colors.greenGray[700],
        },

        '& .ag-cell': {
          color: theme.colors.black,
          padding: theme.spacing(2, 1),
          lineHeight: `${theme.spacing(2)}px`,
          borderBottom: `1px solid ${theme.colors.gray[200]}`,

          '& .ag-eew-link': {
            color: theme.colors.greenGray[700],
            textDecoration: 'none',

            '&:hover': {
              textDecoration: 'underline',
            },
          },
        },

        // this is the container where vertical scrollbar lives in
        '& .ag-body-viewport': {},

        // this is the container where horizontal scrollbar lives in
        '& .ag-body-horizontal-scroll-viewport': {},
      },
    },
  }),
);

type GridContextType = {
  gridApi: GridApi;
  columnApi: ColumnApi;
  gridOptions: GridOptions;
  serverSideDataSource: Nullable<ServerSideDataSourceWithKeyword>;
};

const GridContext = createContext<GridContextType | null>(null);

const DataGrid = React.forwardRef(
  <T extends {}>(
    props: Props<T>,
    /**
     * NOTE: the default exported declaration from 'ag-grid-react' module is wrong
     */
    ref: React.Ref<import('ag-grid-react/lib/agGridReact').AgGridReact>,
  ) => {
    const { rows, initialOptions, children, emptyComponent, gridOptions, refresh } = props;

    const [gridApi, setGridApi] = useState(null as Nullable<GridApi>);
    const [columnApi, setColumnApi] = useState(null as Nullable<ColumnApi>);

    useEffect(() => {
      if (gridApi != null) {
        gridApi.purgeServerSideCache();
      }
    }, [gridApi, refresh]);

    const classes = useStyles(props);

    useRespectInitialOption(gridApi, columnApi, props);

    const baseOptions = useMemo(() => makeGridOptions(gridOptions), [gridOptions]);
    const serverSideDataSource = gridOptions.serverSideDatasource;

    return (
      <div className={clsx(classes.grid, 'ag-theme-balham')}>
        <GridContext.Provider
          value={{
            gridApi: gridApi!,
            columnApi: columnApi!,
            gridOptions,
            serverSideDataSource: serverSideDataSource! as ServerSideDataSourceWithKeyword,
          }}
        >
          {gridApi && columnApi && children}
        </GridContext.Provider>

        {/*
         * NOTE: after `options.columnDefs` passed into this component,
         * all it should be handled by `ag-grid` imperative API then.
         */}
        <AgGridReact
          ref={ref}
          {...baseOptions}
          columnDefs={initialOptions.columnDefs}
          noRowsOverlayComponentFramework={emptyComponent}
          onModelUpdated={({ api }) => {
            const rowCount = api.getModel().getRowCount();
            rowCount === 0 ? api.showNoRowsOverlay() : api.hideOverlay();
          }}
          rowData={rows}
          onGridReady={({ api, columnApi }) => {
            setGridApi(api);
            setColumnApi(columnApi);
          }}
        />
      </div>
    );
  },
);

DataGrid.defaultProps = defaultProps;

export { GridContext, getAgGridLicKeyConfig };
export default DataGrid;
