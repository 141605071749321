import { PaginationTextFormatter } from "./Components/Pagination";

export interface DataGridPaginationProps {
  /**
   * Auto detect how many rows to be displayed base on the grid height. Default `false`.
   */
  autoPageSize?: boolean;
  /**
   * Turns on or off the built-in pagination. Default `false`.
   */
  enabled: boolean;
  /**
   * Defines how many rows to load per page. Default `10`.
   */
  pageSize?: number;
  /**
   * Formats the pagination text. Default '[from row no.] - [to row no.] of [total no. of row] '
   */
  textFormatter?: PaginationTextFormatter;
  /**
   * The locaiton of the pagination component.
   */
  location?: 'status-bar' | 'top-panel' | 'custom';
}

export const DefaultPaginationProps: DataGridPaginationProps = Object.freeze({
  autoPageSize: false,
  enabled: false,
  pageSize: 10,
  location: 'status-bar'
});

export function generatePaginationSettings(inputProps: DataGridPaginationProps) {
  if (inputProps.enabled) {
    return {
      pagination: true,
      paginationAutoPageSize: inputProps.autoPageSize,
      paginationPageSize: inputProps.pageSize,
      suppressPaginationPanel: true,
    };
  }

  return undefined;
}
