import React, { FC } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import isNil from 'lodash/isNil';

import Chip from 'Chip';
import CellRenderer, { CellRendererProps } from './CellRenderer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'auto',
      lineHeight: '16px',
      paddingBottom: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
      marginTop: -theme.spacing(0.5),
    },
  }),
);

const ChipCellRenderer: FC<CellRendererProps> = (props) => {
  const classes = useStyles();

  const { context, value, valueFormatted } = props;
  const onClickCallback = props.onClick || (context && context.onChipCellClick);

  const displayValue = valueFormatted === null ? value : valueFormatted;

  const component = (props: any) => (
    <Chip
      classes={{
        root: classes.root,
      }}
      label={displayValue}
      size="small"
      {...props}
    />
  );

  return isNil(displayValue) ? <></> : <CellRenderer {...props} component={component} onClick={onClickCallback} />;
};

export default ChipCellRenderer;
