/* eslint-disable react/no-danger */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { KeyboardDatePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem'
import { ErrorMessage } from '@hookform/error-message'
import NumberFormat from 'react-number-format'
import classnames from 'classnames'
import OptionsHOC from '../common/OptionsHOC'
import localize from '../../utils/localize'

const COLUMN_KEYS = ['loanAmount', 'annualizedRate', 'tenor', 'loanDate', 'availableCreditLimit']

const FIELDS = [
    [
        {
            label: () => localize.strings.BACLoanAmount,
            defaultValue: 0,
            key: 'loanAmount',
            rules: getValues => ({
                required: localize.strings.Required,
                validate: value => (value <= 3000000 && value >= 3000) || localize.strings.InvalidLoanAmountRangeQC
            }),
            render: errors => ({ onChange, value }) => (
                <NumberFormat
                    value={typeof value === 'number' ? value : 0}
                    thousandSeparator
                    prefix="$"
                    allowNegative={false}
                    maxLength={20}
                    decimalScale={2}
                    fixedDecimalScale
                    autoComplete="off"
                    onFocus={event => setTimeout(() => event.target.select(), 0)}
                    onValueChange={_value => onChange(_value.floatValue || _value)}
                />
            )
        },
        {
            label: () => localize.strings.AnnualRate,
            defaultValue: 0,
            key: 'annualizedRate',
            rules: getValues => ({
                required: localize.strings.Required,
                validate: value =>
                    (value.floatValue <= 40 && value.floatValue >= 1) || localize.strings.invalidMonthlyFlatRateBAC
            }),
            render: errors => ({ onChange, value }) => {
                return (
                    <NumberFormat
                        value={value?.floatValue || 0}
                        suffix="%"
                        decimalScale={2}
                        maxLength={7}
                        fixedDecimalScale
                        autoComplete="off"
                        allowLeadingZeros={false}
                        onFocus={event => setTimeout(() => event.target.select(), 0)}
                        onValueChange={onChange}
                    />
                )
            }
        }
    ],
    [
        {
            type: 'selection',
            label: () => localize.strings.BACTenor,
            defaultValue: '',
            key: 'tenor',
            rules: getValues => ({
                required: localize.strings.Required
            }),
            render: errors => ({ onChange, value }) => (
                <FormControl className="default-input">
                    <Select
                        displayEmpty
                        value={value}
                        className={classnames('input-box', { error: errors.tenor })}
                        labelId="customized-select-label"
                        id="customized-select"
                        disableUnderline
                        style={{
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            fontFamily:
                                '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif',
                            color: '#3D505D',
                            fontSize: '16px'
                        }}
                        onChange={onChange}
                    >
                        {Array(2)
                            .fill(1)
                            .map((i, index) => (
                                <MenuItem key={`tenor-${index * 6 + 6}`} value={`${index * 6 + 6}`}>
                                    {index * 6 + 6}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            )
        },
        {
            type: 'date',
            label: () => localize.strings.AssumingApprovalDate,
            defaultValue: moment().format('YYYY-MM-DD'),
            key: 'loanDate',
            rules: getValues => ({
                required: localize.strings.Required
            }),
            render: errors => _props => (
                <KeyboardDatePicker
                    {..._props}
                    value={_props.value}
                    defaultValue={_props.value}
                    className={classnames('input-box', { error: errors.loanDate })}
                    style={{ width: '368px' }}
                    id="date-picker-dialog"
                    format="yyyy-MM-dd"
                    okLabel={localize.strings.OkDatePicker}
                    cancelLabel={localize.strings.CancelDatePicker}
                    TextFieldComponent={_dateProps => <TextField {..._dateProps} disabled />}
                    error={false}
                    disablePast
                    helperText={null}
                    InputProps={{
                        disableUnderline: true
                    }}
                    onChange={_value => _props.onChange(moment(_value).format('yyyy-MM-DD'))}
                />
            )
        }
    ],
    [
        {
            label: () => localize.strings.AvailableCreditLimit,
            defaultValue: { formattedValue: '$0.00', value: '0.00', floatValue: undefined },
            key: 'availableCreditLimit',
            rules: getValues => ({
                required: localize.strings.Required,
                validate: value => {
                    const _value = value.floatValue
                    if (_value <= 3000000 && _value >= 0) return true
                    if (!_value) return localize.strings.Required
                    return localize.strings.InvalidAvailableCreditLimitRangeBAC
                }
            }),
            render: errors => ({ onChange, value }) => (
                <NumberFormat
                    value={value.floatValue}
                    thousandSeparator
                    prefix="$"
                    allowNegative={false}
                    maxLength={20}
                    decimalScale={2}
                    fixedDecimalScale
                    autoComplete="off"
                    onFocus={event => setTimeout(() => event.target.select(), 0)}
                    onValueChange={_value => onChange(_value)}
                />
            )
        }
    ]
]

export const savedFields = [
    {
        key: 'loanAmount',
        type: 'currency',
        label: () => localize.strings.BACLoanAmount,
        format: '$0,0.00'
    },
    {
        key: 'annualizedRate',
        type: 'percent',
        label: () => localize.strings.AnnualRate,
        format: '0.00%'
    },
    {
        key: 'tenor',
        label: () => localize.strings.BACTenor
    },
    {
        key: 'loanDate',
        type: 'date',
        label: () => localize.strings.AssumingApprovalDate,
        format: 'yyyy-MM-dd'
    },
    {
        key: 'availableCreditLimit',
        type: 'currency',
        label: () => localize.strings.AvailableCreditLimit,
        format: '$0,0.00'
    }
]

export const calcFields = [
    {
        key: 'totalLoanAmount',
        label: () => localize.strings.BACLoanAmount,
        format: '$0,0.00'
    },
    {
        key: 'annualizedRate',
        label: () => localize.strings.AnnualRate,
        format: '0.00%'
    },
    {
        key: 'apr',
        label: () => localize.strings.BACAPR,
        format: '0.00%'
    },
    {
        key: 'tenor',
        label: () => localize.strings.BACTenor
    },
    {
        key: 'loanDate',
        label: () => localize.strings.AssumingApprovalDate
    },
    {
        key: 'endDateLowRate',
        label: () => localize.strings.EndDateLowRatePeriod
    },
    {
        key: 'startDateNormalRate',
        label: () => localize.strings.StartDateNormalRatePeriod
    },
    {
        key: 'availableCreditLimit',
        label: () => localize.strings.AvailableCreditLimit,
        format: '$0,0.00'
    }
]

const BACForm = ({ onSubmit }) => {
    const { errors, control, getValues, trigger, watch } = useFormContext()
    const { loanAmount, annualizedRate, tenor, loanDate, availableCreditLimit } = watch(COLUMN_KEYS)

    const onSubmitMemo = useCallback(onSubmit, [])

    useEffect(() => {
        if (loanAmount && annualizedRate && tenor && loanDate) onSubmitMemo()
    }, [loanAmount, annualizedRate, tenor, loanDate, availableCreditLimit])

    return (
        <>
            <h5>{localize.strings.BalACheck}</h5>
            {FIELDS.map((row, index) => (
                <div className="row" key={`BACForm-${index}`}>
                    {row.map(item => (
                        <div className="col s6" key={item.key}>
                            <label className="input-label">{item.label()}</label>
                            <div className="row">
                                <OptionsHOC type={item.type} isError={errors[item.key]}>
                                    <Controller
                                        defaultValue={item.defaultValue}
                                        name={item.key}
                                        control={control}
                                        render={item.render(errors)}
                                        rules={item.rules(getValues, trigger)}
                                    />
                                </OptionsHOC>
                                <div style={{ wordWrap: 'break-word', color: 'red', width: '368px' }}>
                                    <label className="input-label error" style={{ textAlign: 'left', color: 'red' }}>
                                        <ErrorMessage
                                            errors={errors}
                                            name={item.key}
                                            render={({ message }) => (
                                                <div dangerouslySetInnerHTML={{ __html: message }} />
                                            )}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </>
    )
}

export default BACForm
