import { Theme } from '@material-ui/core/styles';
import Highcharts from 'highcharts/highstock';
import mergeWith from 'lodash/mergeWith';

import { replaceIfArray } from './utils';

export interface StockBaseRangeSelectorOptions {
  /**
   * Enable or disable the Custom Range Selector (Default: false)
   */
  enabled: boolean;
  /**
   * Range Selector options
   */
  options?: Highcharts.RangeSelectorOptions;
}

export function getDefaultSelectButton(options: Highcharts.RangeSelectorOptions) {
  if (options.buttons !== undefined && options.buttons.length !== 0) {
    if (options.buttons.length === 1) {
      return 0;
    } else if (options.buttons.length > 1) {
      const allIndex = options.buttons.findIndex(x => x.type === 'all');
      return allIndex === -1 ? 0 : allIndex;
    }
  }
}

export function enforceDefaultSelectButton(options: Highcharts.RangeSelectorOptions) {
  options.selected = getDefaultSelectButton(options);

  return options;
}

export function buildOptions(
  theme: Theme,
): Highcharts.RangeSelectorOptions {
  return enforceDefaultSelectButton({
    buttons: [
      {
        type: 'year',
        count: 1,
        text: '1 yr',
      },
      {
        type: 'year',
        count: 3,
        text: '3 yr',
      }, {
        type: 'year',
        count: 5,
        text: '5 yr',
      }, {
        type: 'all',
        text: 'All',
      }],
    buttonSpacing: theme.spacing(2),
    buttonTheme: {
      fill: 'none',
      style: {
        fill: theme.palette.primary.light,
      },
      states: {
        hover: {
          fill: 'none',
          style: {
            color: theme.palette.primary.light,
          },
        },
        select: {
          fill: 'none',
          style: {
            fontWeight: 'normal',
            color: theme.palette.primary.main,
          },
        },
      },
      // Bug: Crash when specify both `style.fontSize` and `width` 
      width: 'auto'
    },
    inputEnabled: false,
    labelStyle: {
      display: 'none',
    },
  });
};

export function enforceOptions(theme: Theme, options: Highcharts.Options, rangeSelectorOptions?: StockBaseRangeSelectorOptions) {
  if (rangeSelectorOptions?.enabled) {
    const rangeSelector = buildOptions(theme);
    if (rangeSelectorOptions.options) {
      mergeWith(rangeSelector, rangeSelectorOptions.options, replaceIfArray);
      enforceDefaultSelectButton(rangeSelector);
    }
    options.rangeSelector = rangeSelector;
  } else {
    options.rangeSelector = {
      enabled: false,
    };
  }
}
