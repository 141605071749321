import React from 'react';
import { propOr } from 'ramda';
import { ColDef, GridApi, ColumnApi } from 'ag-grid-community';

import { Dialog, DialogActions, DialogContent, DialogTitle } from 'Dialog';
import StyledButton from 'Button';
import ColumnSelector from 'ColumnSelector';
import useCustomizeColumns from './hooks/useCustomizeColumns';

/**
 * Create mapper function to map ColDef to view model.
 */
const mapState = (colDef: ColDef) => {
  const id = colDef.field!;
  const name = colDef.headerName!;
  const checked = propOr(false, 'hide', colDef) === false;

  return {
    id,
    name,
    checked,
  };
};

const filterState = (colDef: ColDef) => propOr(true, 'showInCustomizeDialog', colDef) === true;

type Props = {
  open: boolean;
  gridApi: GridApi;
  columnApi: ColumnApi;
  onSubmit: (colDefs: ColDef[]) => void;
  onDismiss: () => void;
};

const CustomizeGridDialog = (props: Props) => {
  const { open, onSubmit, onDismiss, gridApi, columnApi } = props;
  const { colDefs, dispatch } = useCustomizeColumns(gridApi, columnApi);

  return (
    <Dialog open={open} onClose={onDismiss}>
      <DialogTitle onClose={onDismiss}>Customize Columns</DialogTitle>

      <DialogContent>
        <ColumnSelector
          columns={colDefs.filter(filterState).map(mapState)}
          columnCount={2}
          draggable={false}
          onCheckChange={(column, checked) =>
            dispatch({
              checked,
              type: 'checkChange',
              field: column.id,
            })
          }
        />
      </DialogContent>

      <DialogActions>
        <StyledButton variant="outlined" color="primary" onClick={onDismiss}>
          Cancel
        </StyledButton>

        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit(colDefs);
          }}
        >
          Customize
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default CustomizeGridDialog;
