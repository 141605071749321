/**
 * @file Support lazy load AgGrid license
 */

import { AgGridLicense } from 'types/DataGrid';
import * as AgGridEnterprise from 'ag-grid-enterprise';

import agGridLicKey from 'DataGrid/ag-grid-license-key.json';

// A flag to make sure the license initialization run only once
let initialized = false;

export function ensureLicense() {
  if (initialized) return;

  if (!AgGridEnterprise.LicenseManager['licenseKey']) {
    const getAgGridLicKeyConfig = () => agGridLicKey as AgGridLicense;
    AgGridEnterprise.LicenseManager.setLicenseKey(getAgGridLicKeyConfig().key);
  }

  initialized = true;
}
