import DateAdapter from '@date-io/date-fns';

export const DateFormat = {
  Date: 'yyyy-MM-dd',
  DateTime24: 'yyyy-MM-dd HH:mm',
  Time24: 'HH:mm',
  LongTime24: 'HH:mm:ss',
};

export const dateUtil = new DateAdapter();

function formatTimestamp(timestamp: number, formatString: string) {
  return dateUtil.format(dateUtil.date(timestamp), formatString);
}

export const dateFormatter = {
  formatCustom: formatTimestamp,
  formatDate: (timestamp: number) => formatTimestamp(timestamp, DateFormat.Date),
  formatDateTime24: (timestamp: number) => formatTimestamp(timestamp, DateFormat.DateTime24),
  formatTime24: (timestamp: number) => formatTimestamp(timestamp, DateFormat.Time24),
  formatLongTime24: (timestamp: number) => formatTimestamp(timestamp, DateFormat.LongTime24),
};
