import React from 'react';
import { makeCustomElement, RealDomWrapper } from '../webComponent';
import LazyRenderer from '../webComponent/LazyRenderer';
import * as Parsers from '../webComponent/attributeParsers';
import { SnackbarProps, SnackbarOrigin } from '@material-ui/core/Snackbar';

export default makeCustomElement<SnackbarProps, React.ComponentType<SnackbarProps>>(
  $dom => new LazyRenderer(() => import('./Snackbar'), $dom),
  {
    parsers: {
      action: Parsers.makeStringParser(),
      anchorOrigin: Parsers.makeJSONParser<SnackbarOrigin>({
        vertical: 'bottom',
        horizontal: 'left',
      }),
      autoHideDuration: Parsers.makeNumberParser(),
      //ClickAwayListenerProps?: Partial<ClickAwayListenerProps>;
      //ContentProps?: Partial<SnackbarContentProps>;
      disableWindowBlurListener: Parsers.makeBooleanParser(),
      message: Parsers.makeStringParser(),
      open: Parsers.makeBooleanParser(),
      resumeHideDuration: Parsers.makeNumberParser(),
      //TransitionComponent?: React.ComponentType<TransitionProps>;
      //transitionDuration?: TransitionProps['timeout'];
      // TransitionProps?: TransitionProps;
    },
    // list of react callback to convert to simple CustomEvent
    events: [
      'onClose',
      'onMouseEnter',
      'onMouseLeave',
      'onEnter',
      'onEntered',
      'onEntering',
      'onExit',
      'onExited',
      'onExiting',
    ],
    children: {
      'prive-ui-snackbar-message': ($ele, props) => {
        props.message = React.createElement(RealDomWrapper, { $ele });
        return undefined;
      },
      'prive-ui-snackbar-action': ($ele, props) => {
        props.action = React.createElement(RealDomWrapper, { $ele });
        return undefined;
      },
    },
  },
);
