import React, { FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme, Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

import ColorScheme from '../ColorScheme';
import ChartProps from '../ChartProps';
import { SeriesData } from './DumbbellType';

export const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

export interface PercentageBarChartProps extends ChartProps<SeriesData> {}

export const PercentageBarChart: FC<PercentageBarChartProps & WithStyles<typeof styles>> = ({
  classes,
  chartOptions,
  data,
}) => {
  const theme = useTheme();

  const { series, ...extOptions } = chartOptions || {};
  const barOptions: Highcharts.SeriesBarOptions = {
    type: 'bar',
    data,
  };
  const options: Highcharts.Options = {
    title: undefined,
    series: [barOptions],
    chart: {
      type: 'bar',
    },
    colors: ColorScheme(theme),
    xAxis: {
      type: 'category',
      title: undefined,
      lineWidth: 0,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      title: undefined,
      gridLineWidth: 0,
      labels: {
        style: {
          color: 'rgb(255, 255, 255, 0)',
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    ...extOptions,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default withStyles(styles, { name: 'PercentageBarChart' })(PercentageBarChart);
