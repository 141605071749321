/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { ErrorMessage } from '@hookform/error-message'
import NumberFormat from 'react-number-format'
import classnames from 'classnames'
import OptionsHOC from '../common/OptionsHOC'
import localize from '../../utils/localize'
import { calcPILLoan } from '../../utils/Calc'

const COLUMN_KEYS = ['loanAmount', 'outstandingLoanAmount', 'monthlyFlatRate', 'tenor', 'extensionFee', 'accountNumber']

const FIELDS = [
    [
        {
            label: () => localize.strings.TotalLoanAmount,
            defaultValue: 0,
            key: 'loanAmount',
            rules: getValues => ({
                required: localize.strings.Required,
                validate: value => {
                    const { outstandingLoanAmount, extensionFee } = getValues(['outstandingLoanAmount', 'extensionFee'])
                    return calcPILLoan(value, outstandingLoanAmount, extensionFee)
                }
            }),
            render: errors => ({ onChange, value }) => (
                <NumberFormat
                    value={typeof value === 'number' ? value : 0}
                    thousandSeparator
                    prefix="$"
                    allowNegative={false}
                    maxLength={20}
                    decimalScale={2}
                    fixedDecimalScale
                    autoComplete="off"
                    onFocus={event => setTimeout(() => event.target.select(), 0)}
                    onValueChange={_value => onChange(_value.floatValue || 0)}
                />
            )
        },
        {
            label: () => localize.strings.OutstandingLoanAmount,
            defaultValue: 0,
            key: 'outstandingLoanAmount',
            rules: getValues => ({
                required: localize.strings.Required
            }),
            render: errors => ({ onChange, value }) => (
                <NumberFormat
                    value={typeof value === 'number' ? value : 0}
                    thousandSeparator
                    prefix="$"
                    allowNegative={false}
                    maxLength={20}
                    decimalScale={2}
                    fixedDecimalScale
                    autoComplete="off"
                    onFocus={event => setTimeout(() => event.target.select(), 0)}
                    onValueChange={_value => onChange(_value.floatValue || 0)}
                />
            )
        }
    ],
    [
        {
            type: 'selection',
            label: () => localize.strings.Tenor,
            defaultValue: '',
            key: 'tenor',
            rules: getValues => ({
                required: localize.strings.Required
            }),
            render: errors => ({ onChange, value }) => (
                <FormControl className="default-input">
                    <Select
                        displayEmpty
                        value={value}
                        className={classnames('input-box', { error: errors.tenor })}
                        labelId="customized-select-label"
                        id="customized-select"
                        disableUnderline
                        style={{
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            fontFamily:
                                '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif',
                            color: '#3D505D',
                            fontSize: '16px'
                        }}
                        onChange={onChange}
                    >
                        {Array(12)
                            .fill(1)
                            .map((i, index) => (
                                <MenuItem key={`tenor-${index * 6 + 6}`} value={`${index * 6 + 6}`}>
                                    {index * 6 + 6}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            )
        },
        {
            label: () => localize.strings.PILMonthlyFlatRate,
            defaultValue: 0.0,
            key: 'monthlyFlatRate',
            rules: getValues => ({
                required: localize.strings.Required,
                validate: value => {
                    const _value = value?.floatValue || 0
                    return _value !== 0 || localize.strings.Required
                }
            }),
            render: errors => ({ onChange, value }) => {
                return (
                    <NumberFormat
                        value={value?.floatValue || 0}
                        suffix="%"
                        decimalScale={3}
                        maxLength={14}
                        fixedDecimalScale
                        autoComplete="off"
                        allowLeadingZeros={false}
                        onFocus={event => setTimeout(() => event.target.select(), 0)}
                        onValueChange={onChange}
                    />
                )
            }
        }
    ],
    [
        {
            label: () => localize.strings.ExtensionFee,
            defaultValue: 0,
            key: 'extensionFee',
            rules: getValues => ({
                required: localize.strings.Required
            }),
            render: errors => ({ onChange, value }) => (
                <NumberFormat
                    value={typeof value === 'number' ? value : 0}
                    thousandSeparator
                    prefix="$"
                    allowNegative={false}
                    maxLength={20}
                    decimalScale={2}
                    fixedDecimalScale
                    autoComplete="off"
                    onFocus={event => setTimeout(() => event.target.select(), 0)}
                    onValueChange={_value => onChange(_value.floatValue || 0)}
                />
            )
        },
        {
            label: () => localize.strings.AccountNumber,
            defaultValue: '',
            key: 'accountNumber',
            rules: getValues => ({
                required: localize.strings.Required,
                validate: value => value.length >= 12 || localize.strings.InvalidAccountNumber
            }),
            render: errors => ({ onChange, value }) => (
                <NumberFormat
                    format="###-###-###############"
                    autoComplete="off"
                    onFocus={event => setTimeout(() => event.target.select(), 0)}
                    onValueChange={_value => onChange(_value.value || '###-###-###############')}
                />
            )
        }
    ]
]

export const savedFields = [
    {
        key: 'loanAmount',
        type: 'currency',
        label: () => localize.strings.TotalLoanAmount,
        format: '$0,0.00'
    },
    {
        key: 'monthlyFlatRate',
        type: 'percent',
        label: () => localize.strings.PILMonthlyFlatRate,
        format: '0.000%'
    },
    {
        key: 'tenor',
        label: () => localize.strings.Tenor
    },
    {
        key: 'outstandingLoanAmount',
        label: () => localize.strings.OutstandingLoanAmount,
        format: '$0,0.00'
    },
    {
        key: 'extensionFee',
        type: 'currency',
        label: () => localize.strings.ExtensionFee,
        format: '$0,0.00'
    },
    {
        key: 'accountNumber',
        label: () => localize.strings.AccountNumber,
        format: '###-###-###############',
        type: 'account'
    }
]

export const calcFields = [
    {
        key: 'disbursementAmount',
        label: () => localize.strings.DisbursementAmount,
        format: '$0,0.00'
    },
    {
        key: 'totalLoanAmount',
        label: () => localize.strings.TotalLoanAmount,
        format: '$0,0.00'
    },
    {
        key: 'apr',
        label: () => localize.strings.APR,
        format: '0.00%'
    },
    {
        key: 'monthlyInstallment',
        label: () => localize.strings.MonthlyInstallment,
        format: '$0,0.00'
    },
    {
        key: 'totalInterestPayable',
        label: () => localize.strings.TotalInterest,
        format: '$0,0.00'
    },
    {
        key: 'firstInstPrincipleAmount',
        label: () => localize.strings.FirstInstallmentPrincipalAmount,
        format: '$0,0.00'
    },
    {
        key: 'firstInstMonthlyFee',
        label: () => localize.strings.PILFirstInstallmentMonthlyFee,
        format: '$0,0.00'
    },
    {
        key: 'lastInstPrincipleAmount',
        label: () => localize.strings.LastInstallmentPrincipalAmount,
        format: '$0,0.00'
    },
    {
        key: 'lastInstMonthlyFee',
        label: () => localize.strings.PILLastInstallmentMonthlyFee,
        format: '$0,0.00'
    }
]

const PILForm = ({ onSubmit }) => {
    const { errors, control, getValues, trigger, watch } = useFormContext()

    const { loanAmount, outstandingLoanAmount, monthlyFlatRate, tenor, extensionFee, accountNumber } = watch(
        COLUMN_KEYS
    )

    const onSubmitMemo = useCallback(onSubmit, [])

    useEffect(() => {
        if (loanAmount && monthlyFlatRate && tenor && true) onSubmitMemo()
    }, [loanAmount, monthlyFlatRate, tenor, outstandingLoanAmount, extensionFee, accountNumber])

    useEffect(() => {
        if (outstandingLoanAmount && extensionFee) trigger(['loanAmount'])
    }, [outstandingLoanAmount, extensionFee])

    return (
        <>
            <h5>{localize.strings.PersonalLoan}</h5>
            {FIELDS.map((row, index) => (
                <div className="row" key={`PILForm-${index}`}>
                    {row.map(item => (
                        <div className="col s6" key={item.key}>
                            <label className="input-label">{item.label()}</label>
                            <div className="row">
                                <OptionsHOC type={item.type} isError={errors[item.key]}>
                                    <Controller
                                        defaultValue={item.defaultValue}
                                        name={item.key}
                                        control={control}
                                        render={item.render(errors)}
                                        rules={item.rules(getValues, trigger)}
                                    />
                                </OptionsHOC>
                                <div style={{ wordWrap: 'break-word', color: 'red', width: '368px' }}>
                                    <label className="input-label error" style={{ textAlign: 'left', color: 'red' }}>
                                        <ErrorMessage
                                            errors={errors}
                                            name={item.key}
                                            render={({ message }) => (
                                                <div dangerouslySetInnerHTML={{ __html: message }} />
                                            )}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </>
    )
}

export default PILForm
