/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext } from 'react'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import localize from '../../utils/localize'
import AuthService from '../../services/AuthService'
import { AuthContext } from '../../auth/AuthContextProvider'
import UserService from '../../services/UserService'
import ProductService from '../../services/ProductService'
import RoutePaths from '../../configs/RoutePaths'
import { login } from '../../auth/authActions'

const StyledForgetPassword = styled.div`
    color: var(--primary-color);
    font-size: 12px;
    cursor: pointer;
    :hover {
        opacity: 0.7;
    }
`
const StyledTitle = styled.h3`
    font-weight: bold;
    text-align: center;
`

const StyledFormDesc = styled.div`
    width: 400px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px;
`

const ROLE_ENUM = {
    ADMIN: 'CONTROL_USER',
    SUPERVISOR: 'SUPERVISOR',
    SALES_AGENT: 'SALES_AGENT'
}

const FIELDS = [
    {
        key: 'username',
        type: 'text',
        label: () => localize.strings.UserID,
        register: { require: localize.strings.Required },
        placeholder: () => localize.strings.UserIDPlaceHolder,
        errorMsg: () => localize.strings.Required,
        autoComplete: 'username'
    },
    {
        key: 'password',
        type: 'password',
        label: () => localize.strings.Password,
        register: { require: localize.strings.Required },
        placeholder: () => localize.strings.PasswordPlaceHolder,
        errorMsg: () => localize.strings.Required,
        autoComplete: 'current-password'
    }
]
const getUserInfo = data => ({
    monolithUserKey: data.monolithId,
    userId: data.username,
    userType: ROLE_ENUM[data.role.code],
    teamId: ''
})

const LoginForm = props => {
    const history = useHistory()
    const { authState, dispatch } = useContext(AuthContext)
    const { register, handleSubmit, errors } = useForm()
    const [loginFailed, setLoginFailed] = useState(false)
    const onSubmit = data => {
        AuthService.login(data)
            .then(result =>
                authState.enableTeamStructure ? UserService.getUserPermission(result) : UserService.getUser(result)
            )
            .then(result =>
                authState.enableTeamStructure
                    ? ProductService.getUserProducts(result)
                    : ProductService.getUserProductsDefaultAll(result)
            )
            .then(result => {
                login({ ...result, userInfo: getUserInfo(result) })(dispatch)
                history.push(RoutePaths.HOME_PATH)
            })
            .catch(() => {
                setLoginFailed(true)
            })
    }

    const handleForgetPassword = () => props.setStatus('forgetPassword')

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <section className="content container-fluid tts-login">
                <div className="row-section flex-center login-section">
                    <div className="row">
                        <p className="error">{loginFailed ? localize.strings.LoginFailed : ''}</p>
                    </div>
                    <StyledTitle>{localize.strings.LoginTitle}</StyledTitle>
                    <StyledFormDesc>{localize.strings.ChangePasswordNotice}</StyledFormDesc>
                    {FIELDS.map(item => (
                        <div className="row" key={item.key}>
                            <label className="input-label">{item.label()}</label>
                            <div className="default-input">
                                <div
                                    className={classnames('input-box', {
                                        error: errors[item.key]
                                    })}
                                >
                                    <input
                                        ref={register(item.register)}
                                        type={item.type}
                                        name={item.key}
                                        className="input-box"
                                        autoComplete={item.autoComplete}
                                        placeholder={item.placeholder()}
                                    />
                                </div>
                            </div>
                            <div style={{ wordWrap: 'break-word', color: 'red', width: '368px' }}>
                                <label className="input-label error" style={{ textAlign: 'left', color: 'red' }}>
                                    <ErrorMessage errors={errors} name={item.key} message={item.errorMsg()} />
                                </label>
                            </div>
                        </div>
                    ))}
                    <div className="action-btn btn-right">
                        <StyledForgetPassword onClick={handleForgetPassword}>
                            {localize.strings.ForgetPassword}
                        </StyledForgetPassword>
                        <button type="submit" disabled={false} className="btn btn-default btn-large">
                            {localize.strings.LoginButton}
                        </button>
                    </div>
                </div>
            </section>
        </form>
    )
}

export default LoginForm
