import { createMuiTheme } from '@material-ui/core/styles';
import { ZIndex } from '@material-ui/core/styles/zIndex';
import type { Theme, ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import type { Shape } from '@material-ui/core/styles/shape';
import type { SvgIconComponent } from '@material-ui/icons';
import mergeDeepRight from 'ramda/es/mergeDeepRight';

import { colors, fonts, dimensions } from 'styles';
import {
  ProgressIndicatorConfig,
  ProgressIndicatorEnum,
} from '../../StepWizard/WizardTypes/ProgressIndicatorConfiguration';

interface StepWizardConfig {
  hideTitle: boolean;
  hideCancel: boolean;
  buttonHeight?: number;
  showIconOnCancelButton?: SvgIconComponent;
  showIconOnBackButton?: SvgIconComponent;
  showIconOnNextButton?: SvgIconComponent;
  showIconOnFinishButton?: SvgIconComponent;
  buttonRowTheme?: (theme: Theme) => Theme;
}
const defaultStepWizardConfig: StepWizardConfig = {
  hideTitle: false,
  hideCancel: false,
};

declare module '@material-ui/core/styles/zIndex' {
  // custom z-index
  interface ZIndex {
    negative: number;
  }
}

declare module '@material-ui/core/styles/shape' {
  interface Shape {
    button: {
      borderRadius: number;
    };
  }
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    colors: typeof colors;
    zIndex: ZIndex;
    progressIndicator: ProgressIndicatorConfig;
    stepWizard: StepWizardConfig;
  }

  interface ThemeOptions {
    colors?: Theme['colors'];
    progressIndicator?: ProgressIndicatorConfig;
    stepWizard?: StepWizardConfig;
  }
}

const defaultShape: Shape = {
  borderRadius: 4,
  button: {
    borderRadius: 9999,
  },
};

const defaultThemeOptions = {
  progressIndicator: {
    type: ProgressIndicatorEnum.DEFAULT,
    showLastProgressBar: true,
    showDescription: false,
  },

  stepWizard: defaultStepWizardConfig,
  colors,

  spacing: dimensions.spacing,

  shape: defaultShape,
  palette: {
    primary: {
      main: colors.technologyBlue[600],
      light: colors.technologyBlue[400],
      hyperlinkColor: colors.blue[600],
    },
    secondary: {
      main: colors.priveGreen[600],
      light: colors.priveGreen[400],
    },
    action: {
      disabledBackground: colors.gray[400],
    },
  },

  typography: {
    htmlFontSize: fonts.fontSize,
    fontFamily: fonts.fontFamily,
    fontWeightBold: fonts.fontWeightBold,
    fontWeightRegular: fonts.fontWeightRegular,
  },

  zIndex: {
    negative: -1,
  },
} as ThemeOptions;

const createPriveTheme = (options: ThemeOptions = {}) => {
  // @ts-ignore
  const mergedOptions = mergeDeepRight(defaultThemeOptions, options) as ThemeOptions;

  return createMuiTheme(mergedOptions);
};

export default createPriveTheme;
