import React from 'react';
import { Grid, Box, Theme, createStyles } from '@material-ui/core';
import ProgressIndicatorItem from './ProgressIndicatorItem';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';

type ItemProps = React.ComponentProps<typeof ProgressIndicatorItem>;

type Props = {
  currentStep: number;
  children: React.ReactElement<ItemProps>[];
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    root: {
    },
  });
});

const ProgressIndicator = (props: Props) => {
  const { currentStep, children } = props;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const totalCount = React.Children.count(children);
  const styles = useStyles();

  return (
    <>
      {!isDesktop && (
        <Box mb={1} fontSize="0.875rem" color="primary.main">
          {Math.min(currentStep + 1, totalCount)}/{totalCount}
        </Box>
      )}
      <Grid container direction="row" wrap="nowrap" className={styles.root}>
        {React.Children.map(children, (child, index) => {
          const { variant: providedVariant } = child.props;
          const variant = (() => {
            if (providedVariant === 'error') {
              return 'error';
            } else {
              if (currentStep === index) {
                return 'current';
              } else {
                return currentStep > index ? 'done' : providedVariant;
              }
            }
          })();

          return React.cloneElement(child, {
            variant,
          });
        })}
      </Grid>
    </>
  );
};

export default ProgressIndicator;
