import { createStyles, withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

export default withStyles(
  createStyles({
    root: {
      "hr&": {
        height: 1,
      },
    },
  }),
)(Divider);
