import React from 'react';
import { ProgressIndicator, ProgressIndicatorItem } from '../ProgressIndicator';
import isClientSideWidget from './isClientSideWidget';
import type WizardBranchesCondition from './WizardTypes/WizardBranchesCondition';
import type WizardJounaryStepsConfiguration from './WizardTypes/WizardJounaryStepsConfiguration';
import type SharedWidgetContext from './WizardTypes/SharedWidgetContext';
import type ProgressBarProps from './WizardTypes/ProgressBarProps';
import { buildSubJourney } from './SubJourney';
import { LazyTitleAndDescripton } from './getLazyComponent';

interface Props {
  /**
   * global steps, including hideFromNavigation and server side widgets
   */
  currentStep: number;
  /**
   * whole journey config, including hideFromNavigation and server side widgets
   */
  stepsConfig: WizardJounaryStepsConfiguration;
  /**
   * branches condition for sub journey conditioning
   */
  branches: WizardBranchesCondition;
  /**
   * sharedWidgetContext for sub journey conditioning
   */
  sharedWidgetContext: SharedWidgetContext;
  /**
   * Custom widget to render
   */
  progressBarComponent?: React.FunctionComponent<ProgressBarProps>;
}
const Nav = ({ currentStep, stepsConfig, branches, sharedWidgetContext, progressBarComponent }: Props) => {
  const currentStepConfig = stepsConfig[currentStep];
  if (!isClientSideWidget(currentStepConfig)) {
    return <></>;
  }
  const subJourney = buildSubJourney(stepsConfig, currentStep, branches, sharedWidgetContext);
  const maxStep = subJourney.list.length;

  if (progressBarComponent) {
    // React/JSX need it upper-case.
    const ProgressBarComponent = progressBarComponent;
    return <ProgressBarComponent currentStep={currentStep} stepsConfig={stepsConfig} subJourney={subJourney} />;
  }

  return (
    <ProgressIndicator currentStep={subJourney.mappedCurrentStep}>
      {subJourney.list.map((config, index) => (
        <LazyTitleAndDescripton
          key={`${index}_${subJourney.mappedCurrentStep}`}
          config={config}
          variant="wait" // update runtime by ProgressIndicator
          render={(title, description, variant) => (
            <ProgressIndicatorItem
              key={`${index}_${subJourney.mappedCurrentStep}`}
              title={title}
              description={description}
              currentStep={index + 1}
              maxStep={maxStep}
              variant={variant}
            />
          )}
        />
      ))}
    </ProgressIndicator>
  );
};

export default Nav;
