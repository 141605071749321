import { mergeDeepRight } from 'ramda';

import Button from '@material-ui/core/Button';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';

// Used for correcting the styles that got overridden by monolith
const stylesReset = (theme: Theme) => ({
  root: {
    '&&&': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  containedPrimary: {
    '&&&': {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:disabled': {
        color: theme.palette.common.white,
      },
    },
  },
  containedSecondary: {
    '&&&': {
      '&:focus': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
});

export default withStyles((theme) =>
  createStyles(
    mergeDeepRight(stylesReset(theme), {
      root: {
        borderRadius: theme.shape.button.borderRadius,
        height: 40,
        textTransform: 'none' as any,
      },

      label: {
        whiteSpace: 'nowrap' as any,
      },

      sizeSmall: {
        height: 32,
      },

      sizeLarge: {
        height: 48,
      },
    }),
  ),
)(Button);
