import React, { ComponentType, FC } from 'react';

import { Column, ColumnApi, GridApi } from 'ag-grid-community';

// IHeaderCompParams is missing, declare ourselves
interface IHeaderCompParams {
  column: Column;
  displayName: string;
  enableMenu: boolean;
  showColumnMenu: () => void;
  progressSort: () => void;
  setSort: () => void;
  api: GridApi;
  columnApi: ColumnApi;
  context: any;
}

export interface HeaderRendererProps extends IHeaderCompParams {
  /**
   * Provides a component for wrapping.
   */
  component: ComponentType;
  /**
   * Properties to pass to the wrapped component.
   */
  componentProps: any;
  /**
   * Callbacks when clicking the wrapped compoent. It will not fire if the wrapped component is not clickable.
   */
  onClick?: (
    props: {
      api: GridApi;
      colId?: string;
      columnApi: ColumnApi;
    },
    e: Event,
  ) => void;
}

const HeaderRenderer: FC<HeaderRendererProps> = ({
  /* Header Renderer Params */
  api,
  column,
  columnApi,
  /* Compoent */
  component: Component,
  onClick,
}) => {
  const colDef = column.getColDef();
  const headerComponentProps = (colDef as any).headerComponentProps || {};

  function handleClick(e: Event) {
    if (headerComponentProps.clickable === false) {
      return;
    }

    if (onClick) {
      onClick(
        {
          api,
          colId: column.getColId(),
          columnApi,
        },
        e,
      );
    }

    if (headerComponentProps.onClick) {
      headerComponentProps.onClick(e);
    }
  }

  return <Component {...headerComponentProps} onClick={handleClick} />;
};

export default HeaderRenderer;
