/* eslint-disable no-case-declarations */
import actionTypes from './actionTypes'
import { setItem } from '../store/localStorage'

/** reducer for auth actions */
const authReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            const { username, tenant, token, role, userInfo, userDetails, products } = action.payload
            const authStateLogin = {
                ...state,
                isAuthenticated: true,
                authInfo: {
                    username,
                    tenant,
                    token,
                    role,
                    userInfo,
                    userDetails,
                    products
                },
                error: ''
            }
            setItem('authState', authStateLogin)
            return authStateLogin
        case actionTypes.SET_ENV:
            return {
                ...state,
                env: action.payload.env
            }
        case actionTypes.SET_LOCALE:
            return {
                ...state,
                locale: action.payload.locale
            }
        case actionTypes.SET_ENABLE_TEAM_STRUCTURE:
            return {
                ...state,
                enableTeamStructure: action.payload.enableTeamStructure
            }
        case actionTypes.SET_ERROR:
            return {
                ...state,
                error: action.payload.error
            }
        case actionTypes.LOGOUT:
            const authStateLogout = {
                ...state,
                isAuthenticated: false,
                authInfo: {},
                error: ''
            }
            setItem('authState', authStateLogout)
            return authStateLogout
        default:
            return state
    }
}

export default authReducer
