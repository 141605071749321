import React from 'react';
import { TimePicker, TimePickerProps } from '@material-ui/pickers';
import CustomTextField from './CustomTextField';

type Props = Omit<TimePickerProps, 'TextFieldComponent'>;

const CustomTimePicker = (props: Props) => {
  return <TimePicker TextFieldComponent={CustomTextField} {...props} />;
};

export default CustomTimePicker;
