import React, { FC } from 'react';
import Pagination, { PaginationTextFormatter } from '../Components/Pagination';
import { useDataGridContext } from '../DataGridContext';

export interface DataGridPaginationProps {
  textFormatter: PaginationTextFormatter;
}

const DataGridPagination: FC<DataGridPaginationProps> = ({ textFormatter }) => {
  const { gridApi } = useDataGridContext();

  return <Pagination api={gridApi} textFormatter={textFormatter} />;
};

export default DataGridPagination;
