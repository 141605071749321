import localize from '../../utils/localize'

export const values = Object.freeze({
    ADMIN: 'ADMIN',
    SUPERVISOR: 'SUPERVISOR',
    SALES_AGENT: 'SALES_AGENT'
})
const ROLE = {
    ADMIN: () => localize.strings.Admin,
    SUPERVISOR: () => localize.strings.Supervisor,
    SALES_AGENT: () => localize.strings.SalesAgent
}

export default ROLE
