import { DialogContent } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(4),
    },
  }),
)(DialogContent);
