/* eslint-disable import/prefer-default-export */
import localize from '../../utils/localize'

const getResetPasswordErrorCode = code => {
    let msg
    switch (code) {
        case 'PASSWORD_RESET_NO_EMAIL_FOUND':
        case 'PASSWORD_RESET_USERNAME_NOT_FOUND':
        case 'PASSWORD_RESET_USER_NOT_ACTIVE':
        case 'PASSWORD_RESET_NO_PREFERRED_EMAIL_FOUND':
        case 'PASSWORD_RESET_NO_ADVISOR_EMAIL':
        case 'PASSWORD_RESET_EMAIL_CANNOT_BE_GENERATED':
            msg = localize.strings.PASSWORD_RESET_NO_EMAIL_FOUND
            break
        case 'PASSWORD_RESET_VERIFICATION_CODE_ERROR':
        case 'PASSWORD_RESET_NO_USER_VERIFICATION_ACTION':
        case 'PASSWORD_RESET_REQUEST_REPEAT':
        case 'PASSWORD_RESET_TRACKING_KEY_ERROR':
            msg = localize.strings.PASSWORD_RESET_VERIFICATION_CODE_ERROR
            break
        default:
            msg = code
    }
    return msg
}

export { getResetPasswordErrorCode }
