import React, { forwardRef, Component, ComponentType, Ref } from 'react';

import { Theme, ThemeProvider } from '@material-ui/core/styles';

export default function withThemeWrapper<T, P extends Object>(
  WrappedComponent: ComponentType<T>,
  theme: Theme,
  options?: {
    injectedProps?: P;
    forwardRef?: boolean;
  },
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component.
  if (options && options.forwardRef) {
    return createComponentWrapperWithForwardRef(displayName, WrappedComponent, theme, options && options.injectedProps);
  } else {
    return createComponentWrapper(displayName, WrappedComponent, theme, options && options.injectedProps);
  }
}

function createComponentWrapper<T, P extends Object>(
  displayName: string,
  WrappedComponent: ComponentType<T>,
  theme: Theme,
  injectedProps?: P,
) {
  return class ComponentWithTheme extends Component<T> {
    public static displayName = `withThemeWrapper(${displayName})`;

    public render() {
      return (
        <ThemeProvider theme={theme}>
          <WrappedComponent {...this.props} {...injectedProps} />
        </ThemeProvider>
      );
    }
  };
}

function createComponentWrapperWithForwardRef<T, P extends Object>(
  displayName: string,
  WrappedComponent: ComponentType<T>,
  theme: Theme,
  injectedProps?: P,
) {
  type WrapperComponentInstance = typeof WrappedComponent;

  type WrapperComponentPropsWithForwardedRef = T & {
    forwardedRef: Ref<typeof WrappedComponent>;
  };

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  class ComponentWithThemeAndForwardRef extends Component<WrapperComponentPropsWithForwardedRef> {
    public static displayName = `withThemeWrapperAndForwardRef(${displayName})`;

    public render() {
      return (
        <ThemeProvider theme={theme}>
          <WrappedComponent ref={this.props.forwardedRef} {...this.props} {...injectedProps} />
        </ThemeProvider>
      );
    }
  }

  return forwardRef<WrapperComponentInstance, WrapperComponentPropsWithForwardedRef>((props, ref) => (
    <ComponentWithThemeAndForwardRef forwardedRef={ref} {...props} />
  ));
}
