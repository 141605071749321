import React, { FC } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

interface SlideNavigatorProps extends WithStyles<typeof styles> {
  currentSlide: number;
  activeSlide: number;
  totalSlides: number;
  onClick: (targetSlide: number, currentSlide: number, totalSlides: number) => void;
}

const styles = ({ colors }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
      marginTop: '10px',
      '& $nav': {
        '& ul': {
          listStyle: 'none',
          margin: 0,
          padding: 0,
          flex: '1 1 auto',
          justifyContent: 'flex-end',
          display: 'flex',
          '& li': {
            backgroundColor: colors.gray[300],
            flex: '0 0 30px',
            height: '4px',
            margin: '0 2px',
            cursor: 'pointer',
            '&$active': {
              backgroundColor: colors.gray[900],
            },
          },
        },
      },
      '& $arrow': {
        cursor: 'pointer',
      },
    },
    active: {},
    arrow: {},
    nav: {},
  });

const isActiveSlide = (idx: number, current: number, numberOfActive: number) =>
  idx >= current && idx < current + numberOfActive;

export const SlideNavigator: FC<SlideNavigatorProps> = ({
  classes,
  currentSlide,
  activeSlide,
  totalSlides,
  onClick,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.nav}>
        <ul>
          {[...Array(totalSlides).keys()].map((v, i) => (
            <li
              key={i}
              className={isActiveSlide(i, currentSlide, activeSlide) ? classes.active : ''}
              onClick={() => i <= totalSlides - activeSlide && onClick(i, currentSlide, totalSlides)}
            ></li>
          ))}
        </ul>
      </div>
      <div>
        <KeyboardArrowLeftIcon
          className={classes.arrow}
          onClick={() => currentSlide > 0 && onClick(currentSlide - 1, currentSlide, totalSlides)}
        />
        <KeyboardArrowRightIcon
          className={classes.arrow}
          onClick={() =>
            currentSlide < totalSlides - activeSlide && onClick(currentSlide + 1, currentSlide, totalSlides)
          }
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(SlideNavigator);
