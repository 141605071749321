import React from 'react';
import { Typography, IconButton, DialogTitle as MuiDialogTitle } from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

type Props = {
  children?: React.ReactNode;
  onClose?: () => void;
} & typeof defaultProps;

const defaultProps = Object.freeze({
  size: 'big' as 'small' | 'big',
});

const useStyles = makeStyles<Theme, Props>((theme) => {
  return createStyles({
    root: {
      '&&&': {
        margin: theme.spacing(0),
        padding: ({ size }) => (size === 'big' ? theme.spacing(4) : theme.spacing(2, 4)),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        position: 'relative',
        overflow: 'hidden',
      },
    },
    closeButton: {
      '&&&': {
        position: 'absolute',
        right: -theme.spacing(4),
        top: ({ size }) => (size === 'big' ? -theme.spacing(4) : -theme.spacing(6)),
        color: theme.palette.common.white,
        padding: theme.spacing(8),
        backgroundColor: theme.palette.primary.light,
      },
    },
    titleRoot: {
      '&&&': {
        fontWeight: 'bold',
        color: theme.palette.common.white, // Reset back to white as overwritten externally
      },
    },
  });
});

const DialogTitle = (props: Props) => {
  const { children, onClose } = props;

  const classes = useStyles(props);

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      {children && (
        <Typography component="div" classes={{ root: classes.titleRoot }} data-testid="titleTestId">
          {children}
        </Typography>
      )}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          data-testid="closeButtonTestId"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

DialogTitle.defaultProps = defaultProps;

export default DialogTitle;
