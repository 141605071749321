import { useReducer } from 'react';
import { Column, ColDef, GridApi, ColumnApi } from 'ag-grid-community';

type State = {
  colDefs: ColDef[];
};

type Action = { type: 'checkChange'; field: string; checked: boolean };

const getDefFromColumn = (column: Column) =>
  ({
    ...column.getUserProvidedColDef(),
    width: column.getActualWidth(),
    rowGroup: column.isRowGroupActive(),
    hide: !column.isVisible(),
    sort: column.getSort(),
  } as ColDef);

const colDefReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'checkChange':
      const { field, checked } = action;

      return {
        ...state,
        colDefs: state.colDefs.map(colDef => {
          return colDef.field === field ? { ...colDef, hide: !checked } : colDef;
        }),
      };
    default:
      throw new RangeError('Invalid action type');
  }
};

/**
 * Since agGridReact will handle all the column state changes,
 * but we also want to rendered those states in our component.
 * We have to pull those states out from `GridApi` and `ColumnApi`.
 */
const useCustomizeColumns = (gridApi: GridApi, columnApi: ColumnApi) => {
  const pullColDefs = () => columnApi.getAllColumns().map(getDefFromColumn);
  const update = (colDefs: ColDef[]) => gridApi.setColumnDefs(colDefs);

  const [{ colDefs }, dispatch] = useReducer(colDefReducer, {
    colDefs: pullColDefs(),
  });

  return {
    colDefs,
    dispatch,
    update,
  };
};

export default useCustomizeColumns;
