import React from 'react'
import { Link } from 'react-router-dom'
import { values as userRole } from '../../model/enum/Role'
import localize from '../../utils/localize'
import RoutePaths from '../../configs/RoutePaths'

const AccountMaintenance = ({ role }) => {
    return [userRole.ADMIN].includes(role) ? (
        <div className="row">
            <Link to={RoutePaths.ACCOUNT_MAINTENANCE}>
                <button type="button" className="btn btn-default btn-block btn-large">
                    {localize.strings.AccountMaintenance}
                </button>
            </Link>
        </div>
    ) : (
        <></>
    )
}

export default AccountMaintenance
