import { curry, apply } from 'ramda'

/**
 * Debounce
 *
 * @param {Boolean} immediate If true run `fn` at the start of the timeout
 * @param  timeMs {Number} Debounce timeout
 * @param  fn {Function} Function to debounce
 */
const debounce = curry((immediate, timeMs, fn) => () => {
    let timeout

    return (...args) => {
        const later = () => {
            timeout = null

            if (!immediate) {
                apply(fn, args)
            }
        }

        const callNow = immediate && !timeout

        clearTimeout(timeout)
        timeout = setTimeout(later, timeMs)

        if (callNow) {
            apply(fn, args)
        }

        return timeout
    }
})

const deepClone = obj => JSON.parse(JSON.stringify(obj))

const getDeepValue = (obj, key) => {
    if (!obj) {
        return undefined
    }
    return key.split('.').reduce((acc, curKey) => {
        return acc && acc[curKey]
    }, obj)
}

const isEmptyObject = obj => !obj || Object.keys(obj).length === 0

const setDeepValue = (obj, key, val) => {
    const keys = key.split('.')
    const last = keys.pop()
    const leaf = keys.reduce((acc, cur) => {
        if (!acc[cur]) {
            acc[cur] = {}
        }
        return acc[cur]
    }, obj)
    leaf[last] = val
    return obj
}

export { deepClone, setDeepValue, getDeepValue, isEmptyObject, debounce }
