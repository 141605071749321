import React from 'react';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import colors from 'styles/colors';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    paper: {
      backgroundColor: colors.gray[100],
      borderRadius: theme.spacing(1),
      boxShadow: theme.shadows[6],
    },

    list: {
      padding: 0,
    },
  }),
)((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));
