import { ICellEditorParams } from 'ag-grid-community';

export interface CellEditorProps extends ICellEditorParams {
  /**
   * componentProps: provides additional props for the wrapped component.
   */
  componentProps: any;
  /**
   * useFormatter: used when the cell value needs formatting prior to editing, such as when using reference data and you
   *               want to display text rather than code.
   */
  useFormatter: boolean;
}

export interface EnhancedCellEditorProps extends CellEditorProps {
  /**
   * getStartValue: get pre-formatter or ref data value (if any) to start editing
   */
  getStartValue: () => any;
  /**
   * formatEndValue: apply format (if any) to end value before return
   */
  formatEndValue: (value: any) => any;
}

export function enhanceCellEditorProps<T extends CellEditorProps>(params: T): EnhancedCellEditorProps & T {
  const getStartValue = () => {
    const formatValue = params.useFormatter || params.column.getColDef().refData;
    return formatValue ? params.formatValue(params.value) : params.value;
  };

  const formatEndValue = (value: any) => {
    return params.parseValue(value);
  };

  return {
    ...params,
    getStartValue,
    formatEndValue,
  };
}
