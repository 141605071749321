import React, { ChangeEventHandler, FC } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import { InputBaseProps } from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { GridApi } from 'ag-grid-community';
import debounce from 'lodash/debounce';

import InputBase from 'Input/InputBase';
import { useDataGridContext } from '../DataGridContext';

const DebounceWaitSeconds = 300;

const search = debounce((keyword: string, gridApi: GridApi) => gridApi.setQuickFilter(keyword), DebounceWaitSeconds);

const DataGridQuickFilter: FC<InputBaseProps> = ({ onChange, ...otherProps }) => {
  const { gridApi } = useDataGridContext();

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    search(e.target.value, gridApi);

    onChange && onChange(e);
  };

  return (
    <InputBase
      onChange={handleChange}
      placeholder="Search"
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      {...otherProps}
    />
  );
};

export default DataGridQuickFilter;
