import React, { FC } from 'react';

import { useTheme, ThemeProvider } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { ColDef } from 'ag-grid-community';

import CustomizeGridDialog from 'DataGrid/GridOperations/CustomizeGridDialog';
import promisify, { CANCEL } from 'Dialog/promisify';
import ControlledMenu, { ControlledMenuItem } from '../Components/ControlledMenu';
import IconButton from '../Components/IconButton';
import { useDataGridContext } from '../DataGridContext';

export interface DataGridSettingMenuProps {
  getMenuItems: (menuItems: ControlledMenuItem[]) => ControlledMenuItem[];
}

const DataGridSettingMenu: FC<DataGridSettingMenuProps> = ({ getMenuItems }) => {
  const { gridApi, columnApi } = useDataGridContext();
  const theme = useTheme();

  const handleOpenCustomizeDialog = async () => {
    try {
      const colDefs = await promisify<ColDef[]>(({ open, onSubmit, onDismiss }) => (
        <ThemeProvider theme={theme}>
          <CustomizeGridDialog
            open={open}
            gridApi={gridApi}
            columnApi={columnApi}
            onSubmit={onSubmit}
            onDismiss={onDismiss}
          />
        </ThemeProvider>
      ));

      gridApi.setColumnDefs(colDefs);
    } catch (e) {
      if (e !== CANCEL) {
        console.log(e);
      }
    }
  };

  const menuItems = getMenuItems([
    {
      key: 'pui-customize',
      label: 'Customize Columns',
      handler: handleOpenCustomizeDialog,
    },
  ]);

  return (
    <ControlledMenu
      triggerComponent={setAnchorEl => (
        <IconButton
          color="primary"
          onClick={e => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <SettingsIcon />
        </IconButton>
      )}
      menuItems={menuItems}
    />
  );
};

DataGridSettingMenu.defaultProps = {
  getMenuItems: menuItems => menuItems,
};

export default DataGridSettingMenu;
