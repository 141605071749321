import ServerSuccessResult from './WizardTypes/ServerSuccessResult';
import UnStoredSession from './WizardTypes/UnStoredSession';
import StoredSession from './WizardTypes/StoredSession';

function isSuccess(json: any): json is ServerSuccessResult {
  return 'success' in json && json.success === true;
}
async function parseServerResult(res: Response) {
  if (res.ok) {
    const json = await res.json();
    if (isSuccess(json)) { 
      return json;
    }
    if ('errorCode' in json) {
      throw new Error(json.errorCode);
    }
  } else if(409 === res.status) {
     const json = await res.json()
     if ('errorCode' in json && ApiErrors.VERSION_CONFLICT === json.errorCode) {
      throw new Error(ApiErrors.VERSION_CONFLICT)
    }     
  }
  throw new Error();
}
function toJsonPayload(session: UnStoredSession) {
  return JSON.stringify({
    version: session.version,
    currentStep: session.currentStep,
    farthestStep: session.farthestStep,
    privateWidgetContext: session.privateWidgetContext,
    sharedWidgetContext: session.sharedWidgetContext,
  });
}
function req(method: string, session?: StoredSession, signal?: AbortSignal): RequestInit {
  const headers = new Headers();
  const ret: RequestInit = {
    credentials: 'include',
    signal,
    method,
    headers,
    redirect: 'error',
    cache: 'no-cache',
  };
  if (method === 'POST' && session !== undefined) {
    ret.body = toJsonPayload(session);
    headers.append('Content-Type', 'application/json');
  }
  if (window?.priveApp?.token) {
    headers.append('Authorization', 'Bearer ' + window.priveApp.token);
  }
  return ret;
}

export const ApiErrors = Object.freeze({
  "VERSION_CONFLICT": "VERSION_CONFLICT", 
  "OBJECT_NOT_FOUND": "OBJECT_NOT_FOUND",
})

export async function apiInit(
  signal: AbortSignal,
  api: string,
  journeyTemplateId: string,
): Promise<ServerSuccessResult> {
  const res = await fetch(`${api}/init/${journeyTemplateId}`, req('POST', undefined, signal));
  return parseServerResult(res);
}
export async function apiLoad(
  signal: AbortSignal,
  api: string,
  journeyTemplateId: string,
  sessionId: string,
): Promise<ServerSuccessResult> {
  const res = await fetch(`${api}/load/${journeyTemplateId}/${sessionId}`, req('GET', undefined, signal));
  return parseServerResult(res);
}
export async function apiSave(
  api: string,
  journeyTemplateId: string,
  session: StoredSession,
): Promise<ServerSuccessResult> {
  const res = await fetch(`${api}/save/${journeyTemplateId}/${session.sessionId}`, req('POST', session)) 
  return parseServerResult(res);
}
export async function apiCheckPoint(
  api: string,
  journeyTemplateId: string,
  session: StoredSession,
): Promise<ServerSuccessResult> {
  const res = await fetch(`${api}/checkpoint/${journeyTemplateId}/${session.sessionId}`, req('POST', session));
  return parseServerResult(res);
}
