/* eslint-disable no-undef */
import axios from 'axios'
import endpoints from '../configs/endpoints'

class LoanService {
    /** save loans calculation to backend */
    saveForm(request, callback) {
        const url = window._env_.isWebpack ? '/loans/v1/calcs' : endpoints.baseTTSServiceUrl + endpoints.saveLoanUrl

        axios.post(url, request).then(
            response => {
                if (response.status === 200) {
                    callback({ status: 'success', data: response.data })
                } else {
                    callback({ status: 'fail', error: response.status })
                }
            },
            error => {
                callback({ status: 'fail', error })
            }
        )
    }

    /** perform loans calculation */
    calcForm(request, callback) {
        const url = window._env_.isWebpack ? '/loans/v1/calc' : endpoints.baseTTSServiceUrl + endpoints.calcLoanUrl
        axios.post(url, request).then(
            response => {
                if (response.status === 200) {
                    const { calculationResult } = response.data
                    callback({ status: 'success', data: calculationResult })
                } else {
                    callback({ status: 'fail', error: response.status })
                }
            },
            error => {
                callback({ status: 'fail', error })
            }
        )
    }
}

export default new LoanService()
