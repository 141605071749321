import Divider from '@material-ui/core/Divider';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      margin: theme.spacing(0, 2),
    },
  }),
)(Divider) as typeof Divider;
// Ref: https://github.com/mui-org/material-ui/issues/15695
