import { useEffect } from 'react';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { Props } from './DataGrid';

/**
 * Only triggered when first options passed in.
 */
const useRespectInitialState = <T extends {}>(
  gridApi: Nullable<GridApi>,
  columnApi: Nullable<ColumnApi>,
  props: Props<T>,
) => {
  useEffect(() => {
    if (gridApi !== null) {
      const {
        initialOptions: { sortBy },
      } = props;
      gridApi.setSortModel(sortBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi]);

  useEffect(() => {
    if (columnApi !== null) {
      const {
        initialOptions: { groupBy },
      } = props;
      columnApi.setRowGroupColumns(groupBy);
      columnApi.setColumnsVisible(groupBy, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnApi]);
};

export default useRespectInitialState;
