import React from 'react';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import { pick } from 'ramda';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';

type Props = SwitchProps &
  WithStyles<typeof styles> & {
    thumbIcon?: React.ReactNode;
  };

const styles = (theme: Theme) => {
  return createStyles({
    switchBase: {
      color: theme.colors.gray[400],

      '&$checked': {
        color: theme.colors.positive[600],

        '& + $track': {
          backgroundColor: theme.colors.gray[200],
          opacity: 1,
        },
      },

      '&$disabled': {
        color: theme.colors.gray[200],

        '&$checked': {
          color: theme.colors.gray[200],

          '& + $track': {
            backgroundColor: theme.colors.gray[0],
            opacity: 1,
          },
        },

        '& + $track': {
          backgroundColor: theme.colors.gray[0],
          opacity: 1,
        },
      },
    },

    track: {
      backgroundColor: theme.colors.gray[200],
      opacity: 1,
    },

    checked: {},
    disabled: {},

    // @ts-ignore
    customThumb: {
      '&&&': {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',

        boxShadow: theme.shadows[1],
        backgroundColor: 'currentColor',
        width: ({ size }: Props) => `${size === 'small' ? '16px' : '20px'} !important`,
        height: ({ size }: Props) => `${size === 'small' ? '16px' : '20px'} !important`,
        lineHeight: 'unset !important', // Fix for style collision with monolith
        borderRadius: '50%',

        '&:before': {
          content: 'unset !important', // Fix for style collision with monolith
        },
      },
    },
  });
};

export default withStyles(styles)((props: Props) => {
  const { thumbIcon, classes, ...others } = props;
  const icon = <span className={classes.customThumb}>{thumbIcon}</span>;
  return (
    <Switch
      icon={icon}
      checkedIcon={icon}
      classes={pick(['root', 'switchBase', 'track', 'checked', 'disabled'])(classes)}
      {...others}
    />
  );
});
