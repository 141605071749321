import React, { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import { ChevronRight, ExpandMore } from '@material-ui/icons';

import { GridContext } from 'DataGrid/DataGrid';
import { useExpand } from './hooks';

type Props = {
  className?: string;
};

const ExpandButton = (props: Props) => {
  const { className } = props;
  const { gridApi, columnApi } = useContext(GridContext)!;
  const { expanded, setExpanded } = useExpand(gridApi, columnApi);

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <IconButton color="primary" classes={{ root: className }} onClick={toggleExpanded}>
      {expanded ? <ExpandMore /> : <ChevronRight />}
    </IconButton>
  );
};

export default ExpandButton;
