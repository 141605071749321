import React, { forwardRef, FC } from 'react';

import { CellEditorProps } from './CellEditor';
import NumberCellEditor from './NumberCellEditor';

const DefaultComponetProps = {
  decimalScale: 2,
  fixedDecimalScale: true,
  prefix: '$',
};

const CurrencyCellEditor: FC<CellEditorProps> = forwardRef((props, ref) => {
  const componentProps =
    props.componentProps === undefined
      ? DefaultComponetProps
      : {
          ...DefaultComponetProps,
          ...props.componentProps,
        };

  const extendedProps = {
    ...props,
    componentProps,
    ref,
  };
  return <NumberCellEditor {...extendedProps} />;
});

export default CurrencyCellEditor;
