import React, { ComponentType, FC } from 'react';

import { ColumnApi, GridApi, ICellRendererParams, RowNode } from 'ag-grid-community';
import clsx from 'clsx';

export interface CellRendererProps extends ICellRendererParams {
  /**
   * Provides a component for wrapping.
   */
  component: ComponentType;
  /**
   * Provides additional props for the wrapped component.
   */
  componentProps: any;
  /**
   * Provides additional props for the wrapped component base on the cell value. It will override the value in `componentProps`.
   */
  componentPropsRenderer: (value: any) => {};
  /**
   * Callbacks when clicking the wrapped compoent. It will not fire if the wrapped component is not clickable.
   */
  onClick?: (
    props: {
      api: GridApi;
      colId?: string;
      columnApi: ColumnApi;
      data: any;
      node: RowNode;
      rowIndex: number;
      value: any;
    },
    e: Event,
  ) => void;
}

const CellRenderer: FC<CellRendererProps> = ({
  /* Cell Renderer Params */
  api,
  column,
  columnApi,
  data,
  node,
  rowIndex,
  value,
  /* Compoent */
  component: Component,
  componentProps = {},
  componentPropsRenderer,
  onClick,
}) => {
  const {
    className,
    classNameRenderer,
    onClick: customOnClick,
    style,
    styleRenderer,
    clickable,
    ...otherComponentProps
  } = componentProps;

  const basicParams = {
    api,
    colId: column.getColId(),
    columnApi,
    data,
    node,
    rowIndex,
    value,
  };

  function handleClick(e: Event) {
    if (onClick) {
      onClick(basicParams, e);
    }

    if (customOnClick) {
      customOnClick(e);
    }
  }

  const additionalComponentProps = componentPropsRenderer ? componentPropsRenderer(basicParams) : {};
  const combinedComponentProps = {
    ...otherComponentProps,
    className: classNameRenderer ? clsx(className, classNameRenderer(value)) : className,
    style: styleRenderer ? styleRenderer(value) : style,
    onClick: clickable === false ? undefined : handleClick,
    ...additionalComponentProps,
  };

  return <Component {...combinedComponentProps} />;
};

export default CellRenderer;
