import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingOverlay = (params: any) => (
  <div className="ag-overlay-loading-center">
    <CircularProgress />
  </div>
);

export default LoadingOverlay;
