import { createContext, useContext, useEffect } from 'react';
import type { DependencyList } from 'react';
import type WizardContext from './WizardTypes/WizardContext';
import type { WizardEventCallback } from './WizardTypes/EventCallback';
import type WizardEventType from './WizardTypes/WizardEventType';
export const StepWidgetContext = createContext<Readonly<WizardContext | undefined>>(undefined);

/**
 * Context for StepWizard widgets
 */
export function useWizardContext() {
  return useContext(StepWidgetContext);
}

/**
 * syntax sugar to listenToWizardEvents
 *
 * cost 1 useContext, and 1 useEffect
 *
 * @param eventName
 * @param callback
 * @param deps
 */
export function useWizardEvent(eventName: WizardEventType, callback: WizardEventCallback, deps?: DependencyList) {
  const wizardContext = useWizardContext();
  useWizardEventEx(wizardContext, eventName, callback, deps);
}

/**
 * syntax sugar to listenToWizardEvents
 * cheaper runtime than useWizardEvent if you reuse useWizardContext
 *
 * cost 1 useEffect
 *
 * @see useWizardEvent
 * @param wizardContext
 * @param eventName
 * @param callback
 * @param deps
 */
export function useWizardEventEx(
  wizardContext: WizardContext | undefined,
  eventName: WizardEventType,
  callback: WizardEventCallback,
  deps?: DependencyList,
) {
  if (deps === undefined) {
    deps = [];
  }
  useEffect(() => {
    // note: condition must always inside hook. never outside hook
    if (wizardContext) {
      return wizardContext.listenToWizardEvents(eventName, callback);
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardContext, callback, eventName, ...deps]);
}

export default StepWidgetContext;
