import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, createStyles } from '@material-ui/core/styles';

export default withStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(1, 2),
      minHeight: theme.spacing(5),
    },
  }),
)(MenuItem) as typeof MenuItem;
// Ref: https://github.com/mui-org/material-ui/issues/15695
