import 'babel-polyfill'
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useRef, useContext, useState, useMemo, useCallback, useEffect } from 'react'
import { V2DataGrids } from '@prive/prive-core-ui'
import { useForm, FormProvider } from 'react-hook-form'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { AuthContext } from '../../auth/AuthContextProvider'
import UserService from '../../services/UserService'
import localize from '../../utils/localize'
import { debounce } from '../../utils/CommonUtils'
import Nav from '../common/Nav'
import Sidebar from '../common/Sidebar'
import Header from '../common/Header'
import ErrorDialog from '../common/ErrorDialog'
import CreateUserForm from './CreateUserForm'
import Loading from '../Loading'
import ROLE, { values as userRole } from '../../model/enum/Role'

const DEBOUNCE_TIME = 300

const { DataGrid, DataGridQuickFilter } = V2DataGrids

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            '&&&': {
                paddingLeft: '30px',
                paddingRight: '30px'
            },
            '&.ag-theme-prive': {
                color: theme.colors.greenGray[700],
                '& .ag-header': {
                    color: theme.colors.greenGray[700]
                },
                '& .ag-toolbar': {
                    height: 64
                }
            },
            "&a:not([class*=' MuiTypography-color'])": {
                color: theme.colors.blue[600]
            }
        },
        input: {
            '&&&': {
                paddingTop: `${theme.spacing(1.5)}px !important`,
                paddingBottom: `${theme.spacing(1.5)}px !important`,
                paddingLeft: `${theme.spacing(2)}px !important`,
                // NOTE: since this should be able to override when we use <InputBase /> in <Select />
                paddingRight: theme.spacing(2)
            }
        },
        rightPanel: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            '& .cancel': {
                color: 'var(--primary-color)',
                fontSize: '18px',
                cursor: 'pointer',
                marginRight: '30px',
                '&:hover': {
                    opacity: 0.7
                }
            }
        }
    })
)

const PAGE_SIZE = 10

const getContextMenuItems = ({
    setIsUpdated,
    setIsLoading,
    setIsErrorDialogOpen,
    setUsers,
    setValue,
    teams,
    groups,
    roles,
    token
}) => params => {
    const { data } = params.node
    const enabledMenu = {
        name: localize.strings.Enabled,
        action: async () => {
            setIsLoading(true)
            const result = await UserService.updateUser({
                accessToken: token,
                userId: data.userId,
                data: { active: true }
            })
            if (result.success) {
                const userList = await UserService.getUserList({ accessToken: token })
                setUsers(userList)
            } else {
                setIsErrorDialogOpen(true)
            }
            setIsLoading(false)
        }
    }
    const disabledMenu = {
        name: localize.strings.Disabled,
        action: async () => {
            setIsLoading(true)
            const result = await UserService.updateUser({
                accessToken: token,
                userId: data.userId,
                data: { active: false }
            })
            if (result.success) {
                const userList = await UserService.getUserList({ accessToken: token })
                setUsers(userList)
            } else {
                setIsErrorDialogOpen(true)
            }
            setIsLoading(false)
        }
    }
    const result = [
        {
            name: localize.strings.Edit,
            action: async () => {
                const { userDetails } = await UserService.getUserDetail({ accessToken: token, monolithId: data.key })
                Object.keys(data).forEach(i => {
                    if (i === 'role') {
                        const role = roles.find(j => j.code === data.roleCode)
                        setValue(i, data.roleCode === userRole.ADMIN ? ROLE[role.code]() : role.id)
                    } else if (i === 'group') {
                        setValue(i, groups.find(j => j.name === data[i]).name)
                    } else if (i === 'team') {
                        const team = teams.find(j => j.name === data[i]) || teams[0]
                        setValue(i, [userRole.ADMIN, userRole.SUPERVISOR].includes(data.roleCode) ? data[i] : team.id)
                        setValue(
                            'defaultTeam',
                            [userRole.ADMIN, userRole.SUPERVISOR].includes(data.roleCode) ? data[i] : team.id
                        )
                    } else if (i === 'firstName') {
                        setValue(i, userDetails.firstName || data[i])
                    } else if (i === 'lastName') {
                        setValue(i, userDetails.lastName || data[i])
                    } else if (i === 'email') {
                        setValue(i, userDetails.email || data[i])
                    } else {
                        setValue(i, data[i])
                    }
                })
                setValue('password', '********')
                setValue('confirmPassword', '********')
                setIsUpdated(true)
            }
        }
    ]
    if (data.roleCode !== userRole.ADMIN) result.push(data.statusCode ? disabledMenu : enabledMenu)
    return result
}
const getColumnDefs = () => [
    {
        headerName: localize.strings.FirstName,
        field: 'firstName'
    },
    {
        headerName: localize.strings.LastName,
        field: 'lastName'
    },
    {
        headerName: localize.strings.Username,
        field: 'username'
    },
    {
        headerName: localize.strings.Status,
        field: 'status'
    },
    {
        headerName: localize.strings.Email,
        field: 'email'
    },
    {
        headerName: localize.strings.UserType,
        field: 'role'
    },
    {
        headerName: localize.strings.Group,
        field: 'group'
    },
    {
        headerName: localize.strings.Team,
        field: 'team'
    },
    {
        field: 'dotMenu',
        type: 'iconMenu',
        headerName: '...',
        cellRendererParams: {
            componentProps: {
                'aria-haspopup': true
            }
        },
        headerComponent: 'puiIconHeader',
        headerComponentProps: {
            icon: <MoreHorizIcon />
        },
        onCellClicked: e => {
            const { api, node, column, value, event } = e
            api.contextMenuFactory.showMenu(node, column, value, event)
        },
        onCellContextMenu: e => {
            const { api, node, column, value, event } = e
            api.contextMenuFactory.showMenu(node, column, value, event)
        }
    }
]

const AccountMaintenance = () => {
    const { authState } = useContext(AuthContext)
    const {
        token,
        userDetails: {
            teamDTO: { id: groupId }
        }
    } = authState.authInfo
    const [isCreated, setIsCreated] = useState(false)
    const [isUpdated, setIsUpdated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
    const [users, setUsers] = useState(null)
    const [roles, setRoles] = useState(null)
    const [groups, setGroups] = useState(null)
    const [teams, setTeams] = useState(null)
    const classes = useStyles()
    const gridRef = useRef(null)
    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange'
    })
    const { handleSubmit, reset, setValue, setError } = methods

    const handleCancel = useCallback(() => {
        reset({ firstName: '', lastName: '', username: '', password: '', email: '', role: '', team: '', roleCode: '' })
        setIsLoading(false)
        setIsCreated(false)
        setIsUpdated(false)
    }, [])

    const handleCreate = useCallback(() => {
        setValue('group', groups.find(i => i.id === groupId).name)
        setValue('confirmPassword', '')
        setIsCreated(true)
    }, [groups])

    const onSubmit = debounce(false, DEBOUNCE_TIME, async (_data, e) => {
        const { firstName, lastName, username, password, email, role, team, userId, roleCode, key } = _data
        const userDetail = {
            firstName,
            lastName,
            username,
            password,
            email,
            teamDTO: {
                id: team
            },
            roleDTO: {
                id: role
            }
        }
        if (isCreated) {
            setIsLoading(true)
            if ([userRole.SUPERVISOR].includes(roleCode)) {
                const newTeamID = groups.find(i => i.name === team)?.id || false
                if (newTeamID) {
                    userDetail.teamDTO.id = newTeamID
                } else {
                    delete userDetail.teamDTO
                }
            }
            const result = await UserService.createUser({ accessToken: token, data: userDetail })
            const userList = await UserService.getUserList({ accessToken: token })
            if (result.success) {
                setUsers(userList)
                reset()
                setIsCreated(false)
            } else {
                setError('username', {
                    type: 'manual',
                    message: result.message
                })
            }
            setIsLoading(false)
        }
        if (isUpdated) {
            delete userDetail.password
            if ([userRole.ADMIN].includes(roleCode)) {
                delete userDetail.roleDTO
                delete userDetail.teamDTO
            }
            if ([userRole.SUPERVISOR].includes(roleCode)) {
                const newTeamID = groups.find(i => i.name === team)?.id || false
                if (newTeamID) {
                    userDetail.teamDTO.id = newTeamID
                } else {
                    delete userDetail.teamDTO
                }
            }
            setIsLoading(true)
            gridRef.current.api.deselectAll()
            const result = await UserService.updateUser({ accessToken: token, userId, data: userDetail })
            const userList = await UserService.getUserList({ accessToken: token })
            if (result.success) {
                setUsers(userList)
                reset()
                setIsUpdated(false)
                setTimeout(() => {
                    gridRef.current.api.getRowNode(userId).setSelected(true)
                }, 0)
            } else {
                setError('username', {
                    type: 'manual',
                    message: result.message
                })
            }
            setIsLoading(false)
        }
    })()

    const handleSubmitMemo = useCallback(handleSubmit(onSubmit), [isCreated, isUpdated])

    const refreshData = async () => {
        const userList = await UserService.getUserList({ accessToken: token })
        setUsers(userList)
    }

    useEffect(() => {
        const initData = async () => {
            const [roleList, userList, teamList, groupList] = await Promise.all([
                UserService.getRoleList({ accessToken: token }),
                UserService.getUserList({ accessToken: token }),
                UserService.getTeamList({ accessToken: token, groupId }),
                UserService.getGroupList({ accessToken: token })
            ])
            setRoles(roleList)
            setUsers(userList)
            setTeams(teamList)
            setGroups(groupList)
        }
        initData()
    }, [])

    useEffect(() => {
        if (authState.locale) refreshData()
    }, [authState.locale])

    const columnDefs = useMemo(() => {
        return getColumnDefs()
    }, [authState.locale])

    const renderHeaderRightPanel = () => {
        if (isCreated || isUpdated) {
            return (
                <div className={classes.rightPanel}>
                    <div className="cancel" onClick={handleCancel} onKeyPress={handleCancel} aria-hidden="true">
                        {localize.strings.CancelDatePicker}
                    </div>
                    <button type="button" className="btn btn-default" onClick={handleSubmitMemo} disabled={isLoading}>
                        {isCreated ? localize.strings.Create : localize.strings.Save}
                    </button>
                </div>
            )
        }
        return (
            <div className={classes.rightPanel}>
                <button type="button" className="btn btn-default" onClick={handleCreate}>
                    {localize.strings.Create}
                </button>
            </div>
        )
    }

    return (
        <div className="wrapper">
            <ErrorDialog
                title={localize.strings.UpdateUserDialogErrorTitle}
                message={localize.strings.UpdateUserDialogErrorMessage}
                buttonLabel={localize.strings.UpdateUserDialogErrorButton}
                isOpen={isErrorDialogOpen}
                handleClose={() => setIsErrorDialogOpen(false)}
            />
            <Sidebar />
            <div className="content-wrapper">
                <Nav />
                <div className="boxes">
                    <Header
                        title={isCreated ? localize.strings.NewUser : localize.strings.AccountMaintenance}
                        rightpanel={renderHeaderRightPanel}
                    />
                    <section className="content container-fluid">
                        {users && teams && groups && roles && (
                            <div
                                className="row-section flex-center tts-new-user-form"
                                style={{ display: isCreated || isUpdated ? 'block' : 'none' }}
                            >
                                <Loading visible={isLoading} size={40}>
                                    <FormProvider {...methods}>
                                        <form>
                                            <CreateUserForm
                                                teams={teams}
                                                groups={groups}
                                                roles={roles}
                                                isUpdated={isUpdated}
                                            />
                                        </form>
                                    </FormProvider>
                                </Loading>
                            </div>
                        )}
                        {users && teams && groups && roles && (
                            <Loading visible={isLoading} size={40}>
                                <div
                                    className="row-section flex-center tts-accountmaintenance"
                                    style={{ display: isCreated || isUpdated ? 'none' : 'block' }}
                                >
                                    <DataGrid
                                        height={500}
                                        classes={classes}
                                        ref={gridRef}
                                        rowData={users}
                                        autoFitColumns
                                        topPanel={{
                                            enabled: true
                                        }}
                                        pagination={{
                                            enabled: true,
                                            pageSize: PAGE_SIZE,
                                            location: 'top-panel'
                                        }}
                                        getRowNodeId={data => data.userId}
                                        hideStatusBarDefaultComponents
                                        gridOptions={{
                                            getContextMenuItems: getContextMenuItems({
                                                setIsErrorDialogOpen,
                                                setIsUpdated,
                                                setIsLoading,
                                                setValue,
                                                setUsers,
                                                teams,
                                                groups,
                                                roles,
                                                token
                                            }),
                                            columnDefs,
                                            defaultColDef: {
                                                enableRowGroup: true
                                            },
                                            rowGroupPanelShow: 'always',
                                            autoGroupColumnDef: {
                                                minWidth: 250,
                                                lockPosition: true,
                                                suppressMenu: false
                                            },
                                            domLayout: 'normal',
                                            rowSelection: 'multiple',
                                            groupUseEntireRow: true,
                                            groupDefaultExpanded: -1,
                                            suppressDragLeaveHidesColumns: true,
                                            stopEditingWhenGridLosesFocus: true
                                        }}
                                    >
                                        <DataGridQuickFilter fullWidth classes={classes} />
                                    </DataGrid>
                                </div>
                            </Loading>
                        )}
                    </section>
                </div>
            </div>
        </div>
    )
}

export default AccountMaintenance
