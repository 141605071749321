/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import endpoints from '../configs/endpoints'
import { values as productTypes } from '../model/enum/ProductType'

const toProductCodes = products => {
    const productCodes = {}
    products.forEach(
        product => product.productCode && Object.assign(productCodes, { [product.productCode]: product.id })
    )
    return productCodes
}

class ProductService {
    getUserProductsDefaultAll(productRequest) {
        return new Promise(resolve => {
            const productCodes = {}
            let id = 10021
            Object.keys(productTypes).forEach(key => Object.assign(productCodes, { [key]: id++ }))

            resolve({
                products: productCodes,
                ...productRequest
            })
        })
    }

    /** get user permission data from tts permission endpoint */
    getUserProducts(prodRequest) {
        return new Promise((resolve, reject) => {
            const { token } = prodRequest
            const monolithId = token && jwtDecode(token).monolith_id

            const url = window._env_.isWebpack
                ? `/loans/v1/userProduct?monolithId=${monolithId}`
                : endpoints.baseTTSServiceUrl + endpoints.userProductUrl.replace('{monolithId}', monolithId)

            const userHeaders = {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
            const options = {
                headers: userHeaders
            }

            try {
                axios
                    .get(url, options)
                    .then(response => {
                        const userData = response.data

                        resolve({
                            products: toProductCodes(userData),
                            ...prodRequest
                        })
                    })
                    .catch(e => {
                        console.log('### Failed to get user', e)
                        reject(e)
                    })
            } catch (e) {
                reject(e)
            }
        })
    }
}
export default new ProductService()
