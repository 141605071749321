import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Droppable, Draggable, DragDropContext, DragDropContextProps } from 'react-beautiful-dnd';
import { List, ListItem, Checkbox, ListItemText, Theme } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { makeStyles, createStyles, Theme as AugmentedTheme } from '@material-ui/core/styles';
import { noop } from 'utils/function';

export type Column = {
  id: string;
  name: string;
  checked?: boolean;
};

type Props = {
  columns: Column[];
  renderOperation?: (column: Column) => React.ReactNode;
  renderName?: (column: Column) => React.ReactNode;
} & typeof defaultProps;

const defaultProps = Object.freeze({
  draggable: true,
  checkable: true,
  onReOrder: noop as (s: number, d: number) => void,
  onCheckChange: noop as (column: Column, checked: boolean) => void,
  columnCount: 1,
});

const useStyles = makeStyles<Theme, Props>((theme: AugmentedTheme) =>
  createStyles({
    listItemText: {
      '&&&': {
        color: theme.colors.greenGray[700],
        fontFamily: theme.typography.fontFamily,

        '& span': {
          display: 'inline-flex',
        },
      },
    },
    list: {
      '&&&': {
        padding: 0,
        columns: props => props.columnCount,
      },
    },
    listItem: {
      '&&&': {
        marginTop: 0,
        padding: theme.spacing(0, 1),
        // avoid exactly [this](https://stackoverflow.com/questions/44603333/avoid-an-element-to-be-split-into-two-columns-while-using-column-count) problem.
        breakInside: 'avoid',
      },
    },
    listItemDraggable: {
      '&&&': {
        borderRadius: theme.spacing(1),

        '&:hover': {
          boxShadow: theme.shadows[3],
        },
      },
    },
    checkboxRoot: {
      '&&&': {
        '& [type="checkbox"]': {
          '&:checked, &:not(:checked)': {
            pointerEvents: 'auto',
          },
        },
      },
    },

    menuIconContainer: {
      '&&&': {
        display: 'inline-flex',
      },
    },
  }),
);

const ColumnSelector = (props: Props) => {
  const { columns, onCheckChange, onReOrder, draggable, checkable, renderOperation, renderName } = props;
  const classes = useStyles(props);

  const onDragEnd = useCallback<DragDropContextProps['onDragEnd']>(
    result => {
      if (result.destination === null) {
        return;
      }

      const { source, destination } = result;

      const isDragAcrossDroppable = source.droppableId !== destination.droppableId;
      if (isDragAcrossDroppable) {
        return;
      } else {
        onReOrder(source.index, destination.index);
      }
    },
    [onReOrder],
  );

  const renderItemChild = (column: Column) => (
    <>
      {checkable && (
        <Checkbox
          classes={{ root: classes.checkboxRoot }}
          checked={column.checked}
          onChange={(e, checked) => onCheckChange(column, checked)}
        />
      )}
      <ListItemText data-testid="columnName" className={classes.listItemText}>
        {typeof renderName === 'undefined' ? column.name : renderName(column)}
      </ListItemText>

      {renderOperation && renderOperation(column)}
    </>
  );

  if (draggable) {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="column" type="column">
          {provided => (
            <List className={classes.list} ref={provided.innerRef} {...provided.droppableProps}>
              {columns.map((column, index) => (
                <Draggable key={column.id} draggableId={column.id} index={index}>
                  {provided => (
                    <ListItem
                      className={clsx(classes.listItem, classes.listItemDraggable)}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      {renderItemChild(column)}

                      {/* NOTE: dragging SVGElement is not possible so we have to wrap it. */}
                      <span {...provided.dragHandleProps} className={classes.menuIconContainer}>
                        <Menu color="primary" />
                      </span>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    );
  } else {
    return (
      <List className={classes.list}>
        {columns.map(column => (
          <ListItem key={column.id} className={classes.listItem}>
            {renderItemChild(column)}
          </ListItem>
        ))}
      </List>
    );
  }
};

ColumnSelector.defaultProps = defaultProps;

export default ColumnSelector;
