import React from 'react';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import CustomTextField from './CustomTextField';

type Props = Omit<DatePickerProps, 'TextFieldComponent'>;

const CustomDatePicker = (props: Props) => {
  return <DatePicker TextFieldComponent={CustomTextField} {...props} />;
};

CustomDatePicker.defaultProps = {
  format: 'yyyy-MM-dd',
};

export default CustomDatePicker;
