import React, { useEffect, FC, ReactNode } from 'react';

import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import isString from 'lodash/isString';

import Divider from '../Lab/Divider';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    container: {
      marginBottom: 0,
      '& .MuiGrid-item:last-child': {
        paddingBottom: 0,
      },
    },
    actionPanel: {
      alignItems: 'center',
      display: 'flex',
      '& > *': {
        marginLeft: theme.spacing(1),
      },
    },
    divderPanel: {
      width: '100%',
      '.MuiDivider-root': {
        paddingBottom: 0,
      },
    },
    bottomPanel: {
      width: '100%',
    },
    separator: {
      width: '100%',
      padding: 0,
    },
  });

export type WidgetHeaderRenderProps = {
  /**
   * The content of the action panel.
   */
  actionPanel?: ReactNode;
  /**
   * The content of the action panel.
   */
  bottomPanel?: ReactNode;
  /**
   * Show divider.
   */
  showDivider?: boolean;
  /**
   * The text to be used in an enclosing title element.
   */
  title?: ReactNode;
} & WithStyles<typeof styles>;

export interface WidgetHeaderProps extends WidgetHeaderRenderProps {
  /**
   * The content of the component. It will replace the default content, such as Title, Action Panel.
   */
  children?: (param: WidgetHeaderRenderProps) => ReactNode;
  /**
   * The `className`.
   */
  className?: string;
  /**
   * The height of the header in pixel.
   */
  height?: number;
}

function defaultRenderer({ actionPanel, bottomPanel, classes, showDivider, title }: WidgetHeaderRenderProps) {
  return (
    <Grid className={classes.container} container spacing={2} justify="space-between">
      <Grid item>{isString(title) ? <Typography variant="h3">{title}</Typography> : title}</Grid>
      <Grid item className={classes.actionPanel}>
        {actionPanel}
      </Grid>
      <Grid item className={classes.separator} />
      {showDivider && (
        <Grid item className={classes.divderPanel}>
          <Divider />
        </Grid>
      )}
      {bottomPanel !== undefined && (
        <Grid item className={classes.bottomPanel}>
          {bottomPanel}
        </Grid>
      )}
    </Grid>
  );
}

const WidgetHeader: FC<WidgetHeaderProps> = ({
  actionPanel,
  bottomPanel,
  classes,
  className,
  children,
  height,
  showDivider,
  title,
  ...otherProps
}) => {
  const childrenRenderer = children || defaultRenderer;

  const renderedChildren = childrenRenderer({
    actionPanel,
    bottomPanel,
    classes,
    showDivider,
    title,
  });

  useEffect(() => {
    if (height) {
      document.documentElement.style.setProperty('--widget-header-height', `${height}px`);
    }
    return () => {
      document.documentElement.style.removeProperty('--widget-header-height');
    };
  }, [height]);

  return (
    <div
      {...otherProps}
      className={clsx('g-header', className, classes.root)}
      style={{
        height,
      }}
    >
      {renderedChildren && React.Children.only(renderedChildren)}
    </div>
  );
};

export default withStyles(styles)(WidgetHeader);
