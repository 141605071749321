import React, { forwardRef } from 'react';

import Highcharts from 'highcharts/highstock';
import mergeWith from 'lodash/mergeWith';

import StockBase, { StockCommonProps, StockBaseRef } from './StockBase';
import { replaceIfArray } from './utils';

export const LineStock = forwardRef<StockBaseRef, StockCommonProps>(({ defaultOptions, ...otherProps }, ref) => {
  const myDefaultOptions: Highcharts.Options = mergeWith(
    {
      chart: {
        type: undefined,
      },
      yAxis: {
        labels: {
          align: 'left',
          x: 0,
        },
        opposite: false,
      },
    },
    defaultOptions,
    replaceIfArray,
  );

  return <StockBase ref={ref} defaultOptions={myDefaultOptions} {...otherProps} />;
});

LineStock.defaultProps = {};

export default LineStock;
